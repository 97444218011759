import styled from 'styled-components'
import {
  color,
  ColorProps,
  margin,
  MarginProps,
  position,
  PositionProps,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'

type EditIconContainerProps = MarginProps & PositionProps

export const InspectionDefectTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const EditIconContainer = styled.div<EditIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.primary500')};
  border-radius: 100%;
  height: 36px;
  width: 36px;
  pointer-events: none;

  ${position}
  ${margin}
`

export const StyledForm = styled.form`
  width: 100%;
  height: 155px;
`

export const AudioTranscriptionStatus = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 ${themeGet('space.3')}px;
  border-radius: 40px;
  height: 26px;

  ${color}
`
