import React, { ComponentProps, useCallback } from 'react'

import { GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid'

import DataGrid from 'Components/Blocks/DataGrid'

import {
  ServiceAppointmentFragment,
  SortInput,
  SortInputOrder,
} from 'GraphQL/Main/TypedDocuments'

type Props = {
  columns: GridColDef<ServiceAppointmentFragment>[]
  data: ServiceAppointmentFragment[]
  loading?: boolean
  page?: number
  pageSize?: number
  totalPages?: number
  onChangeSort?: (sort?: SortInput) => void
  onChangePage?: ComponentProps<typeof DataGrid>['onChangePage']
  onChangePageSize?: ComponentProps<typeof DataGrid>['onChangePageSize']
  onClickRow?: (row: ServiceAppointmentFragment) => void
}

function ServiceAppointmentGrid({
  columns,
  data,
  loading,
  page,
  pageSize,
  totalPages,
  onChangeSort,
  onChangePage,
  onChangePageSize,
  onClickRow,
}: Props) {
  const handleChangeSort = useCallback(
    (sort: GridSortModel) => {
      if (loading) {
        return
      }

      if (sort.length === 0) {
        onChangeSort?.(undefined)
      } else {
        const column = sort[0].field
        const order =
          sort[0].sort === SortInputOrder.Desc
            ? SortInputOrder.Desc
            : SortInputOrder.Asc
        onChangeSort?.({
          column,
          order,
        })
      }

      onChangePage?.(0)
    },
    [loading, onChangeSort, onChangePage],
  )

  const handleChangePageSize = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePageSize']>
  >(
    size => {
      onChangePageSize?.(size)
    },
    [onChangePageSize],
  )

  const handleChangePage = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePage']>
  >(
    page => {
      onChangePage?.(page)
    },
    [onChangePage],
  )

  const handleClickRow = useCallback(
    ({ row }: GridRowParams<ServiceAppointmentFragment>) => {
      onClickRow?.(row)
    },
    [onClickRow],
  )

  return (
    <DataGrid
      columns={columns}
      isClickable
      loading={loading}
      page={page}
      pageSize={pageSize}
      rows={data}
      totalPages={totalPages}
      onChangePage={handleChangePage}
      onChangePageSize={handleChangePageSize}
      onRowClick={handleClickRow}
      onSortModelChange={handleChangeSort}
    />
  )
}

export default ServiceAppointmentGrid
