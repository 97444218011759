import React, { ComponentProps } from 'react'
import { Field } from 'react-final-form'

import { FieldValidator } from 'final-form'
import Utils from 'Utils'

import TextEditor from './TextEditor'

type Props = ComponentProps<typeof TextEditor> & {
  name: string
  defaultValue?: string
  checkErrorIfDirty?: boolean
  displayError?: boolean
  caption?: string
  validate?: FieldValidator<string>
}

function TextEditorField({
  name,
  defaultValue,
  checkErrorIfDirty,
  displayError,
  caption,
  validate,
  ...rest
}: Props) {
  return (
    <Field<string> defaultValue={defaultValue} name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <TextEditor
            {...rest}
            caption={(displayError && error) || caption}
            danger={hasError}
            value={value}
            onChange={handleChange}
          />
        )
      }}
    </Field>
  )
}

export default TextEditorField
