import React, { ComponentProps, useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'

import debounce from 'lodash/debounce'

import { Select } from 'Components/UI'

import { PAGINATION_SIZES } from 'Constants/dataGrid'

import { ServiceAppointmentListDocument } from 'GraphQL/Admin/TypedDocuments'
import { ServiceAppointmentFragment } from 'GraphQL/Main/TypedDocuments'

const SEARCH_DEBOUNCE = 350

type ServiceAppointmentOption = {
  label: string
  value: string
  serviceAppointment?: ServiceAppointmentFragment
}

type Props = {
  disabled?: boolean
  onSelect?: (serviceAppointment?: ServiceAppointmentFragment) => void
}

function ServiceAppointmentSelector({ disabled, onSelect }: Props) {
  const [search, setSearch] = useState('')

  const { data: serviceAppointmentData, loading: serviceAppointmentLoading } =
    useQuery(ServiceAppointmentListDocument, {
      context: {
        admin: true,
      },
      variables: {
        page: 0,
        limit: PAGINATION_SIZES[0],
        search: search || undefined,
      },
    })

  const serviceAppointments = useMemo(
    () =>
      serviceAppointmentData
        ? serviceAppointmentData.serviceAppointmentList.rows
        : [],
    [serviceAppointmentData],
  )
  const options = useMemo(
    () =>
      serviceAppointments.map(serviceAppointment => ({
        label: `${serviceAppointment.subject} | ${serviceAppointment.appointmentNumber}`,
        value: serviceAppointment.id,
        serviceAppointment,
      })),
    [serviceAppointments],
  )
  const debouncedSearch = useMemo(() => {
    return debounce((value: string) => setSearch(value), SEARCH_DEBOUNCE)
  }, [])

  const handleSelectServiceAppointment = useCallback<
    NonNullable<
      ComponentProps<typeof Select<ServiceAppointmentOption>>['onChange']
    >
  >(
    (_, option) => {
      onSelect?.(option?.serviceAppointment)
    },
    [onSelect],
  )

  const handleChangeSearch = useCallback<
    NonNullable<ComponentProps<typeof Select>['onChangeInput']>
  >(
    event => {
      debouncedSearch(event.target.value)
    },
    [debouncedSearch],
  )

  return (
    <Select<ServiceAppointmentOption>
      disabled={disabled}
      label="Service Appointment subject"
      loading={serviceAppointmentLoading}
      options={options}
      onChange={handleSelectServiceAppointment}
      onChangeInput={handleChangeSearch}
    />
  )
}

export default ServiceAppointmentSelector
