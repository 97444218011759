import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { CORS } from 'Config'

import { FloorPlanFragment } from 'GraphQL/Admin/TypedDocuments'

import styles from '../styles'

type Props = {
  floorPlans?: FloorPlanFragment[]
}

function FloorPlans({ floorPlans }: Props) {
  if (!floorPlans?.length) {
    return null
  }

  return (
    <View break style={[styles.lowerZIndex, styles.mt30]}>
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.header5}>FLOOR PLANS</Text>
      </View>

      <View
        style={[
          styles.mt12,
          styles.column,
          styles.justifyCenter,
          styles.center,
        ]}
      >
        {floorPlans.map(floorPlan => {
          const image = floorPlan.editedImageUrl || floorPlan.originalImageUrl

          if (!image) {
            return null
          }

          return (
            <View key={floorPlan.id} style={styles.floorPlanImageContainer}>
              <Image src={`${CORS}${image}`} style={styles.floorPlanImage} />
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default FloorPlans
