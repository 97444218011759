import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { Fade, Popper, Slider } from '@mui/material'

import { Card, Column, IconButton } from 'Components/UI'

type Props = {
  disabled?: boolean
  value?: number
  muted?: boolean
  onChange?: ComponentProps<typeof Slider>['onChange']
  onToggleMute?: () => void
}

function PlayerVolumeBarDropdown({
  value,
  disabled,
  muted,
  onChange,
  onToggleMute,
}: Props) {
  const theme = useTheme()
  const iconButtonRef = useRef(null)

  const color = useMemo(
    () => ({
      volumeIcon: themeGet('colors.neutral100')({ theme }),
      deleteIcon: themeGet('colors.danger500')({ theme }),
    }),
    [theme],
  )

  const [popperOpen, setPopperOpen] = useState(false)

  const handleOpenPopper = useCallback(() => {
    setPopperOpen(true)
  }, [])

  const handleClosePopper = useCallback(() => {
    setPopperOpen(false)
  }, [])

  const handleMute = useCallback(() => {
    if (!value && !muted) {
      return
    }

    onToggleMute?.()
  }, [value, muted, onToggleMute])

  return (
    <Column relative>
      <IconButton
        disabled={disabled}
        ref={iconButtonRef}
        onClick={handleMute}
        onMouseEnter={handleOpenPopper}
        onMouseLeave={handleClosePopper}
      >
        {muted || !value ? (
          <VolumeOffIcon style={{ color: color.volumeIcon, fontSize: 22 }} />
        ) : (
          <VolumeUpIcon style={{ color: color.volumeIcon, fontSize: 22 }} />
        )}
      </IconButton>

      <Popper
        anchorEl={iconButtonRef.current}
        open={popperOpen}
        placement="top"
        style={{
          zIndex: 1000,
        }}
        transition
        onMouseEnter={handleOpenPopper}
        onMouseLeave={handleClosePopper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Card height="130px" px={2} py={6}>
              <Slider
                orientation="vertical"
                size="small"
                sx={{
                  // NOTE: for Chrome, Safari and newer Edge versions
                  '& input[type="range"]': {
                    WebkitAppearance: 'slider-vertical',
                  },
                }}
                value={value}
                onChange={onChange}
              />
            </Card>
          </Fade>
        )}
      </Popper>
    </Column>
  )
}

export default PlayerVolumeBarDropdown
