import JSZip from 'jszip'

import { CORS } from 'Config'

import {
  AudioRecordTranscriptionStatus,
  InspectionDefectFragment,
} from 'GraphQL/Main/TypedDocuments'

import Async from './async'
import File from './file'
import Image from './image'

const DEFAULT_STATUS_COLORS = {
  color: 'danger500',
  background: 'danger500Translucent',
}

const AUDIO_TRANSCRIPTION_STATUS_COLOR = {
  [AudioRecordTranscriptionStatus.Ready]: 'success500',
  [AudioRecordTranscriptionStatus.Pending]: 'warning400',
  [AudioRecordTranscriptionStatus.Processing]: 'warning600',
  [AudioRecordTranscriptionStatus.Error]: 'danger500',
}

const AUDIO_TRANSCRIPTION_STATUS_BACKGROUND_COLOR = {
  [AudioRecordTranscriptionStatus.Ready]: 'success500Translucent',
  [AudioRecordTranscriptionStatus.Pending]: 'warning400Translucent',
  [AudioRecordTranscriptionStatus.Processing]: 'warning600Translucent',
  [AudioRecordTranscriptionStatus.Error]: 'danger500Translucent',
}

const AUDIO_TRANSCRIPTION_STATUS_TEXT = {
  [AudioRecordTranscriptionStatus.Ready]: 'Recognized',
  [AudioRecordTranscriptionStatus.Processing]: 'In progress',
  [AudioRecordTranscriptionStatus.Error]: 'Error',
}

function getAudioTranscriptionStatusColors(
  status?: AudioRecordTranscriptionStatus | null,
): { color: string; background: string } {
  if (!status) return DEFAULT_STATUS_COLORS

  return {
    color:
      AUDIO_TRANSCRIPTION_STATUS_COLOR[status] || DEFAULT_STATUS_COLORS.color,
    background:
      AUDIO_TRANSCRIPTION_STATUS_BACKGROUND_COLOR[status] ||
      DEFAULT_STATUS_COLORS.background,
  }
}

function getAudioTranscriptionStatusText(
  status?: AudioRecordTranscriptionStatus | null,
): string | null {
  if (!status) return null

  return AUDIO_TRANSCRIPTION_STATUS_TEXT[status] || null
}

async function downloadDefectImage(defect?: InspectionDefectFragment) {
  const defectPhotoUrl = defect?.editedPhotoUrl || defect?.originalPhotoUrl

  if (!defectPhotoUrl) return

  const response = await fetch(`${CORS}${defectPhotoUrl}`)
  if (!response.ok) {
    throw new Error('Something went wrong. Unable to download image')
  }

  const blob = await response.blob()
  const fileUrl = URL.createObjectURL(blob)
  const fileName = `figure-${defect.orderNumber}-${defect.id.slice(0, 8)}.png`

  File.downloadFile(fileUrl, fileName)

  URL.revokeObjectURL(fileUrl)
}

async function downloadZipDefectImages(
  defects?: InspectionDefectFragment[],
  zipName?: string,
) {
  if (!defects?.length) return

  const zip = new JSZip()
  const fileName = zipName || 'defectImages'

  await Async.forEachPromise(defects, async defect => {
    const photoUrl = defect.editedPhotoUrl || defect.originalPhotoUrl
    if (!photoUrl) return

    const response = await fetch(`${CORS}${photoUrl}`)
    if (!response.ok) {
      throw new Error('Something went wrong. Unable to download images')
    }

    const blob = await response.blob()
    zip.file(
      `${fileName}/figure-${defect.orderNumber}-${defect.id.slice(0, 8)}.png`,
      blob,
    )
  })

  const zipContent = await zip.generateAsync({ type: 'blob' })
  const fileUrl = URL.createObjectURL(zipContent)

  File.downloadFile(fileUrl, fileName)

  URL.revokeObjectURL(fileUrl)
}

async function getCompressedImageUrl(defect: InspectionDefectFragment) {
  if (defect.editedPhotoUrl) {
    return Image.compressImageFromUrl(defect.editedPhotoUrl)
  }
  if (defect.compressedOriginalPhotoUrl) {
    return defect.compressedOriginalPhotoUrl
  }
  return Image.compressImageFromUrl(defect.originalPhotoUrl)
}

export default {
  downloadZipDefectImages,
  downloadDefectImage,
  getAudioTranscriptionStatusColors,
  getAudioTranscriptionStatusText,
  getCompressedImageUrl,
}
