import ExifReader from 'exifreader'
import { compressAccurately } from 'lib-image-conversion'
import { DateTime } from 'luxon'

import { TIME_EXIF_FORMAT } from 'Constants/date'
import { DEFAULT_FILE_SIZE_KB } from 'Constants/file'

function downloadFile(url?: string | null, name?: string) {
  if (!url) return

  const a = document.createElement('a')
  a.href = url
  a.download = name || url.substring(url.lastIndexOf('/') + 1)
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

async function compressFile(file: File, size = DEFAULT_FILE_SIZE_KB) {
  const compressedBlobData = await compressAccurately(file, size)
  const compressedFile = new File([compressedBlobData], file.name, {
    type: compressedBlobData.type,
  })
  return compressedFile
}

async function getOriginalDateFromFile(file?: File) {
  if (!file) return null

  const fileTags = await ExifReader.load(file)
  const fileOriginalDate = fileTags.DateTimeOriginal?.value?.[0]
  const fileCreatedAt = fileOriginalDate
    ? DateTime.fromFormat(fileOriginalDate, TIME_EXIF_FORMAT).toISO()
    : null

  return fileCreatedAt
}

export default { downloadFile, compressFile, getOriginalDateFromFile }
