import { useSubscription } from '@apollo/client'

import {
  InspectionDefectDocument,
  InspectionDefectUpdatedDocument,
} from 'GraphQL/Main/TypedDocuments'

function InspectionDefectSubscriptionManager() {
  useSubscription(InspectionDefectUpdatedDocument, {
    onSubscriptionData: async ({ subscriptionData, client }) => {
      const defectId = subscriptionData.data?.inspectionDefectUpdated.id
      if (!defectId) return

      client
        .query({
          query: InspectionDefectDocument,
          variables: {
            id: defectId,
          },
          fetchPolicy: 'network-only',
        })
        .then()
    },
  })

  return null
}

export default InspectionDefectSubscriptionManager
