import { persistState } from '@storeon/localstorage'
import { createStoreon, StoreonStore } from 'storeon'

import { STORE } from 'Config'

import Shared from 'Services/Shared'

import {
  Events as FloorPlanNotesEvents,
  INITIAL_STATE as FLOOR_PLAN_NOTES_STATE,
  KEY as FLOOR_PLAN_NOTES_KEY,
  module as floorPlanNotesModule,
  State as FloorPlanNotesState,
} from './floorPlanNotes'
import {
  Events as PasswordResetStateEvents,
  INITIAL_STATE as PASSWORD_RESET_INITIAL_STATE,
  KEY as PASSWORD_RESET_STATE_KEY,
  module as passwordResetStateModule,
  State as PasswordResetStateState,
} from './passwordResetState'
import {
  Events as ServiceAppointmentsEvents,
  INITIAL_STATE as SERVICE_APPOINTMENTS_STATE,
  KEY as SERVICE_APPOINTMENTS_KEY,
  module as serviceAppointmentsModule,
  State as ServiceAppointmentsState,
} from './serviceAppointments'
import {
  Events as SessionEvents,
  INITIAL_STATE as SESSION_INITIAL_STATE,
  KEY as SESSION_KEY,
  module as sessionModule,
  State as SessionState,
} from './session'

export const STORE_CLEAR = 'clear'

interface MainEvents {
  clear: void
}

type State = SessionState &
  PasswordResetStateState &
  FloorPlanNotesState &
  ServiceAppointmentsState
type Events = MainEvents &
  SessionEvents &
  PasswordResetStateEvents &
  FloorPlanNotesEvents &
  ServiceAppointmentsEvents

type Store = StoreonStore<State, Events>

function createStore() {
  const modules = [
    sessionModule,
    passwordResetStateModule,
    floorPlanNotesModule,
    serviceAppointmentsModule,

    persistState(
      [
        SESSION_KEY,
        PASSWORD_RESET_STATE_KEY,
        FLOOR_PLAN_NOTES_KEY,
        SERVICE_APPOINTMENTS_KEY,
      ],
      {
        key: STORE.KEY,
      },
    ),
  ]

  const store = createStoreon<State, Events>(modules)
  Shared.setStore(store)

  store.on(STORE_CLEAR, state => ({
    ...state,
    [SESSION_KEY]: SESSION_INITIAL_STATE,
    [SERVICE_APPOINTMENTS_KEY]: SERVICE_APPOINTMENTS_STATE,
    [PASSWORD_RESET_STATE_KEY]: PASSWORD_RESET_INITIAL_STATE,
    [FLOOR_PLAN_NOTES_KEY]: FLOOR_PLAN_NOTES_STATE,
  }))

  return store
}

export { createStore }
export type { Store }
