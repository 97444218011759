import React, { useCallback, useEffect, useState } from 'react'

import {
  Document as PDFDocument,
  Image,
  Page,
  PDFViewer,
  View,
} from '@react-pdf/renderer'

import { Loader } from 'Components/UI'

import { CORS } from 'Config'

import { FloorPlanFragment } from 'GraphQL/Admin/TypedDocuments'
import {
  InspectionDefectFragment,
  InspectionDefectKind,
  InspectionFragment,
} from 'GraphQL/Main/TypedDocuments'

import toast from 'Services/Toast'

import Utils from 'Utils'

import PropertyDescriptions from './pages/PropertyDescriptions'
import {
  DefectAssessment,
  FloorPlans,
  MajorDefects,
  MediaContent,
  MinorDefects,
  PurposeCopyrightContent,
  SignatureContent,
  TermsAndConditions,
  TitleContent,
} from './pages'
import styles from './styles'

type Props = {
  inspection?: InspectionFragment
  inspectionDefects?: InspectionDefectFragment[]
  floorPlans?: FloorPlanFragment[]
  withoutHeader?: boolean
  onLoadSuccess?: () => void
}

function InspectionPDF({
  inspection,
  inspectionDefects,
  floorPlans,
  withoutHeader,
  onLoadSuccess,
}: Props) {
  const [defects, setDefects] = useState<{
    majorDefects: InspectionDefectFragment[]
    minorDefects: InspectionDefectFragment[]
    compressedDefects: InspectionDefectFragment[]
  }>({
    majorDefects: [],
    minorDefects: [],
    compressedDefects: [],
  })
  const [compressing, setCompressing] = useState(false)

  const compressDefectImages = useCallback(async () => {
    if (!inspectionDefects) return

    try {
      setCompressing(true)

      const majors: InspectionDefectFragment[] = []
      const minors: InspectionDefectFragment[] = []
      const compressed: InspectionDefectFragment[] = []

      await Utils.Async.forEachPromise(inspectionDefects, async defect => {
        const compressedImageUrl =
          await Utils.InspectionDefect.getCompressedImageUrl(defect)

        compressed.push({
          ...defect,
          ...(defect.editedPhotoUrl && { editedPhotoUrl: compressedImageUrl }),
          ...(defect.originalPhotoUrl &&
            !defect.editedPhotoUrl && { originalPhotoUrl: compressedImageUrl }),
        })

        switch (defect.kind) {
          case InspectionDefectKind.Major: {
            majors.push(defect)
            break
          }

          case InspectionDefectKind.Minor: {
            minors.push(defect)
            break
          }

          default:
            break
        }
      })

      setDefects({
        majorDefects: majors,
        minorDefects: minors,
        compressedDefects: compressed,
      })

      setCompressing(false)
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [inspectionDefects])

  useEffect(() => {
    compressDefectImages().then()
  }, [compressDefectImages])

  if (compressing) {
    return <Loader fullScreen size={70} />
  }

  return (
    <PDFViewer height="100%" width="100%">
      <PDFDocument onRender={onLoadSuccess}>
        <Page size="A4" style={styles.container} wrap>
          {!!inspection?.watermarkUrl && (
            <View fixed style={styles.watermarkContainer}>
              <Image
                src={`${CORS}${inspection.watermarkUrl}`}
                style={styles.watermark}
              />
            </View>
          )}

          {!withoutHeader && (
            <>
              <TitleContent inspection={inspection} />
              <PurposeCopyrightContent />
              <TermsAndConditions />
              <PropertyDescriptions inspection={inspection} />
              <DefectAssessment />
            </>
          )}

          <MajorDefects
            audioTranscription={inspection?.audioRecord?.transcription}
            descriptionHidden={withoutHeader}
            inspectionDefects={defects.majorDefects}
          />
          <MinorDefects
            audioTranscription={inspection?.audioRecord?.transcription}
            descriptionHidden={withoutHeader}
            inspectionDefects={defects.minorDefects}
          />
          {!withoutHeader && <FloorPlans floorPlans={floorPlans} />}

          {!withoutHeader && <SignatureContent inspection={inspection} />}

          <MediaContent
            audioTranscription={inspection?.audioRecord?.transcription}
            inspectionDefects={defects.compressedDefects}
            isCompassArrowShowing={inspection?.isCompassArrowShowing}
            isGpsRequired={inspection?.isGpsRequired}
          />
        </Page>
      </PDFDocument>
    </PDFViewer>
  )
}
export default InspectionPDF
