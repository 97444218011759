import React, { useEffect, useRef } from 'react'

type Props = {
  observer?: IntersectionObserver
}

function LoadMoreTrigger({ observer }: Props) {
  const triggerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!observer || !triggerRef.current) return undefined

    const trigger = triggerRef.current
    observer.observe(trigger)

    return () => {
      observer.unobserve(trigger)
    }
  }, [observer])

  return <div ref={triggerRef} />
}

export default LoadMoreTrigger
