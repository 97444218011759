import React from 'react'

import { ShowModalKind, ShowModalParams } from 'Types/modals'

import { ActivityFeedGlobalModal } from 'Components/Blocks'

type Props = {
  modal?: ShowModalParams
  onClose?: () => void
}

function Modals({ modal, onClose }: Props) {
  switch (modal?.kind) {
    case ShowModalKind.ActivityFeed:
      return (
        <ActivityFeedGlobalModal
          defectId={modal.defectId}
          isOpen
          onClose={onClose}
        />
      )

    default:
      return null
  }
}

export default Modals
