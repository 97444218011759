import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { useQuery } from '@apollo/client'

import { ServiceAppointmentHeader } from 'Components/Blocks'
import InspectorServiceAppointmentTable from 'Components/Blocks/Tables/InspectorServiceAppointmentTable'
import { Column } from 'Components/UI'

import { PAGINATION_SIZES } from 'Constants/dataGrid'
import { INSPECTION_ROOT, SERVICE_APPOINTMENT_ROOT } from 'Constants/paths'

import {
  InspectorServiceAppointmentsDocument,
  SortInput,
} from 'GraphQL/Main/TypedDocuments'

function ServiceAppointments() {
  const history = useHistory()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(PAGINATION_SIZES[0])
  const [totalPages, setTotalPages] = useState(0)
  const [sort, setSort] = useState<SortInput>()
  const [search, setSearch] = useState('')

  const { data, loading } = useQuery(InspectorServiceAppointmentsDocument, {
    variables: {
      page,
      limit: pageSize,
      search: search || undefined,
      sort,
    },
  })

  useEffect(() => {
    if (loading) {
      return
    }

    setTotalPages(data?.inspectorServiceAppointments.pages || 0)
  }, [loading, data])

  const serviceAppointments = useMemo(
    () => data?.inspectorServiceAppointments.rows || [],
    [data],
  )

  const handleClickOnServiceAppointment = useCallback(
    (serviceAppointmentId: string) => {
      history.push(SERVICE_APPOINTMENT_ROOT(serviceAppointmentId))
    },
    [history],
  )

  const handleClickRow = useCallback(
    (inspectionId: string) => {
      history.push(INSPECTION_ROOT(inspectionId))
    },
    [history],
  )

  return (
    <Column fullHeight>
      <ServiceAppointmentHeader onChange={setSearch} />

      <Column fullHeight m={6} minHeight={0}>
        <InspectorServiceAppointmentTable
          data={serviceAppointments}
          loading={loading}
          page={page}
          pageSize={pageSize}
          sort={sort}
          totalPages={totalPages}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onChangeSort={setSort}
          onClickRow={handleClickRow}
          onServiceAppointmentClick={handleClickOnServiceAppointment}
        />
      </Column>
    </Column>
  )
}

export default ServiceAppointments
