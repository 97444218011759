import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GridOnIcon from '@mui/icons-material/GridOn'
import { Tooltip } from '@mui/material'
import { DateTime } from 'luxon'

import {
  ServiceAppointmentCalendar,
  ServiceAppointmentFilter,
  ServiceAppointmentHeader,
} from 'Components/Blocks'
import AdminServiceAppointmentTable from 'Components/Blocks/Tables/AdminServiceAppointmentTable'
import { Column, DateRangeInput, IconButton, Row } from 'Components/UI'

import { PAGINATION_SIZES } from 'Constants/dataGrid'
import { INSPECTION_ROOT, SERVICE_APPOINTMENT_ROOT } from 'Constants/paths'
import {
  ServiceAppointmentColumn,
  TableView,
} from 'Constants/serviceAppointments'

import {
  ServiceAppointmentListDocument,
  SortInput,
  SortInputOrder,
} from 'GraphQL/Admin/TypedDocuments'

import { useServiceAppointmentsState } from 'Hooks'

function ServiceAppointments() {
  const history = useHistory()
  const theme = useTheme()
  const { state, updateDateRange, updateTableView, clearFilters } =
    useServiceAppointmentsState()

  const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>(
    state.view === TableView.Grid ? 'no-cache' : 'cache-and-network',
  )
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(PAGINATION_SIZES[0])
  const [totalPages, setTotalPages] = useState(0)
  const [sort, setSort] = useState<SortInput | undefined>(
    state.view === TableView.Calendar
      ? {
          column: ServiceAppointmentColumn.ScheduledStartAt,
          order: SortInputOrder.Asc,
        }
      : undefined,
  )
  const [search, setSearch] = useState('')

  const { data, loading } = useQuery(ServiceAppointmentListDocument, {
    context: {
      admin: true,
    },
    variables: {
      page,
      limit: pageSize,
      sort,
      ...(search && { search }),
      ...(state.dateRange?.from && {
        scheduledFrom: state.dateRange?.from,
      }),
      ...(state.dateRange?.to && { scheduledTo: state.dateRange?.to }),
      ...(state.filters?.statuses && { status: state.filters?.statuses }),
    },
    fetchPolicy,
  })

  useEffect(() => {
    if (loading) {
      return
    }

    setTotalPages(data?.serviceAppointmentList.pages || 0)
  }, [loading, data])

  useEffect(() => {
    if (state.view !== TableView.Calendar) return

    setSort({
      column: ServiceAppointmentColumn.ScheduledStartAt,
      order: SortInputOrder.Asc,
    })
  }, [state])

  const { serviceAppointments, count } = useMemo(
    () => ({
      count: data?.serviceAppointmentList.count,
      serviceAppointments: data?.serviceAppointmentList.rows || [],
    }),
    [data],
  )

  const color = useMemo(
    () => ({
      activeIcon: themeGet('colors.primary500')({ theme }),
      defaultIcon: themeGet('colors.neutral500')({ theme }),
    }),
    [theme],
  )

  const handleClickOnServiceAppointment = useCallback(
    (serviceAppointmentId: string) => {
      history.push(SERVICE_APPOINTMENT_ROOT(serviceAppointmentId))
    },
    [history],
  )

  const handleClickRow = useCallback(
    (inspectionId: string) => {
      history.push(INSPECTION_ROOT(inspectionId))
    },
    [history],
  )

  const handleChangeDate = useCallback(
    (newDate?: { from: DateTime; to: DateTime }) => {
      if (!newDate) {
        updateDateRange(undefined)
        return
      }

      const fromISO = newDate && newDate.from.startOf('day').toUTC(0).toISO()
      const toISO = newDate && newDate.to.endOf('day').toUTC(0).toISO()

      if (!fromISO || !toISO) return

      setPage(0)
      setPageSize(PAGINATION_SIZES[0])

      updateDateRange({
        from: fromISO,
        to: toISO,
      })
    },
    [updateDateRange],
  )

  const handleChangeSearch = useCallback<
    NonNullable<ComponentProps<typeof ServiceAppointmentHeader>['onChange']>
  >(value => {
    setSearch(value)
    setPage(0)
    setPageSize(PAGINATION_SIZES[0])
  }, [])

  const handleSetGridView = useCallback(() => {
    setFetchPolicy('no-cache')
    setSort?.(undefined)
    setPage(0)
    setPageSize(PAGINATION_SIZES[0])

    updateTableView(TableView.Grid)
  }, [updateTableView])

  const handleSetCalendarView = useCallback(() => {
    setFetchPolicy('cache-and-network')
    setSort?.({
      column: ServiceAppointmentColumn.ScheduledStartAt,
      order: SortInputOrder.Asc,
    })

    clearFilters()
    updateTableView(TableView.Calendar)

    const now = DateTime.now()
    const from = now.startOf('day')
    const to = now.endOf('week')
    if (state.dateRange) return

    handleChangeDate({
      from,
      to,
    })
  }, [state, updateTableView, handleChangeDate, clearFilters])

  return (
    <Column fullHeight>
      <ServiceAppointmentHeader onChange={handleChangeSearch} />

      <Column fullHeight m={6} minHeight={0}>
        <Row fullWidth mb={4} spaceBetween>
          <Row gap={4}>
            {state.view === TableView.Grid && <ServiceAppointmentFilter />}

            <Tooltip arrow placement="top" title="Scheduled start date">
              <Row>
                <DateRangeInput
                  from={state.dateRange?.from}
                  secondary
                  to={state.dateRange?.to}
                  withClear={state.view === TableView.Grid}
                  withToday
                  onChange={handleChangeDate}
                />
              </Row>
            </Tooltip>
          </Row>

          <Row gap={4}>
            <Tooltip arrow placement="top" title="Table view">
              <IconButton
                style={{
                  color:
                    state.view === TableView.Grid
                      ? color.activeIcon
                      : color.defaultIcon,
                }}
                onClick={handleSetGridView}
              >
                <GridOnIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow placement="top" title="Calendar view">
              <IconButton
                style={{
                  color:
                    state.view === TableView.Calendar
                      ? color.activeIcon
                      : color.defaultIcon,
                }}
                onClick={handleSetCalendarView}
              >
                <CalendarTodayIcon />
              </IconButton>
            </Tooltip>
          </Row>
        </Row>

        {state.view === TableView.Grid && (
          <AdminServiceAppointmentTable
            data={serviceAppointments}
            loading={loading}
            page={page}
            pageSize={pageSize}
            sort={sort}
            totalPages={totalPages}
            onChangePage={setPage}
            onChangePageSize={setPageSize}
            onChangeSort={setSort}
            onClickRow={handleClickRow}
            onServiceAppointmentClick={handleClickOnServiceAppointment}
          />
        )}

        {state.view === TableView.Calendar && (
          <ServiceAppointmentCalendar
            count={count}
            data={serviceAppointments}
            dateRange={state.dateRange}
            loading={loading}
            page={page}
            sort={sort}
            onChangeDate={handleChangeDate}
            onChangePage={setPage}
            onChangeSort={setSort}
            onClickRow={handleClickRow}
            onServiceAppointmentClick={handleClickOnServiceAppointment}
          />
        )}
      </Column>
    </Column>
  )
}

export default ServiceAppointments
