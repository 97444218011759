import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

type DragContainerProps = {
  dark?: boolean
}

const dragContainerDarkCss = ({ dark }: DragContainerProps) =>
  dark &&
  css`
    background-color: ${themeGet('colors.neutral500')};
  `

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 700px;
  height: 400px;
  outline: none;
  border-radius: 10px;
  user-select: none;
  background-color: ${themeGet('colors.neutral700')};
`

export const DragContainer = styled.div<DragContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;

  ${dragContainerDarkCss}
`
