import { StoreonModule } from 'storeon'
import { NoteConfig } from 'Types/konva'

import omitBy from 'lodash/omitBy'

const KEY = 'floorPlanNotes'

type StateValues = Record<string, NoteConfig>

interface State {
  [KEY]: StateValues
}

interface Events {
  'floorPlanNotes/update': { note: NoteConfig }
  'floorPlanNotes/remove': { noteId: string }
  'floorPlanNotes/bulkRemove': { floorPlanId: string }
  'floorPlanNotes/clear': void
}

export const INITIAL_STATE: StateValues = {}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))

  store.on('floorPlanNotes/update', (state, { note }) => {
    if (!note.id) return state

    return {
      [KEY]: {
        ...state[KEY],
        [note.id]: {
          ...state[KEY]?.[note.id],
          ...note,
        },
      },
    }
  })

  store.on('floorPlanNotes/remove', (state, { noteId }) => {
    const updatedNotes = { ...state[KEY] }
    delete updatedNotes[noteId]

    return {
      [KEY]: updatedNotes,
    }
  })

  store.on('floorPlanNotes/bulkRemove', (state, { floorPlanId }) => {
    const updatedNotes = omitBy(
      { ...state[KEY] },
      note => note.floorPlanId === floorPlanId,
    )

    return {
      [KEY]: updatedNotes,
    }
  })

  store.on('floorPlanNotes/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

export { KEY, module }
export type { Events, State }
