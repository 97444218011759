import React, { ComponentProps } from 'react'

import { Autocomplete, TextField } from '@mui/material'

import Caption from 'Components/UI/Forms/Caption'
import Loader from 'Components/UI/Loader'

import { Wrapper, WrapperProps } from './styles'

type OptionType<T> = {
  label: string
  value: string
} & T

type Props<T> = WrapperProps & {
  caption?: string
  required?: boolean
  label?: React.ReactNode
  placeholder?: string
  success?: boolean
  danger?: boolean
  disabled?: boolean
  small?: boolean
  defaultValue?: OptionType<T>
  value?: OptionType<T> | null
  options?: OptionType<T>[]
  loading?: boolean
  disableClearable?: boolean
  disablePortal?: boolean
  readOnly?: boolean
  multiple?: boolean
  onChange?: ComponentProps<typeof Autocomplete<OptionType<T>>>['onChange']
  onChangeInput?: ComponentProps<typeof TextField>['onChange']
}

function Select<T>({
  caption,
  required,
  label,
  placeholder,
  success,
  danger,
  disabled,
  small,
  defaultValue,
  value,
  options,
  loading,
  disableClearable,
  disablePortal,
  readOnly,
  multiple,
  onChange,
  onChangeInput,
  ...rest
}: Props<T>) {
  return (
    <Wrapper {...rest}>
      <Autocomplete<OptionType<T>>
        componentsProps={{
          popper: {
            sx: {
              zIndex: 10000,
            },
          },
        }}
        defaultValue={defaultValue}
        // NOTE: material TS is complaining about the non false type
        // @ts-ignore
        disableClearable={disableClearable}
        disablePortal={disablePortal}
        disabled={disabled}
        filterOptions={options => options}
        loading={loading}
        loadingText="Loading..."
        // NOTE: material TS is complaining about the non false type
        // @ts-ignore
        multiple={multiple}
        options={options || []}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly,
              endAdornment: (
                <>
                  {loading && <Loader size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            placeholder={placeholder}
            required={required}
            onChange={onChangeInput}
          />
        )}
        selectOnFocus={false}
        size={small ? 'small' : 'medium'}
        value={value}
        onChange={onChange}
      />

      <Caption caption={caption} danger={danger} mt={2} success={success} />
    </Wrapper>
  )
}

export default Select
