import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'

import debounce from 'lodash/debounce'

import {
  DataGrid,
  DeleteUserDropdown,
  Header,
  InviteInspectorModal,
  UserDropdown,
} from 'Components/Blocks'
import { Button, Column, Input, Row, Text } from 'Components/UI'

import { PAGINATION_SIZES } from 'Constants/dataGrid'

import {
  SortInput,
  SortInputOrder,
  UserListDocument,
  UserRole,
} from 'GraphQL/Admin/TypedDocuments'

import { InspectorColumns, InspectorColumnsType } from './types'
import { processInspectorsData } from './utils'

const SEARCH_DEBOUNCE = 350

enum SortingColumns {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  LastName = 'lastName',
}

const DEFAULT_SORT: SortInput[] = [
  {
    column: InspectorColumns.CreatedAt,
    order: SortInputOrder.Desc,
  },
]

function Inspectors() {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(PAGINATION_SIZES[0])
  const [totalPages, setTotalPages] = useState(0)
  const [sort, setSort] = useState<SortInput[]>(DEFAULT_SORT)
  const [search, setSearch] = useState('')
  const [userInviteModalOpen, setUserInviteModalOpen] = useState(false)

  const {
    data,
    loading,
    refetch: usersRefetch,
  } = useQuery(UserListDocument, {
    context: {
      admin: true,
    },
    variables: {
      page,
      limit: pageSize,
      search: search || undefined,
      sort,
      roles: [UserRole.User],
    },
  })

  useEffect(() => {
    if (loading) {
      return
    }

    setTotalPages(data?.users.pages || 0)
  }, [loading, data])

  const inspectors = useMemo(
    () => processInspectorsData(data?.users.rows),
    [data],
  )

  const debouncedSearch = useMemo(() => {
    return debounce(setSearch, SEARCH_DEBOUNCE)
  }, [])

  const handleChangeSort = useCallback((sort: GridSortModel) => {
    if (sort.length === 0) {
      setSort(DEFAULT_SORT)
    } else {
      const column = sort[0].field
      const order =
        sort[0].sort === SortInputOrder.Desc
          ? SortInputOrder.Desc
          : SortInputOrder.Asc

      if (column === InspectorColumns.Name) {
        setSort([
          { column: SortingColumns.FirstName, order },
          { column: SortingColumns.LastName, order },
        ])
      } else {
        setSort([
          {
            column,
            order,
          },
        ])
      }
    }

    setPage(0)
  }, [])

  const handleChangePageSize = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePageSize']>
  >(size => {
    setPageSize(size)
  }, [])

  const handleChangePage = useCallback<
    NonNullable<ComponentProps<typeof DataGrid>['onChangePage']>
  >(page => {
    setPage(page)
  }, [])

  const handleSearch = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(
    event => {
      debouncedSearch(event.target.value)
    },
    [debouncedSearch],
  )

  const handleOpenModal = useCallback(() => {
    setUserInviteModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    usersRefetch().then()
    setUserInviteModalOpen(false)
  }, [usersRefetch])

  const handleDeleteUserDropdownSuccessRequest = useCallback(() => {
    usersRefetch().then()
  }, [usersRefetch])

  const columns: GridColDef<InspectorColumnsType>[] = useMemo(
    () => [
      {
        field: InspectorColumns.CreatedAt,
        flex: 1,
        renderHeader: () => <Text action4>Date added</Text>,
      },
      {
        field: InspectorColumns.Name,
        flex: 1,
        renderHeader: () => <Text action4>Name</Text>,
        renderCell: ({ value }) => <Text ellipsis>{value || 'N/A'}</Text>,
      },
      {
        field: InspectorColumns.Email,
        flex: 1,
        renderHeader: () => <Text action4>Email</Text>,
        sortable: false,
      },
      {
        field: InspectorColumns.Controls,
        renderHeader: () => null,
        renderCell: row => {
          return (
            <DeleteUserDropdown
              user={row.row.user}
              onSuccessRequest={handleDeleteUserDropdownSuccessRequest}
            />
          )
        },
        sortable: false,
      },
    ],
    [handleDeleteUserDropdownSuccessRequest],
  )

  return (
    <Column fullHeight>
      <Header>
        <Row center fullWidth spaceBetween>
          <Text header5 inverse>
            Inspectors
          </Text>

          <Row gap={4}>
            <Input
              inverse
              placeholder="Search..."
              renderBeforeElement={() => <SearchIcon />}
              small
              onChange={handleSearch}
            />

            <UserDropdown />
          </Row>
        </Row>
      </Header>

      <Column fullHeight m={6} minHeight={0}>
        <Row center justifyContent={'flex-end'} mb={6}>
          <Button small onClick={handleOpenModal}>
            INVITE NEW USER
          </Button>
          <InviteInspectorModal
            isOpen={userInviteModalOpen}
            title="Invite new user"
            onClose={handleCloseModal}
          />
        </Row>

        <DataGrid
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          rows={inspectors}
          totalPages={totalPages}
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
          onSortModelChange={handleChangeSort}
        />
      </Column>
    </Column>
  )
}

export default Inspectors
