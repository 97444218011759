import { ShapeConfig } from 'konva/lib/Shape'
import { NoteConfig } from 'Types/konva'

export const BREAKPOINT_WIDTH = {
  SMALL: 590,
}

// Defect
export const STAGE_HEIGHT = 630
export const SHAPE_DIMENSIONS = {
  width: 105,
  height: 24,
}
export const SHAPE_COORDINATES = {
  start_x: 0,
  start_y: 8,
  top_y: 0,
  body_x: 70,
  head_x: SHAPE_DIMENSIONS.width,
  head_y: 12,
  bottom_y: SHAPE_DIMENSIONS.height,
  body_y: 16,
}
export const INITIAL_SHAPE_CONFIG: ShapeConfig = {
  y: STAGE_HEIGHT / 2 - SHAPE_DIMENSIONS.height / 2,
  rotationDeg: -45,
  ...SHAPE_DIMENSIONS,
}

export const INITIAL_NOTE_CONFIG: NoteConfig = {
  width: 50,
  x: 150,
  y: 150,
  text: '',
}

// Map
export const MAP_STAGE_WIDTH = 256

// FloorPlan
export const FLOOR_PLAN_IMAGE_SCALE = 0.4
