import React from 'react'

import { Loader, Modal, ProgressBar, Row } from 'Components/UI'

type Props = {
  progress?: number
  isOpen?: boolean
  onClose?: () => void
}

function FileUploadProgressModal({ progress, isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title="Uploading file"
      zIndex={3000}
      onClose={onClose}
    >
      {!progress ? (
        <Row justifyCenter>
          <Loader />
        </Row>
      ) : (
        <ProgressBar value={progress} />
      )}
    </Modal>
  )
}

export default FileUploadProgressModal
