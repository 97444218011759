import styled from 'styled-components'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

type CardProps = MarginProps & PaddingProps

export const Card = styled.div<CardProps>`
  width: 100%;
  max-width: 448px;
  background-color: ${themeGet('colors.background.block')};
  border-radius: 4px;

  ${margin};
  ${padding};
`

export const StyledForm = styled.form`
  align-items: center;
  justify-items: center;
  flex-direction: column;
  width: 100%;
`
