import styled, { css } from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import MaterialIconButton, {
  IconButtonProps as MaterialIconButtonProps,
} from '@mui/material/IconButton'

type IconButtonProps = MarginProps &
  LayoutProps &
  MaterialIconButtonProps & {
    active?: boolean
  }

const iconButtonActiveCss = ({ active }: IconButtonProps) =>
  active &&
  css`
    background-color: ${themeGet('colors.neutral700')};
  `

const IconButton = styled(MaterialIconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('fontWeights.1')};

    ${margin}
    ${layout}
    ${iconButtonActiveCss}
  }
`

export default IconButton
