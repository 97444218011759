import React, { useCallback, useMemo } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { SortInputOrder } from 'GraphQL/Main/TypedDocuments'

import { Container } from './styles'

type Props = {
  sortOrder?: SortInputOrder
  active?: boolean
  children?: React.ReactNode
  onChangeSort?: (sortOrder: SortInputOrder | null) => void
}

function Sort({ sortOrder, active, children, onChangeSort }: Props) {
  const theme = useTheme()
  const color = useMemo(
    () => ({
      sortIcon: themeGet('colors.primary500')({ theme }),
    }),
    [theme],
  )

  const sortIconContent = useMemo(() => {
    if (!sortOrder || !active) return null

    switch (sortOrder) {
      case SortInputOrder.Asc:
        return (
          <ArrowUpwardIcon style={{ fontSize: 16, color: color.sortIcon }} />
        )

      case SortInputOrder.Desc:
        return (
          <ArrowDownwardIcon style={{ fontSize: 16, color: color.sortIcon }} />
        )

      default:
        return null
    }
  }, [active, sortOrder, color])

  const handleChangeSort = useCallback(() => {
    if (!active) {
      onChangeSort?.(SortInputOrder.Asc)
      return
    }

    switch (sortOrder) {
      case SortInputOrder.Asc: {
        onChangeSort?.(SortInputOrder.Desc)
        break
      }

      case SortInputOrder.Desc: {
        onChangeSort?.(null)
        break
      }

      default: {
        onChangeSort?.(SortInputOrder.Asc)
        break
      }
    }
  }, [active, sortOrder, onChangeSort])

  return (
    <Container gap={1} onClick={handleChangeSort}>
      {children}
      {sortIconContent}
    </Container>
  )
}

export default Sort
