import { useCallback, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import superagent from 'superagent'
import Utils from 'Utils'

import { FileSignDocument, FileType } from 'GraphQL/Main/TypedDocuments'

import { Session } from 'Services/Store/session'
import toast from 'Services/Toast'

const MIN_PROGRESS = 0
const MAX_PROGRESS = 100

type GetSignedUrlProps = {
  file: File
  type: FileType
  serviceAppointmentId?: string
  inspectionId?: string
  floorPlanId?: string
}

function useSignFile() {
  const [fileSignMutation] = useMutation(FileSignDocument)
  const [progress, setProgress] = useState(MIN_PROGRESS)
  const [loading, setLoading] = useState(false)
  const reset = useRef(false)

  const resetProgress = useCallback(() => {
    setProgress(MIN_PROGRESS)
  }, [])

  const getSignedUrl = useCallback(
    async ({
      file,
      type,
      serviceAppointmentId,
      inspectionId,
      floorPlanId,
    }: GetSignedUrlProps) => {
      try {
        setLoading(true)

        const signedFile = await fileSignMutation({
          variables: {
            type,
            fileName: file.name,
            ...(serviceAppointmentId && { serviceAppointmentId }),
            ...(inspectionId && { inspectionId }),
            ...(floorPlanId && { floorPlanId }),
          },
        })
        if (!signedFile?.data?.fileSign) {
          return null
        }

        const accessToken = Session.getToken()
        const request = superagent
          .post(signedFile.data.fileSign.uploadUrl)
          .set('Authorization', `Bearer ${accessToken}`)
        request.on('progress', event => {
          if (reset.current) {
            request.abort()
            reset.current = false
            setLoading(false)

            return
          }

          setProgress(
            event.total
              ? (event.loaded / event.total) * MAX_PROGRESS
              : MIN_PROGRESS,
          )
        })

        // todo type problem
        // @ts-ignore
        const response = await request.attach('file', file)

        setProgress(MAX_PROGRESS)
        resetProgress()
        setLoading(false)

        if (response?.body?.publicUrl && response?.body?.signedUrl) {
          return {
            ...signedFile,
            data: {
              ...signedFile.data,
              fileSign: {
                ...signedFile.data.fileSign,
                publicUrl: response.body.publicUrl,
                signedUrl: response.body.signedUrl,
              },
            },
          }
        }

        return null
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
        setLoading(false)

        return null
      }
    },
    [reset, resetProgress, fileSignMutation],
  )

  const abortUpload = useCallback(() => {
    if (progress !== MAX_PROGRESS) {
      reset.current = true
    }

    resetProgress()
    setLoading(false)
  }, [progress, resetProgress])

  return {
    loading,
    progress,
    getSignedUrl,
    resetProgress,
    abortUpload,
  }
}

export default useSignFile
