import React, { useCallback, useState } from 'react'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, IconButton, Table, Tooltip } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DateTime } from 'luxon'
import Utils from 'Utils'

import { Dot, Row, Text } from 'Components/UI'

import { ServiceAppointmentFragment } from 'GraphQL/Main/TypedDocuments'

import { ExpandedTable } from '../styles'

type Props = {
  row: ServiceAppointmentFragment
  onClickRow?: (inspectionId: string) => void
  onServiceAppointmentClick?: () => void
}

export default function DataRow({
  row,
  onClickRow,
  onServiceAppointmentClick,
}: Props) {
  const [open, setOpen] = useState(false)

  const handleCollapse = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setOpen(!open)
    },
    [open],
  )

  return (
    <>
      <TableRow
        key={row.id}
        sx={{ height: '66px' }}
        onClick={onServiceAppointmentClick}
      >
        <TableCell width="50px">
          <IconButton
            disabled={!row.inspections?.length}
            size="small"
            onClick={handleCollapse}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.createdAt
            ? DateTime.fromISO(row.createdAt).toLocaleString()
            : 'N/A'}
        </TableCell>
        <TableCell>
          <Tooltip arrow placement="top" title={row.subject}>
            <Text ellipsis maxWidth="320px">
              {row.subject || 'N/A'}
            </Text>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Text ellipsis maxWidth="240px">
            {row.project.name || 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          <Text ellipsis>{row.totalInspectionDefectCount}</Text>
        </TableCell>
        <TableCell>
          {row.status ? (
            <Row center gap={3} overflow="hidden">
              <Dot
                color={Utils.ServiceAppointment.getStatusColor(row.status)}
                size={9}
              />
              <Text ellipsis>
                {Utils.ServiceAppointment.getStatusText(row.status)}
              </Text>
            </Row>
          ) : (
            <Row>&mdash;</Row>
          )}
        </TableCell>
      </TableRow>
      <ExpandedTable>
        <TableCell colSpan={6} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="66px" />
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                    Opportunity Date
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                    Survey name
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                    Figures count
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.inspections?.map(inspection => (
                  <TableRow
                    key={inspection.id}
                    onClick={() => onClickRow?.(inspection.id)}
                  >
                    <TableCell />
                    <TableCell>
                      {inspection.inspectionDate
                        ? DateTime.fromISO(
                            inspection.inspectionDate,
                          ).toLocaleString()
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        arrow
                        placement="top"
                        title={inspection.name || ''}
                      >
                        <Text ellipsis maxWidth="400px">
                          {inspection.name || 'N/A'}
                        </Text>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{inspection.inspectionDefectCount}</TableCell>
                    <TableCell>
                      {inspection.status ? (
                        <Row center gap={3} overflow="hidden">
                          <Dot
                            color={Utils.Inspection.getStatusColor(
                              inspection.status,
                            )}
                            size={9}
                          />
                          <Text ellipsis>
                            {Utils.Inspection.getStatusText(inspection.status)}
                          </Text>
                        </Row>
                      ) : (
                        <Row>&mdash;</Row>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </ExpandedTable>
    </>
  )
}
