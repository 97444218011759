import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'
import Utils from 'Utils'

import { tinyLogoImage } from 'Assets/Images/PDF'

import {
  COMPANY_ABN,
  COMPANY_ADDRESS,
  COMPANY_DETAIL_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_PHONE,
} from 'Constants/company'

import { ServiceAppointmentFragment } from 'GraphQL/Main/TypedDocuments'

import styles from '../styles'

type Props = {
  serviceAppointment?: ServiceAppointmentFragment
}

function ServiceAppointmentTitleContent({ serviceAppointment }: Props) {
  return (
    <>
      <View
        style={[styles.row, styles.mt5, styles.justifyCenter, styles.alignEnd]}
      >
        <Image src={tinyLogoImage} style={styles.companyLogo} />

        <Text style={styles.subheader2}>ABN: {COMPANY_ABN}</Text>
        <Text style={[styles.subheader2, styles.ml8]}>
          Telephone: {COMPANY_PHONE}
        </Text>
        <Text style={[styles.subheader2, styles.ml8]}>
          Email: {COMPANY_EMAIL}
        </Text>
      </View>
      <View style={[styles.column, styles.center]}>
        <Text style={styles.caption2}>{COMPANY_ADDRESS}</Text>
        <Text style={styles.caption2}>{COMPANY_DETAIL_ADDRESS}</Text>
      </View>

      <View
        style={[
          styles.row,
          styles.spaceBetween,
          styles.alignStart,
          styles.mt5,
          styles.lateralPaddingContainer,
        ]}
      >
        <View style={[styles.column, styles.headerColumn]}>
          <Text style={[styles.subheader1, styles.mt5]}>PROJECT:</Text>
          <Text style={[styles.body3, styles.mt2, styles.projectName]}>
            {serviceAppointment?.project.name ? (
              serviceAppointment?.project.name
            ) : (
              <>&mdash;</>
            )}
          </Text>
        </View>

        <View style={[styles.column, styles.headerColumn]}>
          <Text style={[styles.subheader1, styles.mt5]}>INSPECTOR:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {serviceAppointment?.inspectorUser ? (
              <>
                {Utils.User.getUserIdentifier(serviceAppointment.inspectorUser)}{' '}
                & {serviceAppointment.inspectorUser.role}
              </>
            ) : (
              <>&mdash;</>
            )}
          </Text>
        </View>
      </View>
    </>
  )
}

export default ServiceAppointmentTitleContent
