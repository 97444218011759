import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import Utils from 'Utils'

import {
  Column,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Text,
} from 'Components/UI'

import { useAppContext } from 'Services/AppContext'
import Auth from 'Services/Auth'

function UserDropdown() {
  const theme = useTheme()
  const iconButtonRef = useRef(null)
  const color = useMemo(
    () => ({
      userIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )
  const { me } = useAppContext()

  const [menuListOpen, setMenuListOpen] = useState(false)

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])
  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])
  const handleLogout = useCallback(() => {
    handleCloseMenuList()

    Auth.signOut().then()
  }, [handleCloseMenuList])

  const { userFullName, userEmail } = useMemo(() => {
    const fullName = Utils.User.getFullName(me?.profile)
    const email =
      !fullName || !me?.primaryEmailCredential?.email
        ? Utils.User.getUserIdentifier(me)
        : me.primaryEmailCredential.email

    return { userFullName: fullName, userEmail: email }
  }, [me])

  return (
    <Column>
      <IconButton ref={iconButtonRef} onClick={handleOpenMenuList}>
        <AccountCircleIcon style={{ color: color.userIcon }} />
      </IconButton>

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        <Column px={5} py={3}>
          {!!userFullName && (
            <Text body3 ellipsis maxWidth="300px">
              {userFullName}
            </Text>
          )}
          <Text body4 ellipsis maxWidth="300px" muted>
            {userEmail}
          </Text>
        </Column>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon />
          Sign out
        </MenuItem>
      </MenuList>
    </Column>
  )
}

export default UserDropdown
