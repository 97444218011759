import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import ImageIcon from '@mui/icons-material/Image'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RestoreIcon from '@mui/icons-material/Restore'
import { Tooltip } from '@mui/material'

import { Column, IconButton, MenuItem, MenuList } from 'Components/UI'

import { useFileDropzone } from 'Hooks'

type Props = {
  disabled?: boolean
  disabledRemoveDrawings?: boolean
  hideRemoveDrawings?: boolean
  hideDownloadImages?: boolean
  onChangeImage?: (files: File[]) => void
  onDownloadImage?: () => void
  onShowActivity?: () => void
  onRemoveDrawings?: () => void
  onDelete?: () => void
}

function InspectionDefectDropdown({
  disabled,
  disabledRemoveDrawings,
  hideRemoveDrawings,
  hideDownloadImages,
  onChangeImage,
  onShowActivity,
  onRemoveDrawings,
  onDelete,
  onDownloadImage,
}: Props) {
  const theme = useTheme()
  const iconButtonRef = useRef(null)
  const color = useMemo(
    () => ({
      menuIcon: themeGet('colors.neutral500')({ theme }),
      deleteIcon: themeGet('colors.danger500')({ theme }),
    }),
    [theme],
  )

  const [menuListOpen, setMenuListOpen] = useState(false)

  const { openDropzone: imageOpenDropzone } = useFileDropzone({
    onDropSuccess: onChangeImage,
    acceptImageOnly: true,
  })

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])

  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])

  const handleChangeImage = useCallback(() => {
    handleCloseMenuList()
    imageOpenDropzone()
  }, [handleCloseMenuList, imageOpenDropzone])

  const handleOpenActivityFeed = useCallback(() => {
    handleCloseMenuList()
    onShowActivity?.()
  }, [handleCloseMenuList, onShowActivity])

  const handleRemoveDrawings = useCallback(() => {
    handleCloseMenuList()
    onRemoveDrawings?.()
  }, [handleCloseMenuList, onRemoveDrawings])

  const handleClickDelete = useCallback(() => {
    handleCloseMenuList()
    onDelete?.()
  }, [handleCloseMenuList, onDelete])

  const handleDownloadImage = useCallback(() => {
    handleCloseMenuList()
    onDownloadImage?.()
  }, [handleCloseMenuList, onDownloadImage])

  return (
    <Column>
      <IconButton
        disabled={disabled}
        ref={iconButtonRef}
        onClick={handleOpenMenuList}
      >
        <MoreVertIcon style={{ color: color.menuIcon, fontSize: 22 }} />
      </IconButton>

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        {!hideDownloadImages && (
          <MenuItem active onClick={handleDownloadImage}>
            <DownloadIcon />
            DOWNLOAD PHOTO
          </MenuItem>
        )}
        <MenuItem active onClick={handleChangeImage}>
          <ImageIcon />
          UPLOAD ANOTHER PHOTO
        </MenuItem>
        {/* <MenuItem active onClick={handleChangeAudio}>
          <AudiotrackIcon />
          UPLOAD ANOTHER AUDIO
        </MenuItem> */}
        <MenuItem active onClick={handleOpenActivityFeed}>
          <RestoreIcon />
          ACTIVITY FEED
        </MenuItem>
        {!hideRemoveDrawings && (
          <Tooltip
            PopperProps={{
              sx: {
                zIndex: 9999,
              },
            }}
            arrow
            placement="left"
            title={
              !disabledRemoveDrawings &&
              'This button will clear/reset all drawings on the image'
            }
          >
            <MenuItem
              active
              disabled={disabledRemoveDrawings}
              onClick={handleRemoveDrawings}
            >
              <CloseIcon />
              REMOVE ARROWS
            </MenuItem>
          </Tooltip>
        )}
        <MenuItem danger onClick={handleClickDelete}>
          <DeleteIcon />
          DELETE RECORD
        </MenuItem>
      </MenuList>
    </Column>
  )
}

export default InspectionDefectDropdown
