import { useCallback, useMemo, useRef, useState } from 'react'

import { NetworkStatus, useApolloClient, useQuery } from '@apollo/client'

import {
  InspectionDefectListDocument,
  SortInputOrder,
} from 'GraphQL/Main/TypedDocuments'

const LOAD_INSPECTION_DEFECTS_LIMIT = 15

function useInspectionDefectList({
  inspectionId = undefined,
  serviceAppointmentId = undefined,
  limit = LOAD_INSPECTION_DEFECTS_LIMIT,
}: {
  inspectionId?: string | undefined
  serviceAppointmentId?: string | undefined
  limit?: number
}) {
  const client = useApolloClient()

  const page = useRef(0)

  const [selectedInspectionDefects, setSelectedInspectionDefects] = useState(
    new Set<string>(),
  )
  const [haveMoreInspectionDefectsToLoad, setHaveMoreInspectionDefectsToLoad] =
    useState(true)

  const {
    data: inspectionDefectData,
    loading: inspectionDefectsLoading,
    networkStatus: inspectionDefectsNetworkStatus,
    fetchMore: inspectionDefectsFetchMore,
    refetch: inspectionDefectsRefetch,
  } = useQuery(InspectionDefectListDocument, {
    variables: {
      serviceAppointmentId,
      inspectionId,
      sort: [
        {
          column: 'createdAt',
          order: SortInputOrder.Asc,
        },
        {
          column: 'id',
          order: SortInputOrder.Asc,
        },
      ],
      limit,
    },
    skip: !inspectionId && !serviceAppointmentId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const {
    inspectionDefects,
    inspectionDefectsCount,
    canLoadMore,
    loadingMore,
  } = useMemo(
    () => ({
      inspectionDefects: inspectionDefectData?.inspectionDefectList.rows || [],
      inspectionDefectsCount:
        inspectionDefectData?.inspectionDefectList.count || 0,
      canLoadMore: haveMoreInspectionDefectsToLoad && !inspectionDefectsLoading,
      loadingMore: inspectionDefectsNetworkStatus === NetworkStatus.fetchMore,
    }),
    [
      inspectionDefectData,
      inspectionDefectsNetworkStatus,
      inspectionDefectsLoading,
      haveMoreInspectionDefectsToLoad,
    ],
  )

  const loadMore = useCallback(() => {
    if (
      !inspectionDefectData ||
      inspectionDefects.length >=
        inspectionDefectData?.inspectionDefectList.count ||
      inspectionDefectsNetworkStatus === NetworkStatus.loading ||
      inspectionDefectsNetworkStatus === NetworkStatus.fetchMore
    ) {
      setHaveMoreInspectionDefectsToLoad(false)
      return
    }

    page.current += 1
    setHaveMoreInspectionDefectsToLoad(true)
    inspectionDefectsFetchMore({
      variables: {
        page: page.current,
      },
    }).then()
  }, [
    inspectionDefectData,
    inspectionDefects,
    inspectionDefectsFetchMore,
    inspectionDefectsNetworkStatus,
  ])

  const loadAll = useCallback(async () => {
    return client.query({
      query: InspectionDefectListDocument,
      variables: {
        serviceAppointmentId,
        inspectionId,
        limit: 999,
      },
      fetchPolicy: 'no-cache',
    })
  }, [client, inspectionId, serviceAppointmentId])

  const refetch = useCallback(() => {
    page.current = 0
    setHaveMoreInspectionDefectsToLoad(true)

    inspectionDefectsRefetch().then()
  }, [inspectionDefectsRefetch])

  const toggleSelection = useCallback((id: string) => {
    setSelectedInspectionDefects(prevSelected => {
      const updatedSelected = new Set(prevSelected)
      if (updatedSelected.has(id)) {
        updatedSelected.delete(id)
      } else {
        updatedSelected.add(id)
      }
      return updatedSelected
    })
  }, [])

  const clearSelection = useCallback(() => {
    setSelectedInspectionDefects(new Set())
  }, [])

  return {
    inspectionDefects,
    inspectionDefectsCount,
    selectedInspectionDefects,
    loading: inspectionDefectsLoading,
    loadingMore,
    canLoadMore,
    refetch,
    loadMore,
    loadAll,
    toggleSelection,
    clearSelection,
  }
}

export default useInspectionDefectList
