import styled from 'styled-components'

import { gap, GapProps } from 'Services/Styles'

export const Container = styled.div<GapProps>`
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  cursor: pointer;
  user-select: none;

  ${gap}
`
