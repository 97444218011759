export const ERRORS_TEXT = {
  'auth.incorrectEmailOrPassword': `User doesn't exist or password is wrong`,
  'auth.emailNotFound': 'Email not found. Check email or create an account',
  'generic.validation': "Password doesn't match requirements",
  'auth.passwordTokenInvalid':
    'Password reset link expired. Please request a new link',
  'inspection.notFound':
    'Opportunity not found. Please check the details and try again',
  'auth.emailAlreadyExists': 'Email already exists',
  'generic.accessDenied': "You don't have permission to perform this action",
  'inspectionDefect.notFound': 'Defect does not exist',
  'serviceAppointment.notFound': 'Service Appointment does not exist',
}
