import React from 'react'
import { Html } from 'react-pdf-html'

import { Image, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import { compassImage, mapArrowImage, pindropImage } from 'Assets/Images'

import { CORS } from 'Config'

import { TIME_12HR_FORMAT } from 'Constants/date'
import { PDF_MAP_ARROW_COORDINATES } from 'Constants/map'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

import Utils from 'Utils'

import styles from '../styles'

type Props = {
  audioTranscription?: string | null
  inspectionDefects?: InspectionDefectFragment[]
  isGpsRequired?: boolean
  isCompassArrowShowing?: boolean
}

function MediaContent({
  audioTranscription,
  inspectionDefects,
  isGpsRequired,
  isCompassArrowShowing,
}: Props) {
  if (!inspectionDefects?.length) {
    return null
  }

  return (
    <View break style={styles.lowerZIndex}>
      {inspectionDefects.map(inspectionDefect => {
        const image =
          inspectionDefect.editedPhotoUrl || inspectionDefect.originalPhotoUrl
        const description = inspectionDefect.description || audioTranscription

        if (!image) {
          return null
        }

        return (
          <View id={inspectionDefect.id} key={inspectionDefect.id}>
            <View style={[styles.row, styles.mt8]}>
              <View style={[styles.mediaImageContainer]}>
                <View style={styles.mediaImageContent}>
                  <Image src={`${CORS}${image}`} style={styles.mediaImage} />

                  {!!inspectionDefect?.photoTakenAt && (
                    <Text style={styles.mediaImageTimestamp}>
                      {DateTime.fromISO(
                        inspectionDefect.photoTakenAt,
                      ).toLocaleString(TIME_12HR_FORMAT)}
                    </Text>
                  )}
                </View>
              </View>

              <View style={[styles.column, styles.mediaDescriptionContainer]}>
                {!!isGpsRequired && inspectionDefect.mapPhotoUrl && (
                  <View style={styles.mapImageContainer}>
                    {isCompassArrowShowing ? (
                      <>
                        <View
                          style={[
                            styles.mapArrowContainer,
                            // NOTE: aligning the arrow in the middle exactly above the dot
                            {
                              transform: `translate(-${
                                PDF_MAP_ARROW_COORDINATES.X
                              }px, -${PDF_MAP_ARROW_COORDINATES.Y}px) rotate(${
                                typeof inspectionDefect.photoDirection !==
                                'number'
                                  ? 0
                                  : inspectionDefect.photoDirection
                              })`,
                            },
                          ]}
                        >
                          <Image src={mapArrowImage} />
                        </View>

                        <View style={styles.compassContainer}>
                          <Image src={compassImage} />
                        </View>
                      </>
                    ) : (
                      <View style={styles.pindropContainer}>
                        <Image src={pindropImage} style={styles.pindropImage} />
                      </View>
                    )}

                    <Image
                      src={inspectionDefect.mapPhotoUrl}
                      style={[styles.mapImage, styles.lowerZIndex]}
                    />
                  </View>
                )}
                <Text style={[styles.defectImageDescription, styles.mt5]}>
                  Figure: {inspectionDefect.orderNumber}
                </Text>
                {!!description &&
                  (description.includes('<p>') ? (
                    <Text style={[styles.mt5]}>
                      <Html
                        stylesheet={{
                          p: styles.defectImageDescription,
                        }}
                      >{`<html><body>${Utils.String.addLineBreaksBetweenParagraphs(
                        description,
                      )}</body></html>`}</Html>
                    </Text>
                  ) : (
                    <Text style={[styles.mt5, styles.caption3]}>
                      {description}
                    </Text>
                  ))}
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

export default MediaContent
