import React, { ComponentRef, useEffect, useMemo, useRef } from 'react'
import { Text, Transformer } from 'react-konva'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { NoteConfig } from 'Types/konva'

type Props = {
  config?: NoteConfig
  value?: string
  isSelected?: boolean
  onChange?: (config: NoteConfig) => void
  onClick?: () => void
  onDoubleClick?: () => void
}

function TextInput({
  config,
  value,
  isSelected,
  onClick,
  onDoubleClick,
  onChange,
}: Props) {
  const theme = useTheme()
  const textRef = useRef<ComponentRef<typeof Text> | null>(null)
  const transformerRef = useRef<ComponentRef<typeof Transformer> | null>(null)

  const { color, fontSize } = useMemo(
    () => ({
      color: {
        dangerIcon: themeGet('colors.danger500')({ theme }),
      },
      fontSize: {
        small: themeGet('space.5')({ theme }),
      },
    }),
    [theme],
  )

  useEffect(() => {
    if (!isSelected || !transformerRef.current || !textRef.current) {
      return
    }

    // NOTE: need to attach transformer manually
    transformerRef.current?.nodes([textRef.current])
    transformerRef.current?.getLayer()?.batchDraw()
  }, [isSelected])

  return (
    <>
      <Text
        {...config}
        draggable
        fill={color.dangerIcon}
        fontSize={fontSize.small}
        perfectDrawEnabled={false}
        ref={textRef}
        text={value}
        onClick={onClick}
        onDblClick={onDoubleClick}
        onDblTap={onDoubleClick}
        onDragEnd={event => {
          onChange?.({
            ...config,
            x: event.target.x(),
            y: event.target.y(),
          })
        }}
        onTap={onClick}
      />
      {isSelected && (
        <Transformer
          ref={transformerRef}
          resizeEnabled={false}
          rotateEnabled={false}
        />
      )}
    </>
  )
}

export default TextInput
