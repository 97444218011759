import React, { ComponentProps, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import EditIcon from '@mui/icons-material/Edit'
import { Checkbox } from '@mui/material'
import { DateTime } from 'luxon'
import Utils from 'Utils'
import validate from 'validate.js'

import { Image, InspectionDefectDropdown, Player } from 'Components/Blocks'
import { Card, Column, Dot, Loader, Row, Text } from 'Components/UI'
import { TextEditorField } from 'Components/UI/Forms'

import { TIME_12HR_FORMAT } from 'Constants/date'

import {
  AudioRecordTranscriptionStatus,
  InspectionDefectFragment,
} from 'GraphQL/Main/TypedDocuments'

import {
  AudioTranscriptionStatus,
  EditIconContainer,
  InspectionDefectTitleContainer,
  StyledForm,
} from './styles'

enum Field {
  Comment = 'comment',
}

type FormValues = {
  [Field.Comment]: string
}

export const CONSTRAINTS = {
  [Field.Comment]: {
    length: {
      maximum: 400,
    },
    format: {
      pattern: /^(?:[^\n]*\n){0,5}[^\n]*$/,
      message: 'Maximum 16 lines are allowed',
    },
  },
}

type Props = {
  audioUrl?: string
  audioTranscription?: string | null
  audioTranscriptionStatus?: AudioRecordTranscriptionStatus | null
  defect: InspectionDefectFragment
  checked?: boolean
  isUpdating?: boolean
  hideComments?: boolean
  hideRemoveDrawings?: boolean
  hideDownloadImages?: boolean
  onToggleSelection?: (checked: boolean) => void
  onChangeComment?: (comment: string) => void
  onClickDefect?: () => void
  onDelete?: () => void
  onChangeImage?: ComponentProps<
    typeof InspectionDefectDropdown
  >['onChangeImage']
  onShowActivity?: () => void
  onRemoveDrawings?: () => void
  onDownloadImage?: ComponentProps<
    typeof InspectionDefectDropdown
  >['onDownloadImage']
}

function InspectionDefectItem({
  audioUrl,
  audioTranscription,
  audioTranscriptionStatus,
  defect,
  checked,
  isUpdating,
  hideComments,
  hideRemoveDrawings,
  hideDownloadImages,
  onToggleSelection,
  onChangeComment,
  onClickDefect,
  onDelete,
  onChangeImage,
  onShowActivity,
  onRemoveDrawings,
  onDownloadImage,
}: Props) {
  const theme = useTheme()

  const initialValues = useMemo(() => {
    return {
      [Field.Comment]: defect.description || audioTranscription || '',
    }
  }, [audioTranscription, defect.description])

  const createdDateText = useMemo(
    () =>
      `Created: ${DateTime.fromISO(defect.createdAt).toLocaleString(
        TIME_12HR_FORMAT,
      )}`,
    [defect],
  )

  const color = useMemo(() => {
    const {
      color: audioTranscriptionStatusColor,
      background: audioTranscriptionStatusBackground,
    } = Utils.InspectionDefect.getAudioTranscriptionStatusColors(
      audioTranscriptionStatus,
    )
    return {
      editIcon: themeGet('colors.neutral900')({ theme }),
      audioTranscriptionStatusColor,
      audioTranscriptionStatusBackground,
    }
  }, [audioTranscriptionStatus, theme])

  const audioTranscriptionStatusText = useMemo(
    () =>
      Utils.InspectionDefect.getAudioTranscriptionStatusText(
        audioTranscriptionStatus,
      ),
    [audioTranscriptionStatus],
  )

  const photoUrl = defect.editedPhotoUrl || defect.originalPhotoUrl

  const handleSubmitForm = useCallback(
    (values: FormValues) => {
      const trimmedComment = values[Field.Comment]?.trim() || ''

      if (
        (!trimmedComment && !defect.description) ||
        defect.description === trimmedComment
      ) {
        return
      }

      onChangeComment?.(trimmedComment)
    },
    [defect, onChangeComment],
  )

  const renderForm = useCallback(
    ({ handleSubmit }) => (
      <StyledForm>
        <TextEditorField
          disabled={isUpdating}
          displayError
          name={Field.Comment}
          onBlur={handleSubmit}
        />
      </StyledForm>
    ),
    [isUpdating],
  )

  const formValidation = useCallback((values: FormValues) => {
    let errors = validate(values, CONSTRAINTS)

    const lineCount =
      ((values[Field.Comment] && values[Field.Comment].match(/<\/p>/g)) || [])
        .length + 1

    if (lineCount > 7) {
      errors = {
        ...errors,
        [Field.Comment]: [
          ...(errors?.[Field.Comment] || []),
          'Maximum 6 rows are allowed',
        ],
      }
    }

    return errors
  }, [])

  return (
    <Column center justifyCenter>
      <Card flex={1} padding={3} width="376px">
        <Column height="100%">
          <Row center mb={3} spaceBetween>
            <Checkbox
              checked={checked}
              onChange={(_, checkedValue) => onToggleSelection?.(checkedValue)}
            />

            <InspectionDefectTitleContainer
              onClick={() => onToggleSelection?.(!checked)}
            >
              <Row center gap={5}>
                <Row center gap={3}>
                  <Text body3 ellipsis heading maxWidth="120px">
                    Figure: {defect.orderNumber}
                  </Text>
                  <Text ellipsis heading maxWidth="100px" muted>
                    ({defect.id.slice(0, 8)})
                  </Text>
                </Row>
                {isUpdating && <Loader size={25} />}
              </Row>
            </InspectionDefectTitleContainer>

            <InspectionDefectDropdown
              disabled={isUpdating}
              disabledRemoveDrawings={!defect.editedPhotoUrl}
              hideDownloadImages={hideDownloadImages}
              hideRemoveDrawings={hideRemoveDrawings}
              onChangeImage={onChangeImage}
              onDelete={onDelete}
              onDownloadImage={onDownloadImage}
              onRemoveDrawings={onRemoveDrawings}
              onShowActivity={onShowActivity}
            />
          </Row>

          {!!photoUrl && (
            <Row height={227} justifyCenter mb={4} relative>
              <Image isClickable url={photoUrl} onClick={onClickDefect} />

              <EditIconContainer bottom={0} m={4} position="absolute" right={0}>
                <EditIcon style={{ color: color.editIcon }} />
              </EditIconContainer>
            </Row>
          )}
          <Row center mb={4}>
            {!hideComments && (
              <AudioTranscriptionStatus
                backgroundColor={color.audioTranscriptionStatusBackground}
                color={color.audioTranscriptionStatusColor}
              >
                <Dot color={color.audioTranscriptionStatusColor} size={10} />

                {!!audioTranscriptionStatusText && (
                  <Text body4 ml={3}>
                    {audioTranscriptionStatusText}
                  </Text>
                )}
              </AudioTranscriptionStatus>
            )}

            <Text body4 ml="auto" muted>
              {createdDateText}
            </Text>
          </Row>

          {!!audioUrl && (
            <Row mb={4}>
              <Player
                startAtSecond={defect.defectAudioRelations?.[0].startAtSecond}
                url={audioUrl}
              />
            </Row>
          )}
          {!hideComments && (
            <Row mt="auto">
              <Form
                initialValues={initialValues}
                render={renderForm}
                validate={formValidation}
                onSubmit={handleSubmitForm}
              />
            </Row>
          )}
        </Column>
      </Card>
    </Column>
  )
}

export default InspectionDefectItem
