import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import Utils from 'Utils'
import validate from 'validate.js'

import { LogoSvg } from 'Assets/Svg'

import { Button, Column, InputField, Link, Loader, Text } from 'Components/UI'

import { FORGOT_PASSWORD, ROOT } from 'Constants/paths'

import { SignInByEmailDocument } from 'GraphQL/Main/TypedDocuments'

import { Card, StyledForm } from 'Containers/Pages/Auth/styles'

import Auth from 'Services/Auth'
import toast from 'Services/Toast'

enum Field {
  Email = 'email',
  Password = 'password',
}

type FormValues = {
  [Field.Email]: string
  [Field.Password]: string
}

export const CONSTRAINTS = {
  [Field.Email]: {
    presence: true,
    email: {
      message: 'should be correct',
    },
  },
  [Field.Password]: {
    presence: true,
    length: {
      minimum: 9,
      maximum: 100,
      message: 'must be at least 9 characters',
    },
  },
}

function Login() {
  const history = useHistory()
  const [signInByEmailMutation] = useMutation(SignInByEmailDocument)

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        const result = await signInByEmailMutation({
          variables: { ...values, withRefresh: true },
        })

        await Auth.signIn({
          accessToken: result.data?.signInByEmail.accessToken,
        })
        history.push(ROOT)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [history, signInByEmailMutation],
  )

  const formValidation = useCallback(
    (values: FormValues) => validate(values, CONSTRAINTS),
    [],
  )

  const renderForm = useCallback(
    ({ handleSubmit, submitting }) => (
      <StyledForm onSubmit={handleSubmit}>
        <InputField
          displayError
          label="Email"
          mb={4}
          name={Field.Email}
          type="text"
        />
        <InputField
          displayError
          label="Password"
          mb="60px"
          name={Field.Password}
          type="password"
        />

        <Button
          disabled={submitting}
          type="submit"
          width={1}
          onClick={handleSubmit}
        >
          LOGIN
          {submitting && <Loader inverse ml={4} />}
        </Button>

        {!submitting && (
          <Button
            component={Link}
            mt={3}
            secondary
            to={FORGOT_PASSWORD}
            width={1}
          >
            FORGOT PASSWORD?
          </Button>
        )}
      </StyledForm>
    ),
    [],
  )

  return (
    <Column center fullHeight justifyCenter>
      <Card p={'60px'}>
        <Column center width={1}>
          <LogoSvg />
          <Text header4 heading mb={9} mt="60px">
            Welcome!
          </Text>

          <Form
            render={renderForm}
            validate={formValidation}
            onSubmit={submit}
          />
        </Column>
      </Card>
    </Column>
  )
}

export default Login
