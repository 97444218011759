import React, { useCallback, useMemo, useState } from 'react'
import { ItemProps, TableVirtuoso } from 'react-virtuoso'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FolderIcon from '@mui/icons-material/Folder'
import { DateTime } from 'luxon'
import Utils from 'Utils'

import isNil from 'lodash/isNil'

import {
  Button,
  Card,
  Column,
  Dot,
  IconButton,
  Loader,
  Row,
  Text,
} from 'Components/UI'
import Sort from 'Components/UI/Sort'

import { ServiceAppointmentColumn } from 'Constants/serviceAppointments'

import {
  ServiceAppointmentFragment,
  SortInput,
  SortInputOrder,
} from 'GraphQL/Main/TypedDocuments'

import {
  FooterCell,
  HeaderCell,
  HeaderRow,
  InspectionNameContainer,
  Table,
  TableCell,
  TableFoot,
  TableHead,
  TableRow,
} from './styles'

type Props = {
  data: ServiceAppointmentFragment[]
  loading?: boolean
  count?: number
  page?: number
  dateRange?: { from: string; to: string }
  sort?: SortInput
  onChangePage?: (page: number) => void
  onChangeDate?: (dateRange: { from: DateTime; to: DateTime }) => void
  onClickRow?: (inspectionId: string) => void
  onServiceAppointmentClick?: (serviceAppointmentId: string) => void
  onChangeSort?: (sort: SortInput) => void
}

function ServiceAppointmentCalendar({
  data,
  loading,
  count,
  page,
  dateRange,
  sort,
  onChangePage,
  onChangeDate,
  onClickRow,
  onChangeSort,
  onServiceAppointmentClick,
}: Props) {
  const theme = useTheme()

  const [collapsedRows, setCollapsedRows] = useState(new Set<string>())

  const color = useMemo(
    () => ({
      folderIcon: themeGet('colors.primary500')({ theme }),
    }),
    [theme],
  )

  const scheduledServiceAppointments = useMemo(() => {
    const scheduledDates = new Set<string>()
    const serviceAppointments = new Set<string>()

    data.forEach(serviceAppointment => {
      const scheduledStartAt = serviceAppointment.scheduledStartAt
        ? DateTime.fromISO(serviceAppointment.scheduledStartAt).toLocaleString()
        : null

      if (scheduledStartAt && !scheduledDates.has(scheduledStartAt)) {
        scheduledDates.add(scheduledStartAt)
        serviceAppointments.add(serviceAppointment.id)
      }
    })

    return serviceAppointments
  }, [data])

  const { todayChecked, currentWeekChecked } = useMemo(() => {
    if (!dateRange?.from || !dateRange?.to)
      return {
        todayChecked: false,
        currentWeekChecked: false,
      }

    const now = DateTime.now()
    const today = now.startOf('day')
    const endOfToday = now.endOf('day')
    const startOfCurrentWeek = today.startOf('week')
    const endOfCurrentWeek = today.endOf('week')

    const fromIso = DateTime.fromISO(dateRange.from)
    const toIso = DateTime.fromISO(dateRange.to)

    return {
      todayChecked: fromIso >= today && toIso <= endOfToday,
      currentWeekChecked:
        fromIso >= startOfCurrentWeek && toIso <= endOfCurrentWeek,
    }
  }, [dateRange])

  const handleEndReached = useCallback(() => {
    if (!data || (count && data.length >= count) || loading || isNil(page)) {
      return
    }

    onChangePage?.(page + 1)
  }, [data, count, loading, page, onChangePage])

  const handleToday = useCallback(() => {
    const now = DateTime.now()
    const from = now.startOf('day')
    const to = now.endOf('day')

    onChangeDate?.({ from, to })
  }, [onChangeDate])

  const handleCurrentWeek = useCallback(() => {
    const now = DateTime.now()
    const from = now.startOf('week')
    const to = now.endOf('week')

    onChangeDate?.({ from, to })
  }, [onChangeDate])

  const handleChangeSort = useCallback(
    (order: SortInputOrder | null, column: ServiceAppointmentColumn) => {
      if (!order) {
        onChangeSort?.({
          column: ServiceAppointmentColumn.ScheduledStartAt,
          order: SortInputOrder.Asc,
        })
        return
      }

      onChangeSort?.({ order, column })
    },
    [onChangeSort],
  )

  const handleToggleCollapse = useCallback((id: string) => {
    setCollapsedRows(prevCollapsedRows => {
      const updatedRows = new Set(prevCollapsedRows)
      if (updatedRows.has(id)) {
        updatedRows.delete(id)
      } else {
        updatedRows.add(id)
      }
      return updatedRows
    })
  }, [])

  const renderHeaderContent = useCallback(() => {
    return (
      <HeaderRow>
        <HeaderCell>Date</HeaderCell>
        <HeaderCell />
        <HeaderCell>Name</HeaderCell>
        <HeaderCell>Opportunity Name</HeaderCell>
        <HeaderCell>
          <Sort
            active={sort?.column === ServiceAppointmentColumn.TotalDefectCount}
            sortOrder={sort?.order}
            onChangeSort={order =>
              handleChangeSort(order, ServiceAppointmentColumn.TotalDefectCount)
            }
          >
            Figures count
          </Sort>
        </HeaderCell>
      </HeaderRow>
    )
  }, [sort, handleChangeSort])

  const renderItemContent = useCallback(
    (_: number, row: ServiceAppointmentFragment) => {
      const scheduledAbbreviatedDay = row.scheduledStartAt
        ? DateTime.fromISO(row.scheduledStartAt).toFormat('ccc')
        : null
      const scheduledDayOfMonth = row.scheduledStartAt
        ? DateTime.fromISO(row.scheduledStartAt).day.toString()
        : null
      const subject = row.subject || 'N/A'

      return (
        <>
          <TableCell width="85px">
            {scheduledAbbreviatedDay &&
            ((scheduledServiceAppointments.has(row.id) &&
              scheduledDayOfMonth) ||
              sort?.column !== ServiceAppointmentColumn.ScheduledStartAt) ? (
              <Column center width="min-content">
                <Text body3 muted>
                  {scheduledAbbreviatedDay.toUpperCase()}
                </Text>
                <Text fontSize="36px" fontWeight={1} muted>
                  {scheduledDayOfMonth}
                </Text>
              </Column>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell width="40px">
            <IconButton
              disabled={!row.inspections?.length}
              onClick={() => handleToggleCollapse(row.id)}
            >
              {!collapsedRows.has(row.id) ? (
                <ExpandMoreIcon />
              ) : (
                <ExpandLessIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell
            width="250px"
            onClick={() => onServiceAppointmentClick?.(row.id)}
          >
            <InspectionNameContainer gap={4}>
              <Row>
                <FolderIcon
                  color="primary"
                  style={{ fontSize: 30, zIndex: 1, color: color.folderIcon }}
                />
              </Row>
              <Text header5 preWrap>
                {row.appointmentNumber || 'N/A'}
              </Text>
            </InspectionNameContainer>
          </TableCell>
          <TableCell width="350px">
            <Text body3 preWrap>
              {subject}
            </Text>
          </TableCell>
          <TableCell width="100px">
            <Text body3>{row.totalInspectionDefectCount}</Text>
          </TableCell>
        </>
      )
    },
    [
      collapsedRows,
      color.folderIcon,
      handleToggleCollapse,
      onServiceAppointmentClick,
      scheduledServiceAppointments,
      sort,
    ],
  )

  const renderFooter = useCallback(
    () => (
      <TableRow>
        <FooterCell />
        <FooterCell />
        <FooterCell>
          {loading && (
            <Row center gap={2} justifyCenter p={2}>
              <Loader size={30} />

              <Text caption2 center muted>
                LOADING...
              </Text>
            </Row>
          )}
        </FooterCell>
        <FooterCell />
        <FooterCell />
      </TableRow>
    ),
    [loading],
  )

  const renderRow = useCallback(
    (props: ItemProps<ServiceAppointmentFragment>) => {
      return (
        <>
          <TableRow {...props} />
          {collapsedRows.has(props.item.id) && (
            <TableRow>
              <TableCell colSpan={6} pl={0}>
                <Table expanded>
                  <TableHead>
                    <HeaderRow>
                      <HeaderCell />
                      <HeaderCell />
                      <HeaderCell>Name</HeaderCell>
                      <HeaderCell>Inspector</HeaderCell>
                      <HeaderCell>Job Reference</HeaderCell>
                      <HeaderCell>Report Writer</HeaderCell>
                      <HeaderCell>Figures count</HeaderCell>
                      <HeaderCell>Status</HeaderCell>
                    </HeaderRow>
                  </TableHead>

                  {props.item.inspections?.map(inspection => (
                    <TableRow
                      key={inspection.id}
                      onClick={() => onClickRow?.(inspection.id)}
                    >
                      <TableCell width="85px" />
                      <TableCell width="40px" />
                      <TableCell width="150px">
                        {inspection.name || 'N/A'}
                      </TableCell>
                      <TableCell width="130px">
                        <Text ellipsis>
                          {Utils.User.getUserIdentifier(
                            inspection.inspectorUser,
                          )}
                        </Text>
                      </TableCell>
                      <TableCell width="80px">
                        {inspection.internalReference || 'N/A'}
                      </TableCell>
                      <TableCell width="130px">
                        <Text ellipsis>{inspection.reportWriter || 'N/A'}</Text>
                      </TableCell>
                      <TableCell width="30px">
                        {inspection.inspectionDefectCount}
                      </TableCell>
                      <TableCell width="100px">
                        {inspection.status ? (
                          <Row center gap={3} overflow="hidden">
                            <Dot
                              color={Utils.Inspection.getStatusColor(
                                inspection.status,
                              )}
                              size={9}
                            />
                            <Text ellipsis>
                              {Utils.Inspection.getStatusText(
                                inspection.status,
                              )}
                            </Text>
                          </Row>
                        ) : (
                          <Row>&mdash;</Row>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableCell>
            </TableRow>
          )}
        </>
      )
    },
    [collapsedRows, onClickRow],
  )

  if (data.length === 0 && loading) {
    return (
      <Column center fullHeight justifyCenter>
        <Loader size={70} />
      </Column>
    )
  }

  if (data.length === 0 && !loading) {
    return (
      <Column center fullHeight justifyCenter>
        <Text center header5 heading>
          No data yet
        </Text>
      </Column>
    )
  }

  return (
    <Card height="100%" p={4}>
      <Column fullHeight>
        <Row gap={3}>
          <Button secondary={!todayChecked} small onClick={handleToday}>
            TODAY
          </Button>

          <Button
            secondary={!(currentWeekChecked && !todayChecked)}
            small
            onClick={handleCurrentWeek}
          >
            THIS WEEK
          </Button>
        </Row>

        <Column fullHeight>
          <TableVirtuoso
            components={{
              Table,
              TableHead,
              TableFoot,
              TableRow: renderRow,
            }}
            data={data}
            endReached={handleEndReached}
            fixedFooterContent={renderFooter}
            fixedHeaderContent={renderHeaderContent}
            itemContent={renderItemContent}
            style={{ height: '100%' }}
          />
        </Column>
      </Column>
    </Card>
  )
}

export default ServiceAppointmentCalendar
