import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DateRange } from 'react-day-picker'

import DateRangeIcon from '@mui/icons-material/DateRange'
import { Fade, Popper } from '@mui/material'
import { DateTime } from 'luxon'

import { Button } from 'Components/UI/Buttons'
import Card from 'Components/UI/Card'
import { DateRangePicker } from 'Components/UI/DatePicker'
import { Column } from 'Components/UI/Flex'

import { useOnClickOutside } from 'Hooks'

import { toRange } from './utils'

type Props = Omit<
  ComponentProps<typeof DateRangePicker>,
  'value' | 'onChange'
> & {
  title?: string
  disabled?: boolean
  secondary?: boolean
  from?: string
  to?: string
  onChange?: (dateRange?: { from: DateTime; to: DateTime }) => void
}

function DateRangeInput({
  title = 'CALENDAR',
  disabled,
  secondary,
  from,
  to,
  onChange,
  ...rest
}: Props) {
  const [popperOpen, setPopperOpen] = useState(false)
  const [range, setRange] = useState<DateRange | undefined>(toRange(from, to))

  useEffect(() => {
    setRange(toRange(from, to))
  }, [from, to])

  const buttonRef = useRef(null)
  const popperRef = useRef(null)

  const handleOpenerClose = useCallback(() => {
    setPopperOpen(false)
  }, [])

  const handleOpenerOpen = useCallback(() => {
    setPopperOpen(true)
  }, [])

  const handleFinish = useCallback(
    (pickedDates?: DateRange) => {
      setRange(pickedDates)

      if (!pickedDates?.from || !pickedDates.to) {
        onChange?.(undefined)
      } else {
        onChange?.({
          from: DateTime.fromJSDate(pickedDates.from),
          to: DateTime.fromJSDate(pickedDates.to),
        })
        handleOpenerClose()
      }
    },
    [onChange, handleOpenerClose],
  )

  const formattedValue = useMemo(() => {
    if (!range || !range.from || !range.to) {
      return title
    }

    return `${DateTime.fromJSDate(
      range.from,
    ).toLocaleString()} — ${DateTime.fromJSDate(range.to).toLocaleString()}`
  }, [range, title])

  useOnClickOutside(popperRef, handleOpenerClose)

  return (
    <Column>
      <Button
        disabled={disabled}
        gap={3}
        ref={buttonRef}
        secondary={secondary}
        small
        onClick={handleOpenerOpen}
      >
        <DateRangeIcon />
        {formattedValue}
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        open={popperOpen}
        ref={popperRef}
        style={{
          zIndex: 1000,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Card>
              <DateRangePicker
                {...rest}
                value={range}
                onChange={handleFinish}
              />
            </Card>
          </Fade>
        )}
      </Popper>
    </Column>
  )
}

export default DateRangeInput
