import styled from 'styled-components'

export const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .quill {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;

    .ql-container {
      &.ql-snow {
        min-height: 0;
      }
    }
  }
`
