import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'
import { MarginProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { ArrowRightSvg } from 'Assets/Svg'

import { Button, Dot, IconButton, Row } from 'Components/UI'

import { GapProps } from 'Services/Styles'

import { ArrowColors } from './types'

type Props = MarginProps &
  GapProps & {
    selectedColor?: ArrowColors
    disabled?: boolean
    onSelectColor?: (color: ArrowColors) => void
    onAddShape?: () => void
  }

function ColorPicker({
  selectedColor,
  disabled,
  onSelectColor,
  onAddShape,
  ...rest
}: Props) {
  const theme = useTheme()
  const color = useMemo(
    () => ({
      shapeIcon: themeGet(`colors.${selectedColor}`)({ theme }),
    }),
    [selectedColor, theme],
  )

  return (
    <Row {...rest} center>
      <Row>
        <IconButton
          active={selectedColor === ArrowColors.Red}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Red)}
        >
          <Dot color={ArrowColors.Red} size={30} />
        </IconButton>

        <IconButton
          active={selectedColor === ArrowColors.Blue}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Blue)}
        >
          <Dot color={ArrowColors.Blue} size={30} />
        </IconButton>

        <IconButton
          active={selectedColor === ArrowColors.Yellow}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Yellow)}
        >
          <Dot color={ArrowColors.Yellow} size={30} />
        </IconButton>

        <IconButton
          active={selectedColor === ArrowColors.Black}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Black)}
        >
          <Dot color={ArrowColors.Black} size={30} />
        </IconButton>

        <IconButton
          active={selectedColor === ArrowColors.Purple}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Purple)}
        >
          <Dot color={ArrowColors.Purple} size={30} />
        </IconButton>

        <IconButton
          active={selectedColor === ArrowColors.Gray}
          disabled={disabled}
          onClick={() => onSelectColor?.(ArrowColors.Gray)}
        >
          <Dot color={ArrowColors.Gray} size={30} />
        </IconButton>
      </Row>

      <Button disabled={disabled} secondary small onClick={onAddShape}>
        <ArrowRightSvg fill={color.shapeIcon} />
      </Button>
    </Row>
  )
}

export default ColorPicker
