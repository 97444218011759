import {
  DayPicker,
  DayPickerProps,
  DayPickerRangeProps,
} from 'react-day-picker'

import styled, { css } from 'styled-components'
import { padding, PaddingProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { createGetParam } from 'Theme'

const sizes = {
  size: {
    large: 48,
    default: 40,
    small: 32,
  },
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  headFontSize: {
    large: themeGet('fontSizes.1'),
    default: themeGet('fontSizes.0'),
    small: 10,
  },
}

export interface SizeProps {
  large?: boolean
  small?: boolean
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

const pickerCss = css`
  --rdp-cell-size: ${getSizeParam('size')}px;
  --rdp-accent-color: ${themeGet('colors.background.subblock')};
  --rdp-background-color: ${themeGet('colors.primary500')};
  --rdp-accent-color-dark: #000;
  --rdp-background-color-dark: #000;
  --rdp-outline: none;
  --rdp-outline-selected: none;

  --caption-color: ${themeGet('colors.text.heading')};
  --caption-font-size: ${getSizeParam('fontSize')}px;
  --day-color: ${themeGet('colors.primary')};
  --day-disabled-color: ${themeGet('colors.text.muted')};
  --day-font-size: ${getSizeParam('fontSize')}px;
  --day-range-radius: 0;
  --head-cell-color: ${themeGet('colors.text.heading')};
  --head-cell-font-size: ${getSizeParam('headFontSize')}px;
  --nav-color: ${themeGet('colors.text.heading')};
  --nav-hover-color: ${themeGet('colors.text.heading')};

  background: ${themeGet('colors.background.block')};
  border: 0;
  border-radius: 6px;
  padding: 12px;

  .rdp {
    margin: 0;
  }

  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    box-sizing: border-box;
    margin: 0;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    outline: none;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  .rdp-button {
    position: relative;
    border: 2px solid transparent;
    border-radius: 6px;
    justify-content: center;
    display: flex;
  }

  .rdp-button[aria-disabled='true'] {
    color: var(--day-disabled-color);
    pointer-events: none;
  }

  .rdp-button:not([aria-disabled='true']) {
    cursor: pointer;
  }

  .rdp-button:focus,
  .rdp-button:active {
    color: ${themeGet('colors.white')};
    border: var(--rdp-outline);
    background-color: var(--rdp-background-color);
  }

  .rdp-button:hover:not([aria-disabled='true']) {
    background-color: var(--rdp-background-color);
    color: ${themeGet('colors.white')};
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }

  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    display: flex;
    height: 38px;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
    position: relative;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
    width: 100%;
  }

  .rdp-caption_label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    border: 0;
    font-size: var(--caption-font-size);
    color: var(--caption-color);
    font-weight: ${themeGet('fontWeights.2')};
  }

  .rdp-nav {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;

    > button {
      width: 20px;
      padding: 0;
      margin: 0;

      > svg {
        width: 12px;
        > path {
          fill: var(--nav-color);
        }
      }

      :hover,
      :active,
      :focus {
        background: none !important;
        > svg {
          > path {
            fill: var(--nav-hover-color);
          }
        }
      }
    }
  }

  &.rdp-multiple_months .rdp-caption_start .rdp-nav {
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &.rdp-multiple_months .rdp-caption_end .rdp-nav {
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
  }

  .rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
  }

  .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
  .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    border: var(--rdp-outline);
    border-radius: 6px;
    background-color: var(--rdp-background-color);
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    vertical-align: middle;
    text-align: center;
    height: var(--rdp-cell-size);
    padding: 0;
    color: var(--head-cell-color);
    font-weight: ${themeGet('fontWeights.0')};
    font-size: var(--head-cell-font-size);
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
  }

  .rdp-weeknumber {
    font-size: 0.75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-weight: ${themeGet('fontWeights.2')};
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: 6px;
    font-size: var(--day-font-size);
    color: var(--day-color);
  }

  .rdp-day_outside {
    color: ${themeGet('colors.text.muted')};
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
    &:after {
      content: '•';
      height: 1px;
      color: ${themeGet('colors.primary')};
      position: absolute;
      bottom: 40%;
    }
  }

  .rdp-day_today:not(.rdp-day_outside) {
    :hover {
      &:after {
        color: ${themeGet('colors.white')};
      }
    }
  }

  .rdp-day_range_end {
    color: ${themeGet('colors.white')} !important;
    background-color: var(--rdp-background-color) !important;
    border-bottom-left-radius: var(--day-range-radius) !important;
    border-top-left-radius: var(--day-range-radius) !important;
  }

  .rdp-day_range_start {
    color: ${themeGet('colors.white')} !important;
    background-color: var(--rdp-background-color) !important;
    border-bottom-right-radius: var(--day-range-radius) !important;
    border-top-right-radius: var(--day-range-radius) !important;

    &:after {
      color: ${themeGet('colors.white')} !important;
    }
  }

  .rdp-day_range_middle {
    border-radius: 0;
  }

  .rdp-day_single_selected {
    color: ${themeGet('colors.white')} !important;
    background-color: var(--rdp-background-color) !important;

    :after {
      color: ${themeGet('colors.white')} !important;
    }
  }

  .rdp-day_selected,
  .rdp-day_selected:active,
  .rdp-day_selected:hover {
    background-color: var(--rdp-accent-color);
    color: var(--day-color);

    :after {
      color: ${themeGet('colors.primary')};
    }
  }
`

type StyledPickerProps = DayPickerProps & SizeProps

export const StyledPicker = styled(DayPicker)<StyledPickerProps>`
  ${pickerCss};
`

type StyleRangePickerProps = DayPickerRangeProps & SizeProps & PaddingProps

export const StyleRangePicker = styled(DayPicker)<StyleRangePickerProps>`
  ${pickerCss};

  ${padding};
`

export const Button = styled.div`
  svg {
    path {
      stroke: ${themeGet('colors.blueSteel')};
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${themeGet('colors.background.block')};
  border-radius: 6px;
`
