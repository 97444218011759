import Inspection from './Inspection'
import InspectionDefectEditor from './InspectionDefectEditor'
import ServiceAppointment from './ServiceAppointment'
import ServiceAppointmentDefectEditor from './ServiceAppointmentDefectEditor'
import ServiceAppointments from './ServiceAppointments'

export default {
  ServiceAppointments,
  ServiceAppointment,
  Inspection,
  InspectionDefectEditor,
  ServiceAppointmentDefectEditor,
}
