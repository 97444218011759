import styled, { css } from 'styled-components'
import { padding, PaddingProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { gap, GapProps } from 'Services/Styles'

type TableCellProps = {
  width?: string
}

type TableExpandedProps = {
  expanded?: boolean
}

const tableCellWidthCss = ({ width }: TableCellProps) =>
  width &&
  css`
    width: ${width};
    max-width: ${width};
    min-width: ${width};
  `
const tableExpandedCss = ({ expanded }: TableExpandedProps) =>
  expanded &&
  css`
    background-color: ${themeGet('colors.neutral800')};
    tr {
      &:hover {
        background-color: ${themeGet('colors.neutral700')};
      }
    }

    thead tr {
      background-color: ${themeGet('colors.neutral800')};
      &:hover {
        background-color: ${themeGet('colors.neutral800')};
      }
    }
  `

export const Table = styled.table<TableExpandedProps>`
  width: 100%;
  border-collapse: collapse;

  ${tableExpandedCss}
`

export const HeaderRow = styled.tr`
  background-color: ${themeGet('colors.background.block')};
`

export const TableRow = styled.tr`
  cursor: pointer;
  transition: all ease ${themeGet('defaultTransitionTime')};

  &:hover {
    background-color: ${themeGet('colors.neutral800')};
  }
`

export const TableHead = styled.thead`
  position: relative;
  text-align: left;
  height: 56px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${themeGet('colors.neutral700')};
  }
`

export const HeaderCell = styled.th`
  font-size: ${themeGet('fontSizes.1')}px;
  font-weight: ${themeGet('fontWeights.1')};
  line-height: 143%;
  color: ${themeGet('colors.text.muted')};
  padding-left: ${themeGet('space.3')}px;
  white-space: nowrap;
`

export const TableCell = styled.td<PaddingProps & TableCellProps>`
  height: 97px;
  padding-left: ${themeGet('space.3')}px;

  ${padding}
  ${tableCellWidthCss}
`

export const InspectionNameContainer = styled.div<GapProps>`
  position: relative;
  display: flex;
  height: 73px;
  background-color: ${themeGet('colors.primary500')};
  border-radius: 8px;
  padding: ${themeGet('space.3')}px;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 3px);
    height: 100%;
    top: 0;
    left: 3px;
    opacity: 0.9;
    background-color: ${themeGet('colors.white')};
    border-radius: 8px;
  }

  ${gap};
`

export const TableFoot = styled.tfoot`
  position: relative;
`

export const FooterCell = styled.td`
  height: 10px;
`
