import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import styles from '../styles'

function DefectAssessment() {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      {/* DEFECT ASSESSMENT */}
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.header5}>DEFECT ASSESSMENT</Text>
      </View>
      <Text style={[styles.caption2, styles.mt12]}>
        Any crack/damage identified during the visual inspection of the property
        is measured using a crack gauge and is described for each location as
        per the following table.
      </Text>

      {/* CRACKING/DAMAGE CLASSIFICATIONS */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.mt12,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>CRACKING/DAMAGE CLASSIFICATIONS</Text>
      </View>

      <View style={[styles.table]}>
        <View style={styles.row}>
          <View style={[styles.tableCell, { width: '55%' }]}>
            <Text style={styles.caption2}>Hairline cracks.</Text>
          </View>
          <View style={[styles.tableCell, { width: '25%' }]}>
            <Text style={styles.caption2}>{'<0.1 mm'}</Text>
          </View>
          <View style={[styles.tableCell, { width: '20%' }]}>
            <Text style={styles.caption2}>0 Hairline</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.tableCell, { width: '55%' }]}>
            <Text style={styles.caption2}>
              Fine cracks that do not need repair.
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '25%' }]}>
            <Text style={styles.caption2}>{'<1 mm'}</Text>
          </View>
          <View style={[styles.tableCell, { width: '20%' }]}>
            <Text style={styles.caption2}>1 Fine</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.tableCell, { width: '55%' }]}>
            <Text style={styles.caption2}>
              Cracks noticeable but easily filled.
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '25%' }]}>
            <Text style={styles.caption2}>{'<5 mm'}</Text>
          </View>
          <View style={[styles.tableCell, { width: '20%' }]}>
            <Text style={styles.caption2}>2 Slight</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.tableCell, { width: '55%' }]}>
            <Text style={styles.caption2}>
              Crack can be repaired and possibly a small amount of wall/ paving
              will need to be replaced.
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '25%' }]}>
            <Text style={styles.caption2}>
              {'<5 mm to 15 mm, or several cracks > 3mm'}
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '20%' }]}>
            <Text style={styles.caption2}>3 Moderate</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.tableCell, { width: '55%' }]}>
            <Text style={styles.caption2}>
              Extensive repair work involving breaking out and replacing
              sections of walls/paving. Walls lean or bulge noticeably. Some
              loss of bearing in beams. Realignment work may be required for
              paving.
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '25%' }]}>
            <Text style={styles.caption2}>
              {'<15 mm to 25 mm, depends on number of cracks'}
            </Text>
          </View>
          <View style={[styles.tableCell, { width: '20%' }]}>
            <Text style={styles.caption2}>4 Severe</Text>
          </View>
        </View>
      </View>

      <Text style={[styles.caption3Italic, styles.mt2]}>
        *Referred to in AS.2670: Residential slabs and footings - Construction,
        Table C1
      </Text>

      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.mt12,
          styles.center,
        ]}
      >
        <Text style={styles.caption2}>
          Defects in this report are classified in two categories of Major and
          Minor:
        </Text>
      </View>
    </View>
  )
}

export default DefectAssessment
