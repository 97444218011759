import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Utils from 'Utils'

import {
  AudioUploader,
  FileUploadProgressModal,
  Header,
  ImageUploader,
} from 'Components/Blocks'
import { Button, Column, IconButton, Loader, Row, Text } from 'Components/UI'

import {
  SERVICE_APPOINTMENT_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'
import { IMAGE_LOADING_TOTAL_STEPS } from 'Constants/serviceAppointments'

import {
  FileType,
  InspectionDefectRelationKind,
  ServiceAppointmentDocument,
  SignedFile,
} from 'GraphQL/Main/TypedDocuments'

import {
  useInspectionDefectEntity,
  useInspectionDefectList,
  useSignFile,
} from 'Hooks'

import toast from 'Services/Toast'

const DEFECTS_LIMIT = 0

type SignedFileWithUrls = SignedFile & {
  publicUrl?: string
  signedUrl?: string
}

type ImageFile = SignedFileWithUrls & { originalDate?: string | null }

type ImageState = {
  originalFile: ImageFile | null
  compressedFile: ImageFile | null
} | null

function CreateServiceAppointmentDefect() {
  const history = useHistory()
  const theme = useTheme()
  const { serviceAppointmentId } = useParams<ServiceAppointmentsRouteParams>()

  const {
    progress,
    loading: fileLoading,
    getSignedUrl,
    abortUpload,
  } = useSignFile()
  const { createInspectionDefect } = useInspectionDefectEntity()

  const [modalOpen, setModalOpen] = useState(false)
  const [newImage, setNewImage] = useState<ImageState>(null)
  const [newAudio, setNewAudio] = useState<null | SignedFileWithUrls>(null)
  const [loadingStep, setLoadingStep] = useState(0)

  const color = useMemo(
    () => ({
      chevronIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )

  const { loading: serviceAppointmentLoading, error: serviceAppointmentError } =
    useQuery(ServiceAppointmentDocument, {
      variables: {
        id: serviceAppointmentId!,
      },
      skip: !serviceAppointmentId,
      fetchPolicy: 'network-only',
    })

  const { inspectionDefectsCount, loading: inspectionDefectsLoading } =
    useInspectionDefectList({ serviceAppointmentId, limit: DEFECTS_LIMIT })

  useLayoutEffect(() => {
    if (!serviceAppointmentError) {
      return
    }

    const [graphQLError] = Utils.Errors.getGraphQLErrors(
      serviceAppointmentError,
    )
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [serviceAppointmentError, history])

  const handleCreateInspectionDefect = useCallback(async () => {
    if (!serviceAppointmentId || !newImage || !newAudio) {
      return
    }

    await createInspectionDefect.mutate({
      serviceAppointmentId,
      originalPhotoUrl: newImage?.originalFile?.publicUrl,
      compressedOriginalPhotoUrl: newImage?.compressedFile?.publicUrl,
      relationKind: InspectionDefectRelationKind.ServiceAppointment,
      photoTakenAt: newImage?.originalFile?.originalDate,
    })

    history.push(SERVICE_APPOINTMENT_ROOT(serviceAppointmentId))
  }, [
    newImage,
    newAudio,
    createInspectionDefect,
    history,
    serviceAppointmentId,
  ])

  const handleAddImage = useCallback(
    async (files: File[]) => {
      if (!serviceAppointmentId) return

      setModalOpen(true)
      // TODO: extract multiple files uploading for service appointment photo to a hook
      setLoadingStep(prevState => prevState + 1)

      const file = files[0]

      const compressedFile = await Utils.File.compressFile(file)

      const signedFile = await getSignedUrl({
        file,
        type: FileType.ServiceAppointmentPhoto,
        serviceAppointmentId,
      })

      setLoadingStep(prevState => prevState + 1)

      const signedCompressedFile = await getSignedUrl({
        file: compressedFile,
        type: FileType.ServiceAppointmentPhoto,
        serviceAppointmentId,
      })

      if (
        !signedFile?.data?.fileSign ||
        !signedCompressedFile?.data?.fileSign
      ) {
        return
      }

      const originalDate = await Utils.File.getOriginalDateFromFile(file)

      setNewImage({
        originalFile: { ...signedFile.data.fileSign, originalDate },
        compressedFile: {
          ...signedCompressedFile.data.fileSign,
          originalDate,
        },
      })
      setModalOpen(false)
      setLoadingStep(0)
    },
    [serviceAppointmentId, getSignedUrl],
  )

  const handleAddAudio = useCallback(
    async (files: File[]) => {
      if (!serviceAppointmentId) return

      setModalOpen(true)

      const file = files[0]
      const signedFile = await getSignedUrl({
        file,
        type: FileType.ServiceAppointmentAudio,
        serviceAppointmentId,
      })

      if (!signedFile?.data?.fileSign) {
        return
      }

      setNewAudio(signedFile?.data?.fileSign)
      setModalOpen(false)
    },
    [serviceAppointmentId, getSignedUrl],
  )

  const handleCloseModal = useCallback(() => {
    abortUpload()
    setModalOpen(false)
  }, [abortUpload])

  const handleBack = useCallback(() => {
    history.push(SERVICE_APPOINTMENT_ROOT(serviceAppointmentId))
  }, [serviceAppointmentId, history])

  const loadingProgress = useMemo(() => {
    if (!loadingStep) return progress
    return Utils.ProgressBar.calculateLoadingProgress(
      loadingStep,
      progress,
      IMAGE_LOADING_TOTAL_STEPS,
    )
  }, [loadingStep, progress])

  if (serviceAppointmentLoading || inspectionDefectsLoading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Header>
        <Row center gap={5} minWidth={0}>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon style={{ color: color.chevronIcon }} />
          </IconButton>

          <Row center minWidth={0}>
            <Text header5 inverse>
              Create defect (Figure: {inspectionDefectsCount + 1})
            </Text>
          </Row>
        </Row>
      </Header>

      <Column center mt={8}>
        <Column width="328px">
          <ImageUploader
            uploadDisabled={createInspectionDefect.loading}
            url={newImage?.originalFile?.signedUrl}
            onChange={handleAddImage}
          />
          <AudioUploader
            mt={5}
            uploadDisabled={!newImage || createInspectionDefect.loading}
            url={newAudio?.signedUrl}
            onChange={handleAddAudio}
          />

          <Button
            disabled={
              createInspectionDefect.loading ||
              !newImage ||
              !newAudio ||
              fileLoading
            }
            mt={5}
            onClick={handleCreateInspectionDefect}
          >
            CREATE MEDIA
            {createInspectionDefect.loading && <Loader inverse ml={4} />}
          </Button>
        </Column>
      </Column>

      <FileUploadProgressModal
        isOpen={modalOpen}
        progress={loadingProgress}
        onClose={handleCloseModal}
      />
    </Column>
  )
}

export default CreateServiceAppointmentDefect
