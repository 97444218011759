import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import Utils from 'Utils'

import { CircleDoneSvg, LogoSvg } from 'Assets/Svg'

import { Button, Column, InputField, Link, Loader, Text } from 'Components/UI'

import { LOGIN, ROOT } from 'Constants/paths'

import {
  ChangeEmailPasswordDocument,
  SignInByEmailDocument,
} from 'GraphQL/Main/TypedDocuments'

import { Card, StyledForm } from 'Containers/Pages/Auth/styles'

import Auth from 'Services/Auth'
import { PasswordResetState } from 'Services/Store/passwordResetState'
import toast from 'Services/Toast'

import Constraints from './Constraints'

enum Field {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

type FormValues = {
  [Field.Password]: string
  [Field.ConfirmPassword]: string
}

function getTokenFromUrl() {
  const [, reset] = window.location.search.split('?token=')

  return reset
}

function ResetPassword() {
  const history = useHistory()

  const [hasPasswordReset, setHasPasswordReset] = useState(false)
  const [password, setPassword] = useState('')
  const email = PasswordResetState.getEmail()

  const [changeEmailPasswordDocumentMutation] = useMutation(
    ChangeEmailPasswordDocument,
  )
  const [signInByEmailMutation, { loading }] = useMutation(
    SignInByEmailDocument,
  )

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        const password = values[Field.Password]
        const token = getTokenFromUrl()

        const result = await changeEmailPasswordDocumentMutation({
          variables: { password, token },
        })

        if (!result.data?.changeEmailPassword.ok) {
          return
        }
        setPassword(password)
        setHasPasswordReset(true)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [changeEmailPasswordDocumentMutation],
  )

  const handleSignIn = useCallback(async () => {
    if (!email) {
      return
    }

    try {
      const result = await signInByEmailMutation({
        variables: { email, password, withRefresh: true },
      })

      await Auth.signIn({
        accessToken: result.data?.signInByEmail.accessToken,
      })
      history.push(ROOT)
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [history, email, password, signInByEmailMutation])

  const formValidation = useCallback((values: FormValues) => {
    const password = values[Field.Password]

    if (!password?.length) {
      return {
        [Field.Password]: 'Password required',
      }
    }
    if (!Constraints.Length.check(password)) {
      return {
        [Field.Password]: `Password length must be ${Constraints.Length.MIN_LENGTH}-${Constraints.Length.MAX_LENGTH} characters`,
      }
    }
    if (!Constraints.UpperCaseLetter.check(password)) {
      return {
        [Field.Password]: 'Password must contain one upper case letter',
      }
    }
    if (!Constraints.LowerCaseLetter.check(password)) {
      return {
        [Field.Password]: 'Password must contain one lower case letter',
      }
    }
    if (!Constraints.OneNumber.check(password)) {
      return {
        [Field.Password]: 'Password must contain one number',
      }
    }
    if (!Constraints.SpecialCharacters.check(password)) {
      return {
        [Field.Password]: `Password must contain one special character (${Constraints.SpecialCharacters.SPECIAL_CHARACTERS})`,
      }
    }

    const confirmPassword = values[Field.ConfirmPassword]

    if (!confirmPassword?.length) {
      return {
        [Field.ConfirmPassword]: 'Confirm password required',
      }
    }
    if (password !== confirmPassword) {
      return {
        [Field.ConfirmPassword]: `Passwords don't match`,
      }
    }

    return undefined
  }, [])

  const renderForm = useCallback(
    ({ handleSubmit, submitting }) => (
      <StyledForm onSubmit={handleSubmit}>
        <InputField
          displayError
          label="New Password"
          mb={4}
          name={Field.Password}
          type="password"
        />
        <InputField
          displayError
          label="Confirm Password"
          mb="60px"
          name={Field.ConfirmPassword}
          type="password"
        />

        <Button
          disabled={submitting}
          type="submit"
          variant="contained"
          width={1}
          onClick={handleSubmit}
        >
          SETUP PASSWORD
          {submitting && <Loader inverse ml={4} />}
        </Button>

        {!submitting && (
          <Button component={Link} mt={3} secondary to={LOGIN} width={1}>
            BACK TO LOGIN
          </Button>
        )}
      </StyledForm>
    ),
    [],
  )

  return (
    <Column center fullHeight justifyCenter>
      <Card p={'60px'}>
        <Column center width={1}>
          <LogoSvg />
          {!hasPasswordReset && (
            <Text header4 heading mb={9} mt="60px">
              Setup new password
            </Text>
          )}

          {!hasPasswordReset ? (
            <Form
              render={renderForm}
              validate={formValidation}
              onSubmit={submit}
            />
          ) : (
            <Column center>
              <Column center gap={9} mb="60px" mt="60px">
                <CircleDoneSvg />
                <Text header4 heading>
                  Password reset
                </Text>
                {email ? (
                  <Text body4 center heading>
                    {`Your password has been successfully reset. Click below to login in magically.`}
                  </Text>
                ) : (
                  <Text body4 center heading>
                    {`Your password has been successfully reset. Click below to navigate to the login page.`}
                  </Text>
                )}
              </Column>

              {email && (
                <Button
                  disabled={loading}
                  mb={3}
                  variant="contained"
                  width={1}
                  onClick={handleSignIn}
                >
                  CONTINUE
                  {loading && <Loader inverse ml={4} />}
                </Button>
              )}

              <Button component={Link} secondary to={LOGIN} width={1}>
                BACK TO LOGIN
              </Button>
            </Column>
          )}
        </Column>
      </Card>
    </Column>
  )
}

export default ResetPassword
