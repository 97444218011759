import React, { useCallback, useEffect, useState } from 'react'

import {
  Document as PDFDocument,
  Image,
  Page,
  PDFViewer,
  View,
} from '@react-pdf/renderer'

import { Loader } from 'Components/UI'

import { CORS } from 'Config'

import { FloorPlanFragment } from 'GraphQL/Admin/TypedDocuments'
import {
  InspectionDefectFragment,
  InspectionDefectKind,
  ServiceAppointmentFragment,
} from 'GraphQL/Main/TypedDocuments'

import toast from 'Services/Toast'

import Utils from 'Utils'

import {
  DefectAssessment,
  FloorPlans,
  MajorDefects,
  MediaContent,
  MinorDefects,
  PurposeCopyrightContent,
  ServiceAppointmentSignatureContent,
  ServiceAppointmentTitleContent,
  TermsAndConditions,
} from './pages'
import styles from './styles'

type Props = {
  serviceAppointment?: ServiceAppointmentFragment
  inspectionDefects?: InspectionDefectFragment[]
  floorPlans?: FloorPlanFragment[]
  withoutHeader?: boolean
  onLoadSuccess?: () => void
}

function ServiceAppointmentPDF({
  serviceAppointment,
  inspectionDefects,
  floorPlans,
  withoutHeader,
  onLoadSuccess,
}: Props) {
  const [defects, setDefects] = useState<{
    majorDefects: InspectionDefectFragment[]
    minorDefects: InspectionDefectFragment[]
    compressedDefects: InspectionDefectFragment[]
  }>({
    majorDefects: [],
    minorDefects: [],
    compressedDefects: [],
  })
  const [compressing, setCompressing] = useState(false)

  const compressDefectImages = useCallback(async () => {
    if (!inspectionDefects) return

    try {
      setCompressing(true)

      const majors: InspectionDefectFragment[] = []
      const minors: InspectionDefectFragment[] = []
      const compressed: InspectionDefectFragment[] = []

      await Utils.Async.forEachPromise(inspectionDefects, async defect => {
        const compressedImageUrl =
          await Utils.InspectionDefect.getCompressedImageUrl(defect)

        compressed.push({
          ...defect,
          ...(defect.editedPhotoUrl && { editedPhotoUrl: compressedImageUrl }),
          ...(defect.originalPhotoUrl &&
            !defect.editedPhotoUrl && { originalPhotoUrl: compressedImageUrl }),
        })

        switch (defect.kind) {
          case InspectionDefectKind.Major: {
            majors.push(defect)
            break
          }

          case InspectionDefectKind.Minor: {
            minors.push(defect)
            break
          }

          default:
            break
        }
      })

      setDefects({
        majorDefects: majors,
        minorDefects: minors,
        compressedDefects: compressed,
      })

      setCompressing(false)
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [inspectionDefects])

  useEffect(() => {
    compressDefectImages().then()
  }, [compressDefectImages])

  if (compressing) {
    return <Loader fullScreen size={70} />
  }

  return (
    <PDFViewer height="100%" width="100%">
      <PDFDocument onRender={onLoadSuccess}>
        <Page size="A4" style={styles.container} wrap>
          {!!serviceAppointment?.watermarkUrl && (
            <View fixed style={styles.watermarkContainer}>
              <Image
                src={`${CORS}${serviceAppointment.watermarkUrl}`}
                style={styles.watermark}
              />
            </View>
          )}
          {!withoutHeader && (
            <>
              <ServiceAppointmentTitleContent
                serviceAppointment={serviceAppointment}
              />
              <PurposeCopyrightContent />
              <TermsAndConditions />
              <DefectAssessment />
            </>
          )}
          <MajorDefects
            audioTranscription={serviceAppointment?.audioRecord?.transcription}
            descriptionHidden={withoutHeader}
            inspectionDefects={defects.majorDefects}
          />
          <MinorDefects
            audioTranscription={serviceAppointment?.audioRecord?.transcription}
            descriptionHidden={withoutHeader}
            inspectionDefects={defects.minorDefects}
          />

          {!withoutHeader && <FloorPlans floorPlans={floorPlans} />}
          {!withoutHeader && (
            <ServiceAppointmentSignatureContent
              serviceAppointment={serviceAppointment}
            />
          )}
          <MediaContent
            audioTranscription={serviceAppointment?.audioRecord?.transcription}
            inspectionDefects={defects.compressedDefects}
            isCompassArrowShowing={serviceAppointment?.isCompassArrowShowing}
            isGpsRequired={serviceAppointment?.isGpsRequired}
          />
        </Page>
      </PDFDocument>
    </PDFViewer>
  )
}
export default ServiceAppointmentPDF
