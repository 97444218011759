import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { TableView } from 'Constants/serviceAppointments'

import {
  Events,
  KEY,
  ServiceAppointmentFilters,
  State,
} from 'Services/Store/serviceAppointments'

export default function useServiceAppointmentsState() {
  const { dispatch, [KEY]: state } = useStoreon<State, Events>(KEY)

  const updateTableView = useCallback(
    (view: TableView) => {
      dispatch('serviceAppointments/update', {
        view,
      })
    },
    [dispatch],
  )

  const updateDateRange = useCallback(
    (dateRange?: { from: string; to: string }) => {
      dispatch('serviceAppointments/update', {
        dateRange,
      })
    },
    [dispatch],
  )

  const updateFilters = useCallback(
    (filters?: ServiceAppointmentFilters) => {
      dispatch('serviceAppointments/update', {
        filters: {
          ...state.filters,
          ...filters,
        },
      })
    },
    [state, dispatch],
  )

  const clearFilters = useCallback(() => {
    dispatch('serviceAppointments/update', {
      filters: undefined,
    })
  }, [dispatch])

  const clear = useCallback(() => {
    dispatch('serviceAppointments/clear')
  }, [dispatch])

  return {
    state,
    updateTableView,
    updateDateRange,
    updateFilters,
    clearFilters,
    clear,
  }
}
