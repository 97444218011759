import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation } from '@apollo/client'
import BusinessIcon from '@mui/icons-material/Business'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Tooltip } from '@mui/material'
import Switch from '@mui/material/Switch'
import Utils from 'Utils'

import {
  FileUploadProgressModal,
  InspectionPDFGenerationModal,
} from 'Components/Blocks/Modals'
import {
  Column,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Row,
  Text,
} from 'Components/UI'

import { FLOOR_PLANS_ROOT } from 'Constants/paths'

import {
  InspectionFragment,
  UpdateInspectionDocument,
} from 'GraphQL/Admin/TypedDocuments'
import { FileType } from 'GraphQL/Main/TypedDocuments'

import { useFileDropzone, useSignFile } from 'Hooks'

import toast from 'Services/Toast'

type Props = {
  inspection?: InspectionFragment
  disabledFloorPlans?: boolean
  onOpenPdfReport?: (includeHeader: boolean) => void
}

function InspectionDropdown({
  inspection,
  onOpenPdfReport,
  disabledFloorPlans,
}: Props) {
  const { progress, getSignedUrl, abortUpload } = useSignFile()

  const theme = useTheme()
  const iconButtonRef = useRef(null)
  const color = useMemo(
    () => ({
      userIcon: themeGet('colors.neutral900')({ theme }),
      checkIcon: themeGet('colors.success400')({ theme }),
    }),
    [theme],
  )

  const [updateInspectionMutation, { loading: updateInspectionLoading }] =
    useMutation(UpdateInspectionDocument, {
      context: {
        admin: true,
      },
    })

  const [menuListOpen, setMenuListOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [pdfGenerationOpen, setPdfGenerationOpen] = useState(false)
  const [gpsEnabled, setGpsEnabled] = useState(!!inspection?.isGpsRequired)
  const [compassArrowEnabled, setCompassArrowEnabled] = useState(
    !!inspection?.isCompassArrowShowing,
  )

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])

  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])

  const handleOpenPdfGeneration = useCallback(() => {
    setPdfGenerationOpen(true)
    handleCloseMenuList()
  }, [handleCloseMenuList])

  const handleClosePdfGeneration = useCallback(() => {
    setPdfGenerationOpen(false)
  }, [])

  const handleCloseFileUploadModal = useCallback(() => {
    abortUpload()
    setFileUploadOpen(false)
  }, [abortUpload])

  const handleToggleGps = useCallback(async () => {
    if (!inspection) {
      return
    }

    const newGpsEnabled = !gpsEnabled
    setGpsEnabled(newGpsEnabled)

    if (!newGpsEnabled && compassArrowEnabled) {
      setCompassArrowEnabled(false)
    }

    try {
      await updateInspectionMutation({
        variables: {
          id: inspection.id,
          isGpsRequired: newGpsEnabled,
          isCompassArrowShowing:
            !newGpsEnabled && compassArrowEnabled ? false : undefined,
        },
      })

      if (!newGpsEnabled) {
        toast.info({
          text: `Opportunity GPS is disabled`,
        })
      } else {
        toast.success({
          text: `Opportunity GPS is enabled`,
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [inspection, compassArrowEnabled, gpsEnabled, updateInspectionMutation])

  const handleToggleCompassArrow = useCallback(async () => {
    if (!inspection) {
      return
    }

    const newCompassArrowEnabled = !compassArrowEnabled
    setCompassArrowEnabled(newCompassArrowEnabled)

    try {
      await updateInspectionMutation({
        variables: {
          id: inspection.id,
          isCompassArrowShowing: newCompassArrowEnabled,
        },
      })

      if (!newCompassArrowEnabled) {
        toast.info({
          text: `Compass arrow is disabled`,
        })
      } else {
        toast.success({
          text: `Compass arrow is enabled`,
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [inspection, compassArrowEnabled, updateInspectionMutation])

  const handleAddPdfHeaderPhoto = useCallback(
    async (files: File[]) => {
      if (!inspection) {
        return
      }

      try {
        setFileUploadOpen(true)
        handleCloseMenuList()

        const file = files[0]

        const compressedFile = await Utils.File.compressFile(file)

        const signedFile = await getSignedUrl({
          file: compressedFile,
          type: FileType.InspectionPhoto,
          serviceAppointmentId: inspection.serviceAppointment?.id,
          inspectionId: inspection.id,
        })
        if (!signedFile?.data?.fileSign) {
          return
        }

        await updateInspectionMutation({
          variables: {
            id: inspection.id,
            photoUrl: signedFile.data.fileSign.publicUrl,
          },
        })

        toast.success({
          text: `PDF header photo successfully added!`,
        })

        setFileUploadOpen(false)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [inspection, getSignedUrl, handleCloseMenuList, updateInspectionMutation],
  )

  const handleAddWatermark = useCallback(
    async (files: File[]) => {
      if (!inspection) {
        return
      }

      try {
        setFileUploadOpen(true)
        handleCloseMenuList()

        const file = files[0]
        const signedFile = await getSignedUrl({
          file,
          type: FileType.InspectionWatermark,
          inspectionId: inspection.id,
          serviceAppointmentId: inspection.serviceAppointment?.id,
        })
        if (!signedFile?.data?.fileSign) {
          return
        }

        await updateInspectionMutation({
          variables: {
            id: inspection.id,
            watermarkUrl: signedFile.data.fileSign.publicUrl,
          },
        })

        toast.success({
          text: `Watermark successfully added!`,
        })

        setFileUploadOpen(false)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [inspection, getSignedUrl, handleCloseMenuList, updateInspectionMutation],
  )

  const compassArrowTooltip = useMemo(() => {
    if (!inspection) {
      return 'The Work Order must be marked as Complete in Salesforce to export the PDF with compass arrow'
    }

    if (!gpsEnabled) {
      return 'You need to enable GPS first'
    }

    return null
  }, [inspection, gpsEnabled])

  const { openDropzone: pdfHeaderPhotoOpenDropzone } = useFileDropzone({
    onDropSuccess: handleAddPdfHeaderPhoto,
    acceptImageOnly: true,
  })
  const { openDropzone: watermarkOpenDropzone } = useFileDropzone({
    onDropSuccess: handleAddWatermark,
    acceptImageOnly: true,
  })

  return (
    <Column>
      <IconButton ref={iconButtonRef} onClick={handleOpenMenuList}>
        <MoreVertIcon style={{ color: color.userIcon, fontSize: 30 }} />
      </IconButton>

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        <Tooltip
          arrow
          placement="left"
          title={
            !inspection &&
            'The Work Order must be marked as Complete in Salesforce to export the PDF with GPS'
          }
        >
          <Row>
            <MenuItem
              active
              disabled={updateInspectionLoading || !inspection}
              width={1}
              onClick={handleToggleGps}
            >
              <Switch checked={gpsEnabled} />

              <Text caption2 color="primary400">
                GPS
              </Text>
            </MenuItem>
          </Row>
        </Tooltip>

        <Tooltip arrow placement="left" title={compassArrowTooltip}>
          <Row>
            <MenuItem
              active
              disabled={updateInspectionLoading || !inspection || !gpsEnabled}
              width={1}
              onClick={handleToggleCompassArrow}
            >
              <Switch checked={compassArrowEnabled} />

              <Text caption2 color="primary400">
                COMPASS ARROW
              </Text>
            </MenuItem>
          </Row>
        </Tooltip>

        {!!inspection && (
          <Tooltip
            arrow
            placement="left"
            title={disabledFloorPlans && 'Please, create at least one media'}
          >
            <Row>
              <MenuItem
                active
                component={Link}
                disabled={disabledFloorPlans}
                to={FLOOR_PLANS_ROOT(inspection?.id)}
                width={1}
              >
                <BusinessIcon />
                <Text mr={4}>FLOORPLANS</Text>
              </MenuItem>
            </Row>
          </Tooltip>
        )}

        <Tooltip
          arrow
          placement="left"
          title={
            !inspection &&
            'The Work Order must be marked as Complete in Salesforce to export the PDF with header photo'
          }
        >
          <Row>
            <MenuItem
              active
              disabled={!inspection}
              width={1}
              onClick={pdfHeaderPhotoOpenDropzone}
            >
              <InsertPhotoIcon />
              <Text mr={4}>
                {inspection?.photoUrl
                  ? 'CHANGE PDF HEADER PHOTO'
                  : 'ADD PDF HEADER PHOTO'}
              </Text>

              {!!inspection?.photoUrl && (
                <CheckCircleIcon
                  style={{
                    color: color.checkIcon,
                  }}
                />
              )}
            </MenuItem>
          </Row>
        </Tooltip>

        <Tooltip
          arrow
          placement="left"
          title={
            !inspection &&
            'The Work Order must be marked as Complete in Salesforce to export the PDF with watermark'
          }
        >
          <Row>
            <MenuItem
              active
              disabled={!inspection}
              width={1}
              onClick={watermarkOpenDropzone}
            >
              <UploadFileIcon />
              <Text mr={4}>
                {inspection?.watermarkUrl
                  ? 'CHANGE WATERMARK'
                  : 'ADD WATERMARK'}
              </Text>

              {!!inspection?.watermarkUrl && (
                <CheckCircleIcon
                  style={{
                    color: color.checkIcon,
                  }}
                />
              )}
            </MenuItem>
          </Row>
        </Tooltip>

        <Row>
          <MenuItem active width={1} onClick={handleOpenPdfGeneration}>
            <PictureAsPdfIcon />
            OPEN PDF
          </MenuItem>
        </Row>
      </MenuList>

      <FileUploadProgressModal
        isOpen={fileUploadOpen}
        progress={progress}
        onClose={handleCloseFileUploadModal}
      />

      {pdfGenerationOpen && (
        <InspectionPDFGenerationModal
          inspection={inspection}
          isOpen
          onClose={handleClosePdfGeneration}
          onOpenPdfReport={onOpenPdfReport}
        />
      )}
    </Column>
  )
}

export default InspectionDropdown
