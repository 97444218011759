import React, { ComponentProps } from 'react'
import ReactModal from 'react-modal'

type Props = ComponentProps<typeof ReactModal> & {}

function Adapter({ ...props }: Props) {
  const className = props?.className
  const overlayClassName = className ? `${className}__overlay` : ''
  return <ReactModal overlayClassName={overlayClassName} {...props} />
}

export default Adapter
