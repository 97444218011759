import React, { useCallback, useMemo } from 'react'

import { MarginProps } from 'styled-system'

import { DateTime } from 'luxon'

import { Image, ImagePreview, Player } from 'Components/Blocks'
import { Card, Column, Row, Text } from 'Components/UI'

import { TIME_12HR_FORMAT } from 'Constants/date'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

type Props = MarginProps & {
  audioUrl?: string
  defect: InspectionDefectFragment
}

function DefectItem({ audioUrl, defect, ...rest }: Props) {
  const { createdDateText, photoUrl } = useMemo(
    () => ({
      createdDateText: `Created: ${DateTime.fromISO(
        defect.createdAt,
      ).toLocaleString(TIME_12HR_FORMAT)}`,
      photoUrl: defect.editedPhotoUrl || defect.originalPhotoUrl,
    }),
    [defect],
  )

  const handleOpenGallery = useCallback(() => {
    ImagePreview.open(photoUrl || '')
  }, [photoUrl])

  return (
    <Card {...rest} flex={1} padding={3} width="376px">
      <Column height="100%">
        <Row center gap={3}>
          <Text body3 ellipsis heading maxWidth="120px">
            Figure: {defect.orderNumber}
          </Text>
          <Text ellipsis heading maxWidth="100px" muted>
            ({defect.id.slice(0, 8)})
          </Text>
        </Row>

        {!!photoUrl && (
          <Row height={127} justifyCenter mb={1} relative>
            <Image isClickable url={photoUrl} onClick={handleOpenGallery} />
          </Row>
        )}
        <Text body4 muted>
          {createdDateText}
        </Text>

        {!!audioUrl && (
          <Player
            startAtSecond={defect.defectAudioRelations?.[0].startAtSecond}
            url={audioUrl}
          />
        )}
      </Column>
    </Card>
  )
}

export default DefectItem
