import { HeadCell } from 'Components/Blocks/Tables/types'

import { ServiceAppointmentColumn } from 'Constants/serviceAppointments'

export const HEADER_CELLS: HeadCell[] = [
  {
    id: ServiceAppointmentColumn.AppointmentNumber,
    label: 'SA Number',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.ScheduledStartAt,
    label: 'Scheduled Date',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.CreatedAt,
    label: 'Creation Date',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.Subject,
    label: 'Opportunity Name',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.TotalDefectCount,
    label: 'Figures count',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.Status,
    label: 'Status',
    sortable: true,
  },
]
