import React, { useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { ShowConfirmModalParams, ShowModalParams } from 'Types/modals'

import { Loader } from 'Components/UI'

import { MeDocument } from 'GraphQL/Main/TypedDocuments'

import AppContainer from 'Containers/App'
import ConfirmModalPresenter from 'Containers/ConfirmModalPresenter'
import Modals from 'Containers/GlobalModals'

import { AppContext } from 'Services/AppContext'
import { Events, KEY, State } from 'Services/Store/session'

function App() {
  const { [KEY]: session } = useStoreon<State, Events>(KEY)
  const [showConfirmModal, setShowConfirmModal] =
    useState<(params?: ShowConfirmModalParams) => Promise<boolean>>()
  const [showModal, setShowModal] = useState<ShowModalParams>()

  const { data, loading } = useQuery(MeDocument, {
    skip: !session.token,
    notifyOnNetworkStatusChange: true,
  })

  const me = data?.me || undefined

  const handleShowModal = useCallback(
    (modal: ShowModalParams) => setShowModal(modal),
    [],
  )
  const handleCloseModal = useCallback(() => setShowModal(undefined), [])

  const contextValue = useMemo(() => {
    return {
      me,
      showConfirmModal,
      showModal: handleShowModal,
    }
  }, [me, handleShowModal, showConfirmModal])

  if (loading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <AppContext.Provider value={contextValue}>
      <AppContainer />

      <ConfirmModalPresenter
        onSetShow={show => setShowConfirmModal(() => show)}
      />
      <Modals modal={showModal} onClose={handleCloseModal} />
    </AppContext.Provider>
  )
}

export default App
