import { UserFragment } from 'GraphQL/Admin/TypedDocuments'

export enum InspectorColumns {
  CreatedAt = 'createdAt',
  Name = 'name',
  Email = 'email',
  Controls = 'controls',
}

export type InspectorColumnsType = {
  id: string
  createdAt: string | null
  name: string | null
  email: string | undefined
  user: UserFragment
}
