import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

type ChevronsContainerProps = {
  open?: boolean
}

const chevronsContainerOpenCss = ({ open }: ChevronsContainerProps) =>
  !open &&
  css`
    justify-content: center;
  `

export const ChevronsContainer = styled.div<ChevronsContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${themeGet('sizes.header')}px;
  padding: 0 ${themeGet('space.4')}px;

  ${chevronsContainerOpenCss}
`
