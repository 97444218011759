import { ShapeConfig } from 'konva/lib/Shape'

export enum ImageLoadingStatus {
  Loaded = 'loaded',
  Loading = 'loading',
  Failed = 'failed',
}

export type NoteConfig = ShapeConfig & {
  text?: string
  floorPlanId?: string
  inspectionId?: string
}
