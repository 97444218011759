export const ROOT = '/'

// Auth
export const AUTH = `${ROOT}auth`
export const LOGIN = `${AUTH}/login`
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`
export const CHANGE_EMAIL_PASSWORD = `${AUTH}/change-email-password`

// Main
export type ServiceAppointmentsRouteParams = {
  serviceAppointmentId?: string
  defectId?: string
  inspectionId?: string
}
export const SERVICE_APPOINTMENTS = `${ROOT}service-appointments`

export const SERVICE_APPOINTMENT_ROOT = (serviceAppointmentId?: string) =>
  `${SERVICE_APPOINTMENTS}/${serviceAppointmentId || ':serviceAppointmentId'}`
export const INSPECTION_ROOT = (inspectionId?: string) =>
  `${ROOT}inspection/${inspectionId || ':inspectionId'}`

export const INSPECTION_ROOT_PDF = (
  inspectionId?: string,
  withoutHeader?: boolean,
) =>
  `${INSPECTION_ROOT(inspectionId)}/report.pdf${
    withoutHeader ? '?withoutHeader=true' : ''
  }`
export const SERVICE_APPOINTMENT_ROOT_PDF = (
  serviceAppointmentId?: string,
  withoutHeader?: boolean,
) =>
  `${SERVICE_APPOINTMENT_ROOT(serviceAppointmentId)}/report.pdf${
    withoutHeader ? '?withoutHeader=true' : ''
  }`

export const INSPECTION_DEFECT_ROOT = (defectId?: string) =>
  `${ROOT}inspection-defect/${defectId || ':defectId'}`
export const SERVICE_APPOINTMENT_DEFECT_ROOT = (defectId?: string) =>
  `${ROOT}service-appointment-defect/${defectId || ':defectId'}`

export const CREATE_DEFECT_INSPECTION = (inspectionId?: string) =>
  `${INSPECTION_ROOT(inspectionId)}/create-defect`

export const CREATE_DEFECT_SERVICE_APPOINTMENT = (
  serviceAppointmentId?: string,
) => `${SERVICE_APPOINTMENT_ROOT(serviceAppointmentId)}/create-defect`

export const FLOOR_PLANS_ROOT = (inspectionId?: string) =>
  `${INSPECTION_ROOT(inspectionId)}/floorplans`

export const INSPECTORS = `${ROOT}inspectors`
export const ADMINS = `${ROOT}admins`
