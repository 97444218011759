import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '@apollo/client'
import qs from 'qs'

import { ServiceAppointmentPDF } from 'Components/Blocks'
import { Column, Loader } from 'Components/UI'

import {
  SERVICE_APPOINTMENT_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'

import { FloorPlanListDocument } from 'GraphQL/Admin/TypedDocuments'
import { ServiceAppointmentDocument } from 'GraphQL/Main/TypedDocuments'

import { useInspectionDefectList } from 'Hooks'

import toast from 'Services/Toast'

import Utils from 'Utils'

import { LoaderContainer } from './styles'

const DEFECTS_LIMIT = 999

function ServiceAppointmentReportPage() {
  const history = useHistory()
  const { serviceAppointmentId } = useParams<ServiceAppointmentsRouteParams>()

  const [loaded, setLoaded] = useState(false)

  const {
    data: serviceAppointmentData,
    loading: serviceAppointmentLoading,
    error: serviceAppointmentError,
  } = useQuery(ServiceAppointmentDocument, {
    variables: {
      id: serviceAppointmentId!,
    },
    skip: !serviceAppointmentId,
    fetchPolicy: 'network-only',
  })

  const { serviceAppointment, inspection, withoutHeader } = useMemo(
    () => ({
      serviceAppointment: serviceAppointmentData?.serviceAppointment,
      // NOTE: only one inspection for now
      inspection: serviceAppointmentData?.serviceAppointment?.inspections?.[0],
      withoutHeader: !!qs.parse(window.location.search.substring(1))
        ?.withoutHeader,
    }),
    [serviceAppointmentData],
  )

  useLayoutEffect(() => {
    if (!serviceAppointmentError) {
      return
    }
    const [graphQLError] = Utils.Errors.getGraphQLErrors(
      serviceAppointmentError,
    )
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [serviceAppointmentError, history])

  useLayoutEffect(() => {
    if (!withoutHeader && serviceAppointment && !serviceAppointment?.signUrl) {
      toast.error({ text: 'Please, upload the signature for the PDF' })
      history.push(SERVICE_APPOINTMENT_ROOT(serviceAppointmentId))
    }
  }, [serviceAppointment, withoutHeader, history, serviceAppointmentId])

  const { inspectionDefects, loading: inspectionDefectsLoading } =
    useInspectionDefectList({ serviceAppointmentId, limit: DEFECTS_LIMIT })

  const { data: floorPlansData, loading: floorPlansLoading } = useQuery(
    FloorPlanListDocument,
    {
      context: {
        admin: true,
      },
      variables: {
        inspectionId: inspection?.id!,
        limit: 999,
      },
      skip: !inspection?.id,
      fetchPolicy: 'network-only',
    },
  )

  const handleLoadSuccess = useCallback(() => {
    if (loaded) return

    setLoaded(true)
  }, [loaded])

  const titleText = useMemo(() => {
    const text = `PDF Service Appointment: ${
      serviceAppointment?.appointmentNumber ||
      serviceAppointment?.id || <>&mdash;</>
    }`
    /* if (inspection?.name) {
      text += ` | Opportunity: ${inspection?.name}`
    } */

    return text
  }, [serviceAppointment])

  if (
    serviceAppointmentLoading ||
    inspectionDefectsLoading ||
    floorPlansLoading
  ) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Helmet title={titleText} />
      <Column fullHeight minHeight={0}>
        {!loaded && (
          <LoaderContainer>
            <Loader size={70} />
          </LoaderContainer>
        )}

        <ServiceAppointmentPDF
          floorPlans={floorPlansData?.floorPlanList.rows}
          inspectionDefects={inspectionDefects}
          serviceAppointment={serviceAppointment}
          withoutHeader={withoutHeader}
          onLoadSuccess={handleLoadSuccess}
        />
      </Column>
    </Column>
  )
}

export default ServiceAppointmentReportPage
