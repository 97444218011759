import styled, { css } from 'styled-components'

import { DataGrid as MaterialDataGrid } from '@mui/x-data-grid'

import { DataGridProps } from './types'

const dataGridIsClickableCss = ({ isClickable }: DataGridProps) =>
  isClickable &&
  css`
    & .MuiDataGrid-row {
      cursor: pointer;
    }
  `

export const StyledGrid = styled(MaterialDataGrid)<DataGridProps>`
  &.MuiDataGrid-root {
    border: none;
    overflow: hidden;

    & .MuiDataGrid-cell {
      border: none;

      &:focus {
        outline: none;
      }
    }

    & .MuiDataGrid-columnHeader {
      user-select: none;

      &:focus,
      &:focus-within {
        outline: none;
      }
    }

    ${dataGridIsClickableCss}
  }
`
