import React, { ComponentProps, useCallback, useState } from 'react'

import ServiceAppointmentSelector from 'Components/Blocks/ServiceAppointments/Selector'
import { Button, Loader, Modal } from 'Components/UI'

import { ServiceAppointmentFragment } from 'GraphQL/Main/TypedDocuments'

type Props = {
  isOpen?: boolean
  loading?: boolean
  onClose?: () => void
  onMove?: (serviceAppointment: ServiceAppointmentFragment) => void
}

function MoveInspectionDefectModal({
  isOpen,
  loading,
  onClose,
  onMove,
}: Props) {
  const [selectedServiceAppointment, setSelectedServiceAppointment] =
    useState<ServiceAppointmentFragment>()

  const handleMove = useCallback(() => {
    if (!selectedServiceAppointment) return

    onMove?.(selectedServiceAppointment)
  }, [selectedServiceAppointment, onMove])

  const handleSelectInspection = useCallback<
    NonNullable<ComponentProps<typeof ServiceAppointmentSelector>['onSelect']>
  >(serviceAppointment => {
    setSelectedServiceAppointment(serviceAppointment)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      title="Move Selected Defects to:"
      onClose={onClose}
    >
      <ServiceAppointmentSelector
        disabled={loading}
        onSelect={handleSelectInspection}
      />

      <Button
        disabled={!selectedServiceAppointment || loading}
        mt={7}
        onClick={handleMove}
      >
        MOVE
        {loading && <Loader inverse ml={4} />}
      </Button>
      <Button mt={3} secondary onClick={onClose}>
        CANCEL
      </Button>
    </Modal>
  )
}

export default MoveInspectionDefectModal
