import { DateTime } from 'luxon'
import Utils from 'Utils'

import { UserFragment } from 'GraphQL/Admin/TypedDocuments'

import { AdminColumnsType } from './types'

function processAdminsData(admins?: UserFragment[]): AdminColumnsType[] {
  if (!admins?.length) {
    return []
  }

  return admins.map(admin => {
    return {
      id: admin.id,
      createdAt: !admin.createdAt
        ? null
        : DateTime.fromISO(admin.createdAt).toLocaleString(),

      name: Utils.User.getFullName(admin.profile) || null,
      email:
        !admin || !admin?.primaryEmailCredential?.email
          ? Utils.User.getUserIdentifier(admin)
          : admin.primaryEmailCredential?.email,
      user: admin,
    }
  })
}

export { processAdminsData }
