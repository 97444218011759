import React from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { AuthLayout } from 'Containers/Layout'
import AuthPages from 'Containers/Pages/Auth'

function authRoutes(): RouteConfig {
  return {
    path: paths.AUTH,
    component: AuthLayout,
    routes: [
      {
        path: paths.AUTH,
        exact: true,
        component: () => <Redirect to={paths.LOGIN} />,
      },
      {
        path: paths.LOGIN,
        exact: true,
        component: AuthPages.Login,
      },
      {
        path: paths.FORGOT_PASSWORD,
        exact: true,
        component: AuthPages.ForgotPassword,
      },
      {
        path: paths.CHANGE_EMAIL_PASSWORD,
        exact: true,
        component: AuthPages.ResetPassword,
      },
      { component: () => <Redirect to={paths.LOGIN} /> },
    ],
  }
}

export default authRoutes
