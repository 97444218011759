import React from 'react'
import { Html } from 'react-pdf-html'

import { Link as PDFLink, Text, View } from '@react-pdf/renderer'
import Utils from 'Utils'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

import styles from '../styles'

type Props = {
  audioTranscription?: string | null
  inspectionDefects?: Array<InspectionDefectFragment>
}

function DefectDescriptionLinks({
  audioTranscription,
  inspectionDefects,
}: Props) {
  return (
    <View>
      {!inspectionDefects?.length ? (
        <Text style={styles.caption2}>
          None noted at the time of the inspection.
        </Text>
      ) : (
        <View style={styles.mt5}>
          {inspectionDefects.map(inspectionDefect => {
            const description =
              inspectionDefect.description || audioTranscription

            return (
              <PDFLink
                key={inspectionDefect.id}
                src={`#${inspectionDefect.id}`}
                style={[
                  styles.row,
                  styles.spaceBetween,
                  styles.defectDescriptionLink,
                  styles.mt2,
                ]}
              >
                <View
                  style={[styles.column, styles.defectDescriptionContainer]}
                >
                  <Text style={styles.body3}>
                    Figure ({inspectionDefect.orderNumber})
                    {!!description &&
                      (description.includes('<p>') ? (
                        <>
                          {': '}
                          <Html
                            stylesheet={{
                              p: styles.defectListDescription,
                            }}
                          >{`<html><body>${Utils.String.addLineBreaksBetweenParagraphs(
                            description,
                          )}</body></html>`}</Html>
                        </>
                      ) : (
                        `: ${description}`
                      ))}
                  </Text>
                </View>
              </PDFLink>
            )
          })}
        </View>
      )}
    </View>
  )
}

export default DefectDescriptionLinks
