export const PDF_MAP_ARROW_COORDINATES = {
  X: 20,
  Y: 34,
}

export const IMAGE_MAP_ARROW_COORDINATES = {
  Y: 54,
}

export const PINDROP_COORDINATES = {
  X: 30,
  Y: 30,
}
