import { HeadCell } from 'Components/Blocks/Tables/types'

import { ServiceAppointmentColumn } from 'Constants/serviceAppointments'

export const HEADER_CELLS: HeadCell[] = [
  {
    id: ServiceAppointmentColumn.CreatedAt,
    label: 'Creation Date',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.Subject,
    label: 'Opportunity Name',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.ProjectName,
    label: 'Project name',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.TotalDefectCount,
    label: 'Figures count',
    sortable: true,
  },
  {
    id: ServiceAppointmentColumn.Status,
    label: 'Status',
    sortable: true,
  },
]
