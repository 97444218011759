import React from 'react'

import InfiniteScroll from 'Components/Blocks/InfiniteScroll'
import { Column, Loader, Row, Text } from 'Components/UI'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

import DefectItem from './DefectItem'

type Props = {
  audioUrl?: string
  inspectionDefects?: InspectionDefectFragment[]
  canLoadMore?: boolean
  loadingMore?: boolean
  loadMore?: () => void
}

function FloorPlansDefectList({
  audioUrl,
  inspectionDefects,
  canLoadMore,
  loadingMore,
  loadMore,
}: Props) {
  return (
    <Column fullHeight>
      <Text action3 mb={3}>
        List of defects:
      </Text>
      {/* TODO: change to Virtuoso */}
      <InfiniteScroll
        canLoadMore={canLoadMore}
        rootMargin="20px"
        shadowy
        onLoadMore={loadMore}
      >
        <Column px={1}>
          {inspectionDefects?.map(defect => (
            <DefectItem
              audioUrl={audioUrl}
              defect={defect}
              key={defect.id}
              mb={5}
            />
          ))}
        </Column>

        {loadingMore && (
          <Row center gap={3} justifyCenter mb={8} mt={4}>
            <Loader size={30} />

            <Text caption2 center muted>
              LOADING...
            </Text>
          </Row>
        )}
      </InfiniteScroll>
    </Column>
  )
}

export default FloorPlansDefectList
