import React, { ComponentProps, useCallback } from 'react'

import { Card } from 'Components/UI'

import NoRowsOverlay from './NoRowsOverlay'
import Pagination from './Pagination'
import { StyledGrid } from './styles'
import { DataGridProps } from './types'

type Props = DataGridProps & {
  pageSize?: number
  page?: number
  totalPages?: number
  loading?: boolean
  onChangePageSize?: ComponentProps<typeof Pagination>['onChangePageSize']
  onChangePage?: ComponentProps<typeof Pagination>['onChangePage']
}

const SLOT_PROPS = {
  toolbar: {
    csvOptions: { disableToolbarButton: true },
    printOptions: { disableToolbarButton: true },
  },
}

function DataGrid({
  pageSize,
  page,
  totalPages,
  onChangePageSize,
  onChangePage,
  loading,
  ...rest
}: Props) {
  const renderPagination = useCallback(() => {
    return (
      <Pagination
        loading={loading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    )
  }, [pageSize, page, totalPages, loading, onChangePage, onChangePageSize])

  return (
    <Card flex={1} overflow="auto">
      <StyledGrid
        {...rest}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        loading={loading}
        slotProps={SLOT_PROPS}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          pagination: renderPagination,
        }}
        sortingMode="server"
      />
    </Card>
  )
}

export default DataGrid
