const PARAGRAPHS_MATCH = /(?<!<br>)<\/p><p>/g
const PARAGRAPHS_WITH_LINE_BREAK = '</p><p><br></p><p>'

function color(value: string) {
  let hash = 0

  for (let i = 0; i < value.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }

  return color
}

function addLineBreaksBetweenParagraphs(value: string) {
  return value.replace(PARAGRAPHS_MATCH, PARAGRAPHS_WITH_LINE_BREAK)
}

export default { color, addLineBreaksBetweenParagraphs }
