import { RouteConfig } from 'react-router-config'

import { UserFragment, UserRole } from 'GraphQL/Main/TypedDocuments'

import { RootLayout } from 'Containers/Layout'
import NotFound from 'Containers/Pages/NotFound'

import adminRoutes from './admin'
import authRoutes from './auth'
import inspectorRoutes from './inspector'
import pdfRoutes from './pdf'

function routes(user?: UserFragment) {
  const allRoutes: RouteConfig[] = [...pdfRoutes]

  if (!user) {
    allRoutes.push(authRoutes())
  } else if (
    user?.role === UserRole.Admin ||
    user?.role === UserRole.SuperAdmin
  ) {
    allRoutes.push(adminRoutes())
  } else if (user?.role === UserRole.User) {
    allRoutes.push(inspectorRoutes())
  }

  return [
    {
      component: RootLayout,
      routes: [...allRoutes, { component: NotFound }],
    },
  ]
}

export { routes }
