import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { CORS } from 'Config'

import { COMPANY_EMAIL, COMPANY_PHONE } from 'Constants/company'

import { ServiceAppointmentFragment } from 'GraphQL/Main/TypedDocuments'

import styles from '../styles'

type Props = {
  serviceAppointment?: ServiceAppointmentFragment
}

function ServiceAppointmentSignatureContent({ serviceAppointment }: Props) {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      <View style={[styles.column, styles.mt350]}>
        <Text style={styles.caption2}>Yours faithfully</Text>
        {!!serviceAppointment?.signUrl && (
          <View
            style={[styles.signImageContainer, styles.mt8, styles.lowerZIndex]}
          >
            <Image
              src={`${CORS}${serviceAppointment.signUrl}`}
              style={styles.signImage}
            />
          </View>
        )}
        {/* TODO: not sure about field relevance */}
        <Text style={[styles.caption2, styles.mt8]}>Michael Burford</Text>
        <Text style={[styles.action4, styles.mt12]}>
          AUSTRALIAN DILAPIDATIONS
        </Text>
        <Text style={styles.caption2}>Office: {COMPANY_PHONE}</Text>
        <Text style={styles.caption2}>Email: {COMPANY_EMAIL}</Text>
      </View>
    </View>
  )
}

export default ServiceAppointmentSignatureContent
