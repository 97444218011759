import { ServiceAppointmentStatus } from 'GraphQL/Main/TypedDocuments'

const STATUS_COLOR = {
  [ServiceAppointmentStatus.Inspected]: 'success500',
  [ServiceAppointmentStatus.PartiallyInspected]: 'success400',
  [ServiceAppointmentStatus.Revert]: 'primary200',
  [ServiceAppointmentStatus.InProgress]: 'warning700',
  [ServiceAppointmentStatus.PendingBooking]: 'warning600',
  [ServiceAppointmentStatus.AccessExhausted]: 'danger200',
  [ServiceAppointmentStatus.DoorKnock]: 'primary300',
  [ServiceAppointmentStatus.Unknown]: 'neutral600',
  [ServiceAppointmentStatus.Dispatched]: 'danger500',
}

const STATUS_TEXT = {
  [ServiceAppointmentStatus.Inspected]: 'Inspected',
  [ServiceAppointmentStatus.PartiallyInspected]: 'Partially inspected',
  [ServiceAppointmentStatus.Revert]: 'Revert',
  [ServiceAppointmentStatus.InProgress]: 'In progress',
  [ServiceAppointmentStatus.PendingBooking]: 'Pending booking',
  [ServiceAppointmentStatus.AccessExhausted]: 'Access exhausted',
  [ServiceAppointmentStatus.DoorKnock]: 'Door knock',
  [ServiceAppointmentStatus.Unknown]: 'Unknown',
  [ServiceAppointmentStatus.Dispatched]: 'Dispatched',
}
function getStatusColor(status: ServiceAppointmentStatus): string {
  return STATUS_COLOR[status] || 'neutral200'
}

function getStatusText(status: ServiceAppointmentStatus): string {
  return STATUS_TEXT[status] || 'N/A'
}

export default {
  getStatusColor,
  getStatusText,
}
