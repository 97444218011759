import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

import { tinyLogoImage } from 'Assets/Images/PDF'

import { CORS } from 'Config'

import {
  COMPANY_ABN,
  COMPANY_ADDRESS,
  COMPANY_DETAIL_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_PHONE,
} from 'Constants/company'

import { InspectionFragment } from 'GraphQL/Main/TypedDocuments'

import Utils from 'Utils'

import styles from '../styles'

type Props = {
  inspection?: InspectionFragment
}

function TitleContent({ inspection }: Props) {
  return (
    <>
      <View
        style={[styles.row, styles.mt5, styles.justifyCenter, styles.alignEnd]}
      >
        <Image src={tinyLogoImage} style={styles.companyLogo} />

        <Text style={styles.subheader2}>ABN: {COMPANY_ABN}</Text>
        <Text style={[styles.subheader2, styles.ml8]}>
          Telephone: {COMPANY_PHONE}
        </Text>
        <Text style={[styles.subheader2, styles.ml8]}>
          Email: {COMPANY_EMAIL}
        </Text>
      </View>

      {!!inspection?.reportType && (
        <View style={[styles.column, styles.center, styles.mt5]}>
          <Text style={styles.caption1}>{inspection.reportType}</Text>
        </View>
      )}
      <View style={[styles.column, styles.center]}>
        <Text style={styles.caption2}>{COMPANY_ADDRESS}</Text>
        <Text style={styles.caption2}>{COMPANY_DETAIL_ADDRESS}</Text>
      </View>

      <View
        style={[
          styles.row,
          styles.spaceBetween,
          styles.alignStart,
          styles.mt5,
          styles.lateralPaddingContainer,
        ]}
      >
        <View style={[styles.column, styles.headerColumn]}>
          <>
            <Text style={styles.subheader1}>COMMISSIONED BY:</Text>

            <Text style={[styles.body3, styles.mt2]}>
              Client:{' '}
              {inspection?.accountPrimaryContact ? (
                inspection.accountPrimaryContact
              ) : (
                <>&mdash;</>
              )}
            </Text>

            <Text style={[styles.body3, styles.mt2]}>
              Company:{' '}
              {inspection?.accountName ? inspection.accountName : <>&mdash;</>}
            </Text>

            <Text style={[styles.body3, styles.mt2]}>
              Address:{' '}
              {inspection?.accountAddress ? (
                inspection.accountAddress
              ) : (
                <>&mdash;</>
              )}
            </Text>
          </>

          <Text style={[styles.subheader1, styles.mt5]}>PROJECT:</Text>
          <Text style={[styles.body3, styles.mt2, styles.projectName]}>
            {inspection?.project.name ? inspection?.project.name : <>&mdash;</>}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>SITE SURVEYED:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.siteAddress ? inspection.siteAddress : <>&mdash;</>}
          </Text>
        </View>

        <View style={[styles.column, styles.headerColumn]}>
          <Text style={styles.subheader1}>INSPECTION DATE:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.inspectionDate ? (
              DateTime.fromISO(inspection.inspectionDate).toLocaleString()
            ) : (
              <>&mdash;</>
            )}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>JOB REFERENCE:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.internalReference ? (
              `Report#${inspection.internalReference}`
            ) : (
              <>&mdash;</>
            )}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>DOCUMENT NUMBER:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.externalReference ? (
              `${inspection.externalReference}`
            ) : (
              <>&mdash;</>
            )}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>INSPECTOR:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.inspectorUser ? (
              <>
                {Utils.User.getUserIdentifier(inspection.inspectorUser)} &{' '}
                {inspection.inspectorUser.role}
              </>
            ) : (
              <>&mdash;</>
            )}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>WEATHER:</Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.weather ? `${inspection.weather}` : <>&mdash;</>}
            {', '}
            {inspection?.temperature ? inspection.temperature : <>&mdash;</>}
          </Text>

          <Text style={[styles.subheader1, styles.mt5]}>
            REPORT PREPARED BY:
          </Text>
          <Text style={[styles.body3, styles.mt2]}>
            {inspection?.reportWriter ? inspection.reportWriter : <>&mdash;</>}
          </Text>
        </View>
      </View>

      {!!inspection?.photoUrl && (
        <View
          style={[
            styles.headerImageContainer,
            styles.mt8,
            styles.lateralPaddingContainer,
            styles.lowerZIndex,
          ]}
        >
          <Image
            src={`${CORS}${inspection.photoUrl}`}
            style={styles.headerImage}
          />
        </View>
      )}
    </>
  )
}

export default TitleContent
