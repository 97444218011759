import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ShowConfirmModalParams } from 'Types/modals'

import { ConfirmModal } from 'Components/UI'

function ConfirmModalPresenter({ onSetShow }) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalParams, setModalParams] = useState<ShowConfirmModalParams>({})

  const promiseRef = useRef<{
    resolve: (value: boolean) => void
  }>()

  const handleShow = useCallback(async (params: ShowConfirmModalParams) => {
    return new Promise<boolean>(resolve => {
      promiseRef.current = { resolve }
      setModalParams(params)
      setIsOpen(true)
    })
  }, [])

  useEffect(() => {
    onSetShow?.(handleShow)
  }, [onSetShow, handleShow])

  const handleCallback = useCallback<
    NonNullable<ComponentProps<typeof ConfirmModal>['onCallback']>
  >(result => {
    promiseRef.current?.resolve(result)
    setIsOpen(false)
    setModalParams({})
  }, [])

  if (!isOpen) return null

  return (
    <ConfirmModal
      cancelText={modalParams?.cancelText}
      danger={modalParams?.danger}
      isOpen={isOpen}
      mainIcon={modalParams?.mainIcon}
      okText={modalParams?.okText}
      primary={modalParams?.primary}
      success={modalParams?.success}
      title={modalParams?.title}
      warning={modalParams?.warning}
      onCallback={handleCallback}
    >
      {modalParams?.children}
    </ConfirmModal>
  )
}

export default ConfirmModalPresenter
