import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import Adapter from './Adapter'

export const StyledModal = styled(Adapter)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
  }

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  margin: ${themeGet('space.4')}px;
`

export const ImageContent = styled.img`
  max-width: 100%;
  flex: 1;
  user-select: none;
  overflow: hidden;
  object-fit: scale-down;
`
