import { ApolloCache } from '@apollo/client'

import {
  InspectionDefectDocument,
  InspectionDefectFragment,
} from 'GraphQL/Main/TypedDocuments'

type UpdateInspectionDefectUpdaterProps = {
  cache: ApolloCache<any>
  id?: InspectionDefectFragment['id']
  inspectionDefect?: InspectionDefectFragment
}

function update({
  cache,
  id,
  inspectionDefect,
}: UpdateInspectionDefectUpdaterProps) {
  if (!id || !inspectionDefect) return

  const query = {
    query: InspectionDefectDocument,
    variables: { id },
  }

  const data = cache.readQuery(query)
  if (!data) {
    return
  }

  cache.writeQuery({
    ...query,
    data: {
      ...data,
      inspectionDefect,
    },
  })
}

const InspectionUpdater = {
  update,
}

export default InspectionUpdater
