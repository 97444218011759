import React from 'react'
import { Redirect } from 'react-router'

import * as paths from 'Constants/paths'

import { InspectorLayout } from 'Containers/Layout'
import Common from 'Containers/Pages/Common'
import Inspector from 'Containers/Pages/Inspector'
import NotFound from 'Containers/Pages/NotFound'

function inspectorRoutes() {
  return {
    path: paths.ROOT,
    component: InspectorLayout,
    routes: [
      {
        path: paths.ROOT,
        exact: true,
        component: () => <Redirect to={paths.SERVICE_APPOINTMENTS} />,
      },
      {
        path: paths.SERVICE_APPOINTMENTS,
        exact: true,
        component: Inspector.ServiceAppointments,
      },
      {
        path: paths.SERVICE_APPOINTMENT_ROOT(),
        exact: true,
        component: Inspector.ServiceAppointment,
      },
      {
        path: paths.CREATE_DEFECT_SERVICE_APPOINTMENT(),
        exact: true,
        component: Common.CreateServiceAppointmentDefect,
      },
      {
        path: paths.CREATE_DEFECT_INSPECTION(),
        exact: true,
        component: Common.CreateInspectionDefect,
      },
      {
        path: paths.INSPECTION_ROOT(),
        exact: true,
        component: Inspector.Inspection,
      },
      {
        path: paths.INSPECTION_DEFECT_ROOT(),
        exact: true,
        component: Inspector.InspectionDefectEditor,
      },
      {
        path: paths.SERVICE_APPOINTMENT_DEFECT_ROOT(),
        exact: true,
        component: Inspector.ServiceAppointmentDefectEditor,
      },
      { component: NotFound },
    ],
  }
}

export default inspectorRoutes
