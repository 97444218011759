import { InspectionStatus } from 'GraphQL/Main/TypedDocuments'

const STATUS_COLOR = {
  [InspectionStatus.Delivered]: 'success500',
  [InspectionStatus.DraftDelivered]: 'success400',
  [InspectionStatus.QualityReview]: 'primary300',
  [InspectionStatus.ReadyForDelivery]: 'primary200',
  [InspectionStatus.InRevision]: 'warning700',
  [InspectionStatus.PendingStart]: 'warning600',
  [InspectionStatus.PendingUpload]: 'warning600',
  [InspectionStatus.PendingFloorPlanSignature]: 'warning500',
  [InspectionStatus.InspectorReviewing]: 'warning500',
  [InspectionStatus.TypingInProgress]: 'neutral600',
  [InspectionStatus.Unknown]: 'danger200',
  [InspectionStatus.Unsuccessful]: 'danger500',
}

const STATUS_TEXT = {
  [InspectionStatus.Delivered]: 'Delivered',
  [InspectionStatus.DraftDelivered]: 'Draft delivered',
  [InspectionStatus.InRevision]: 'In revision',
  [InspectionStatus.InspectorReviewing]: 'Inspector reviewing',
  [InspectionStatus.PendingFloorPlanSignature]: 'Pending floor plan signature',
  [InspectionStatus.PendingStart]: 'Pending start',
  [InspectionStatus.PendingUpload]: 'Pending upload',
  [InspectionStatus.QualityReview]: 'Quality review',
  [InspectionStatus.ReadyForDelivery]: 'Ready for delivery',
  [InspectionStatus.TypingInProgress]: 'Typing in progress',
  [InspectionStatus.Unknown]: 'Unknown',
  [InspectionStatus.Unsuccessful]: 'Unsuccessful',
}
function getStatusColor(status: InspectionStatus): string {
  return STATUS_COLOR[status] || 'neutral200'
}

function getStatusText(status: InspectionStatus): string {
  return STATUS_TEXT[status] || 'N/A'
}

export default {
  getStatusColor,
  getStatusText,
}
