import React, { useCallback } from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ApolloProvider } from '@apollo/client'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import { ImagePreview } from 'Components/Blocks'
import { Toast } from 'Components/UI'

import { createApolloClient } from 'Services/Apollo'
import { createStore } from 'Services/Store'

import { GlobalStyle, ThemeProvider } from 'Theme'

import App from './App'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-quill/dist/quill.snow.css'
import 'react-vis/dist/style.css'

const history = createBrowserHistory()
const apolloClient = createApolloClient()
const store = createStore()

function Container() {
  const renderToastCloseButton = useCallback(
    ({ closeToast }) => <Toast.CloseButton onClose={closeToast} />,
    [],
  )

  return (
    <ApolloProvider client={apolloClient}>
      <StoreContext.Provider value={store}>
        <ThemeProvider>
          <GlobalStyle />

          <ToastContainer
            className="toast-container_v2"
            closeButton={renderToastCloseButton}
            closeOnClick={false}
            draggable
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss
            pauseOnHover
            position="top-right"
            rtl={false}
          />

          <ImagePreview />

          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </StoreContext.Provider>
    </ApolloProvider>
  )
}

export default Container
