import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { Column } from 'Components/UI'

type Props = {
  route?: RouteConfig
}

function InspectorLayout({ route }: Props) {
  return <Column fullHeight>{renderRoutes(route?.routes)}</Column>
}

export default InspectorLayout
