import React, { useCallback, useRef, useState } from 'react'

import MaterialPagination from '@mui/material/Pagination'

import { MenuItem, MenuList, Row, Text } from 'Components/UI'

import { PAGINATION_SIZES } from 'Constants/dataGrid'

type Props = {
  pageSize?: number
  page?: number
  totalPages?: number
  loading?: boolean
  onChangePageSize?: (size: number) => void
  onChangePage?: (page: number) => void
}

function Pagination({
  pageSize,
  page,
  totalPages,
  loading,
  onChangePageSize,
  onChangePage,
}: Props) {
  const iconButtonRef = useRef(null)
  const [menuListOpen, setMenuListOpen] = useState(false)

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])
  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])
  const handleChangePageSize = useCallback(
    (size: number) => {
      onChangePageSize?.(size)
      onChangePage?.(0)
    },
    [onChangePageSize, onChangePage],
  )

  if (!totalPages) {
    return null
  }

  return (
    <Row center gap={5}>
      <Row center gap={2}>
        <Text color="neutral100">Rows per page:</Text>
        <MenuItem
          disabled={loading}
          ref={iconButtonRef}
          small
          onClick={handleOpenMenuList}
        >
          <Text color="neutral100">{pageSize}</Text>
        </MenuItem>
      </Row>

      <MaterialPagination
        count={totalPages}
        disabled={loading}
        page={page !== undefined ? page + 1 : 0}
        onChange={(_, pageValue) => onChangePage?.(pageValue - 1)}
      />

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        {PAGINATION_SIZES.map(size => (
          <MenuItem
            key={size}
            selected={size === pageSize}
            onClick={() => handleChangePageSize(size)}
          >
            {size}
          </MenuItem>
        ))}
      </MenuList>
    </Row>
  )
}

export default Pagination
