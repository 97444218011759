import React, {
  ComponentProps,
  ComponentRef,
  useCallback,
  useRef,
  useState,
} from 'react'
import ReactPlayer from 'react-player'

import { MarginProps } from 'styled-system'

import { Column } from 'Components/UI'

import CustomPlayer from './CustomPlayer'

type Props = MarginProps & {
  url: string
  playButtonAccessory?: React.ReactNode
}

const PLAYER_CONFIG = {
  file: {
    attributes: { controlsList: 'nodownload noplaybackrate' },
  },
}

function LargePlayer({ url, playButtonAccessory, ...rest }: Props) {
  const playerRef = useRef<ComponentRef<typeof ReactPlayer> | null>(null)

  const [play, setPlay] = useState(false)
  const [currentDuration, setCurrentDuration] = useState(0)
  const [volume, setVolume] = useState(50)
  const [totalDuration, setTotalDuration] = useState(0)
  const [muted, setMuted] = useState(false)
  const [loading, setLoading] = useState(true)

  const finished = totalDuration === currentDuration

  const handleReady = useCallback(() => {
    if (!playerRef.current) {
      return
    }

    setLoading(false)
    setTotalDuration(Math.round(playerRef.current.getDuration()))
  }, [])

  const handlePause = useCallback(() => {
    setPlay(false)
  }, [])

  const handlePlay = useCallback(() => {
    setPlay(true)
  }, [])

  const handleChangeDuration = useCallback<
    NonNullable<ComponentProps<typeof CustomPlayer>['onChangeDuration']>
  >((_, value) => {
    if (typeof value !== 'number') {
      return
    }

    setCurrentDuration(value)
    playerRef.current?.seekTo(value)
  }, [])

  const handleChangeVolume = useCallback<
    NonNullable<ComponentProps<typeof CustomPlayer>['onChangeVolume']>
  >(
    (_, value) => {
      if (typeof value !== 'number') {
        return
      }

      if (muted) {
        setMuted(false)
      }

      setVolume(Math.round(value))
    },
    [muted],
  )

  const handleToggleMute = useCallback(() => {
    setMuted(prevState => !prevState)
  }, [])

  const handleChangeProgress = useCallback<
    NonNullable<ComponentProps<typeof ReactPlayer>['onProgress']>
  >(
    event => {
      const playedSeconds = Math.round(event.playedSeconds)

      if (playedSeconds === totalDuration) {
        setPlay(false)
        setCurrentDuration(0)
        playerRef.current?.seekTo(0)

        return
      }

      setCurrentDuration(playedSeconds)
    },
    [totalDuration],
  )

  return (
    <Column {...rest}>
      <ReactPlayer
        config={PLAYER_CONFIG}
        height={0}
        muted={muted}
        playing={play}
        ref={playerRef}
        url={url}
        volume={volume / 100}
        width={0}
        onProgress={handleChangeProgress}
        onReady={handleReady}
      />
      <CustomPlayer
        currentDuration={currentDuration}
        finished={finished}
        loading={loading}
        muted={muted}
        playButtonAccessory={playButtonAccessory}
        playing={play}
        totalDuration={totalDuration}
        volume={muted ? 0 : volume}
        onChangeDuration={handleChangeDuration}
        onChangeVolume={handleChangeVolume}
        onPause={handlePause}
        onPlay={handlePlay}
        onToggleMute={handleToggleMute}
      />
    </Column>
  )
}

export default LargePlayer
