import React, {
  ComponentProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import LoadMoreTrigger from './LoadMoreTrigger'
import { ScrollContainer } from './styles'

type Props = ComponentProps<typeof ScrollContainer> & {
  children?: ReactNode
  canLoadMore?: boolean
  rootMargin?: string
  shadowy?: boolean
  onLoadMore?: () => void
}

function InfiniteScroll({
  children,
  rootMargin = '0px',
  canLoadMore,
  shadowy,
  onLoadMore,
  ...rest
}: Props) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const [triggerObserver, setTriggerObserver] = useState<IntersectionObserver>()

  useEffect(() => {
    if (!scrollContainerRef.current) return undefined

    const callback: IntersectionObserverCallback = entries => {
      const isIntersecting = entries.some(entry => entry.isIntersecting)
      if (isIntersecting) onLoadMore?.()
    }

    const observer = new IntersectionObserver(callback, {
      root: scrollContainerRef.current,
      rootMargin,
      threshold: 0.0,
    })

    setTriggerObserver(observer)

    return () => {
      observer.disconnect()
    }
  }, [rootMargin, onLoadMore])

  return (
    <ScrollContainer {...rest} ref={scrollContainerRef} shadowy={shadowy}>
      {children}
      {canLoadMore && <LoadMoreTrigger observer={triggerObserver} />}
    </ScrollContainer>
  )
}

export default InfiniteScroll
