import React, { useEffect, useState } from 'react'
import { Image as KonvaImage } from 'react-konva'

type Props = {
  image?: HTMLImageElement
  height?: number
  onClick?: () => void
}

type ImageSize = {
  width: number
  height: number
}

function Image({ image, height = 250, onClick }: Props) {
  const [imageSize, setImageSize] = useState<ImageSize | null>(null)

  useEffect(() => {
    if (!image) return

    setImageSize({
      width: image.width,
      height: image.height,
    })
  }, [image])

  if (!image || !imageSize) {
    return null
  }

  return (
    <KonvaImage
      image={image}
      scaleX={height / imageSize.height}
      scaleY={height / imageSize.height}
      onClick={onClick}
    />
  )
}

export default Image
