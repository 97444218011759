import React, {
  ComponentProps,
  ComponentRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Layer, Stage } from 'react-konva'
import { useResizeDetector } from 'react-resize-detector'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material'
import { ShapeConfig } from 'konva/lib/Shape'
import useImage from 'use-image'
import { v4 as uuid } from 'uuid'

import { compassImage, mapArrowImage } from 'Assets/Images'

import { Image as ImageBlock, KonvaImage, StyledStage } from 'Components/Blocks'
import { InspectionDefectDropdown } from 'Components/Blocks/Dropdowns'
import { Button, Loader, Modal, Row, Text } from 'Components/UI'

import { CORS } from 'Config'

import {
  BREAKPOINT_WIDTH,
  INITIAL_SHAPE_CONFIG,
  MAP_STAGE_WIDTH,
  SHAPE_DIMENSIONS,
  STAGE_HEIGHT,
} from 'Constants/konva'

import {
  FileType,
  InspectionDefectFragment,
  InspectionDefectKind,
} from 'GraphQL/Main/TypedDocuments'

import { useInspectionDefectEntity, useSignFile } from 'Hooks'

import { KeyboardKey } from 'Types/keyboard'
import { ImageLoadingStatus } from 'Types/konva'

import Utils from 'Utils'

import ColorPicker from './ColorPicker'
import Shape from './Shape'
import {
  ButtonsContainer,
  CompassContainer,
  LoaderContainer,
  MapArrowContainer,
  PindropContainer,
  RadioValuesContainer,
  StageContainer,
} from './styles'
import { ArrowColors } from './types'

type Props = {
  url?: string
  isOpen?: boolean
  inspectionDefect?: InspectionDefectFragment
  inspectionId?: string
  serviceAppointmentId?: string
  isGpsRequired?: boolean
  isCompassArrowShowing?: boolean
  onDelete?: () => void
  onClose?: () => void
  onChangeImage?: ComponentProps<
    typeof InspectionDefectDropdown
  >['onChangeImage']
  onShowActivity?: () => void
  onRemoveDrawings?: () => void
  onDownloadImage?: ComponentProps<
    typeof InspectionDefectDropdown
  >['onDownloadImage']
}

function InspectionDefectModal({
  isOpen,
  url,
  inspectionDefect,
  inspectionId,
  isGpsRequired,
  isCompassArrowShowing,
  onDelete,
  onClose,
  onChangeImage,
  onShowActivity,
  onRemoveDrawings,
  onDownloadImage,
  serviceAppointmentId,
}: Props) {
  const theme = useTheme()
  const { ref: resizeDetectorRef, width: stageContainerWidth } =
    useResizeDetector()
  const { getSignedUrl } = useSignFile()
  const { updateInspectionDefect } = useInspectionDefectEntity()

  const [image, imageStatus] = useImage(
    `${CORS}${url}` || '',
    'anonymous',
    'origin',
  )

  const stageRef = useRef<ComponentRef<typeof Stage> | null>(null)

  const [shapes, setShapes] = useState<ShapeConfig[]>([])
  const [paletteOpen, setPaletteOpen] = useState(false)
  const [defectKind, setDefectKind] = useState<InspectionDefectKind>(
    inspectionDefect?.kind || InspectionDefectKind.Minor,
  )
  const [selectedShapeId, setSelectedShapeId] = useState('')
  const [selectedColor, setSelectedColor] = useState<ArrowColors>(
    ArrowColors.Red,
  )
  const [loading, setLoading] = useState(false)

  const color = useMemo(
    () => ({
      shapeIcon: themeGet(`colors.${selectedColor}`)({ theme }),
      pindropIcon: themeGet('colors.danger500')({ theme }),
    }),
    [selectedColor, theme],
  )

  const isTightImage = useMemo(
    () =>
      !!(stageContainerWidth && stageContainerWidth <= BREAKPOINT_WIDTH.SMALL),
    [stageContainerWidth],
  )

  const stageWidth = useMemo(() => {
    if (!image) {
      return 0
    }

    const imageScale = STAGE_HEIGHT / image.height
    const imageWidth = image.width * imageScale
    const imageHeight = image.height * imageScale

    return (STAGE_HEIGHT * imageWidth) / imageHeight
  }, [image])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyboardKey.Backspace && selectedShapeId) {
        setShapes(prevRectangles =>
          prevRectangles.filter(
            prevRectangle => prevRectangle.id !== selectedShapeId,
          ),
        )
      }
    },
    [selectedShapeId],
  )

  // NOTE: Konva lacks keyboard handlers, so we have to implement a workaround for that
  // TODO: add a button in the anchor for deleting the selected shape
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])

  const handleClosePalette = useCallback(() => {
    setPaletteOpen(false)
    setShapes([])
    setSelectedShapeId('')
    setDefectKind(InspectionDefectKind.Minor)
    setSelectedColor(ArrowColors.Red)
  }, [])

  const handleChangeDefectKind = useCallback<
    NonNullable<ComponentProps<typeof RadioGroup>['onChange']>
  >(event => {
    setDefectKind(event.target.value as InspectionDefectKind)
  }, [])

  const handleAddShape = useCallback(() => {
    const newShapeId = uuid()

    const halvedStageWidth = stageWidth / 2
    const halvedShapeWidth = SHAPE_DIMENSIONS.width / 2

    setShapes(prevState => [
      ...prevState,
      {
        ...INITIAL_SHAPE_CONFIG,
        id: newShapeId,
        fill: color.shapeIcon,
        x: halvedStageWidth - halvedShapeWidth,
      },
    ])

    setSelectedShapeId(newShapeId)
  }, [color, stageWidth])

  const handleChangeShape = useCallback(
    (shapeConfig: ShapeConfig, index: number) => {
      const rects = shapes.slice()
      rects[index] = shapeConfig

      setShapes(rects)
    },
    [shapes],
  )

  const handleOpenPalette = useCallback(() => {
    setPaletteOpen(true)
    handleAddShape()
  }, [handleAddShape])

  const handleSaveMarkedDefect = useCallback(async () => {
    if (!inspectionDefect?.id || !(inspectionId || serviceAppointmentId)) {
      return
    }

    setLoading(true)

    // NOTE: remove selected shapes
    setSelectedShapeId('')
    stageRef.current?.getLayers()[0].find('Transformer')[0]?.remove()

    const imageBlobData = (await stageRef.current?.toBlob({
      // NOTE: increased canvas image quality
      pixelRatio: 2,
    })) as Blob

    const file = new File([imageBlobData], `canvas-${inspectionDefect.id}`, {
      type: imageBlobData.type,
    })

    const compressedFile = await Utils.File.compressFile(file)

    const signedFile = await getSignedUrl({
      file: compressedFile,
      type: inspectionId
        ? FileType.InspectionPhoto
        : FileType.ServiceAppointmentPhoto,
      serviceAppointmentId,
      inspectionId,
    })

    if (!signedFile?.data?.fileSign) {
      return
    }

    await updateInspectionDefect.mutate({
      id: inspectionDefect.id,
      kind: inspectionDefect.kind !== defectKind ? defectKind : undefined,
      editedPhotoUrl: signedFile.data.fileSign.publicUrl,
    })

    onClose?.()
    setLoading(false)
  }, [
    inspectionDefect,
    getSignedUrl,
    serviceAppointmentId,
    inspectionId,
    updateInspectionDefect,
    defectKind,
    onClose,
  ])

  if (!url) return null

  return (
    <Modal
      closeButtonAccessory={
        !paletteOpen && (
          <InspectionDefectDropdown
            disabled={loading}
            disabledRemoveDrawings={!inspectionDefect?.editedPhotoUrl}
            onChangeImage={onChangeImage}
            onDelete={onDelete}
            onDownloadImage={onDownloadImage}
            onRemoveDrawings={onRemoveDrawings}
            onShowActivity={onShowActivity}
          />
        )
      }
      isOpen={isOpen}
      overflow="auto"
      title={
        !!inspectionDefect && (
          <Row center>
            Figure: {inspectionDefect.orderNumber}
            <Text action2 ml={1} muted>
              ({inspectionDefect.id.slice(0, 8)})
            </Text>
            {paletteOpen && !isTightImage && (
              <ColorPicker
                disabled={loading}
                gap={4}
                ml={4}
                selectedColor={selectedColor}
                onAddShape={handleAddShape}
                onSelectColor={setSelectedColor}
              />
            )}
          </Row>
        )
      }
      onClose={onClose}
    >
      {imageStatus === ImageLoadingStatus.Failed && (
        <Row center height="630px" justifyCenter width="840px">
          <Text body2>Something went wrong, try reloading the page</Text>
        </Row>
      )}

      {imageStatus === ImageLoadingStatus.Loading && (
        <Row center height="630px" justifyCenter width="840px">
          <Loader size={70} />
        </Row>
      )}

      {imageStatus === ImageLoadingStatus.Loaded && (
        <>
          {paletteOpen && isTightImage && (
            <ColorPicker
              disabled={loading}
              gap={4}
              mb={5}
              ml={4}
              selectedColor={selectedColor}
              onAddShape={handleAddShape}
              onSelectColor={setSelectedColor}
            />
          )}

          <Row relative>
            <Row alignStart gap={4}>
              <StageContainer ref={resizeDetectorRef}>
                <StyledStage
                  height={STAGE_HEIGHT}
                  listening={!loading}
                  ref={stageRef}
                  width={stageWidth}
                >
                  <Layer>
                    <KonvaImage
                      height={STAGE_HEIGHT}
                      image={image}
                      onClick={() => setSelectedShapeId('')}
                    />

                    {shapes.map((shape, index) => (
                      <Shape
                        draggable
                        isSelected={shape.id === selectedShapeId}
                        key={shape.id}
                        shapeProps={shape}
                        onChange={shapeConfig =>
                          handleChangeShape(shapeConfig, index)
                        }
                        onSelect={() => {
                          if (!shape.id) {
                            return
                          }

                          setSelectedShapeId(shape.id)
                        }}
                      />
                    ))}
                  </Layer>
                </StyledStage>
              </StageContainer>

              {isGpsRequired && inspectionDefect?.mapPhotoUrl && (
                <StageContainer width={MAP_STAGE_WIDTH}>
                  {isCompassArrowShowing ? (
                    <>
                      <MapArrowContainer
                        rotate={inspectionDefect.photoDirection || 0}
                      >
                        <ImageBlock url={mapArrowImage} />
                      </MapArrowContainer>

                      <CompassContainer>
                        <ImageBlock url={compassImage} />
                      </CompassContainer>
                    </>
                  ) : (
                    <PindropContainer>
                      <LocationOnIcon
                        style={{ color: color.pindropIcon, fontSize: 30 }}
                      />
                    </PindropContainer>
                  )}

                  <ImageBlock url={inspectionDefect?.mapPhotoUrl || ''} />
                </StageContainer>
              )}

              {loading && (
                <LoaderContainer>
                  <Loader inverse ml={4} size={100} />
                </LoaderContainer>
              )}
            </Row>
          </Row>

          <Row mt={5} width={stageWidth}>
            {!paletteOpen ? (
              <Row fullWidth spaceBetween>
                <Button gap={3} secondary small onClick={onClose}>
                  <ArrowBackIcon />
                  BACK
                </Button>

                <ButtonsContainer gap={7} tight={isTightImage}>
                  <Tooltip
                    PopperProps={{
                      sx: {
                        zIndex: 9999,
                      },
                    }}
                    arrow
                    placement="top-start"
                    title={
                      inspectionDefect?.editedPhotoUrl &&
                      'This button will clear/reset all drawings on the image'
                    }
                  >
                    <Button
                      disabled={!inspectionDefect?.editedPhotoUrl}
                      gap={3}
                      secondary
                      small
                      onClick={onRemoveDrawings}
                    >
                      <CloseIcon />
                      REMOVE ARROWS
                    </Button>
                  </Tooltip>

                  <Button gap={3} small onClick={handleOpenPalette}>
                    <AddIcon />
                    POINT ISSUES
                  </Button>
                </ButtonsContainer>
              </Row>
            ) : (
              <Row center spaceBetween width={1}>
                <RadioGroup
                  value={defectKind}
                  onChange={handleChangeDefectKind}
                >
                  <RadioValuesContainer tight={isTightImage}>
                    <FormControlLabel
                      control={<Radio />}
                      disabled={loading}
                      label={<Text>Minor Defect</Text>}
                      value={InspectionDefectKind.Minor}
                    />

                    <FormControlLabel
                      control={<Radio />}
                      disabled={loading}
                      label={<Text>Major Defect</Text>}
                      value={InspectionDefectKind.Major}
                    />
                  </RadioValuesContainer>
                </RadioGroup>

                <Row gap={6}>
                  <Button
                    danger
                    disabled={loading}
                    small
                    onClick={handleClosePalette}
                  >
                    CANCEL
                  </Button>

                  <Button
                    disabled={loading}
                    small
                    onClick={handleSaveMarkedDefect}
                  >
                    SAVE WITH MARKERS
                  </Button>
                </Row>
              </Row>
            )}
          </Row>
        </>
      )}
    </Modal>
  )
}

export default InspectionDefectModal
