import React, { useCallback, useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'

import { IconButton, Row } from 'Components/UI'

import { useEventBusSubscribe } from 'Hooks'

import EventBus from 'Services/EventBus'

import { Content, ImageContent, StyledModal } from './styles'

type ModalState = {
  isOpen: boolean
  url?: string | null
}

enum Action {
  Open = 'ImagePreview/Open',
}

const rootElement =
  (window && window.document && window.document.getElementById('root')) ??
  undefined

const INITIAL_MODAL_STATE = { isOpen: false, url: null }

function ImagePreview() {
  const [modalState, setModalState] = useState<ModalState>(INITIAL_MODAL_STATE)
  const { isOpen, url } = modalState

  const handleOpen = useCallback(
    (url: string) => setModalState({ url, isOpen: true }),
    [],
  )
  const handleClose = useCallback(() => setModalState(INITIAL_MODAL_STATE), [])

  useEventBusSubscribe(Action.Open, handleOpen)

  if (!url) return null

  return (
    <StyledModal
      appElement={rootElement}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
    >
      <Row height="40px" justifyEnd>
        <IconButton onClick={handleClose}>
          <ClearIcon style={{ color: 'white' }} />
        </IconButton>
      </Row>

      <Content>{!!url && <ImageContent src={url} />}</Content>
    </StyledModal>
  )
}

ImagePreview.open = (url: string) => EventBus.trigger(Action.Open, url)

export default ImagePreview
