import { EImageType } from 'lib-image-conversion'

export const IMAGE_MIMES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/bmp',
  'image/gif',
  'image/webp',
  'image/svg+xml',
]

export const DEFAULT_COMPRESS_IMAGE_OPTIONS = {
  size: 300,
  accuracy: 0.9,
  type: EImageType.JPEG,
  width: 420,
  height: 245,
  scale: 0.6,
}
