import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { Html } from 'react-konva-utils'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { NoteConfig } from 'Types/konva'

import { Input, Row } from 'Components/UI'

const INPUT_OFFSET_Y = 20
const INPUT_OFFSET_X = 15

type Props = {
  config?: NoteConfig
  value?: string
  onChange?: (config: NoteConfig) => void
  onKeyDown?: ComponentProps<typeof Input>['onKeyDown']
  onClick?: ComponentProps<typeof Input>['onClick']
}

export function EditableTextInput({
  config,
  value,
  onChange,
  onKeyDown,
  onClick,
}: Props) {
  const [text, setText] = useState(value || '')
  const theme = useTheme()
  const color = useMemo(
    () => ({
      input: themeGet('colors.danger500Translucent')({ theme }),
    }),
    [theme],
  )

  const groupProps = useMemo(
    () => ({
      ...config,
      ...(config?.x && { x: config.x - INPUT_OFFSET_X }),
      ...(config?.y && { y: config.y - INPUT_OFFSET_Y }),
    }),
    [config],
  )

  const handleChange = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(event => {
    setText(event.target.value)
  }, [])

  const handleBlur = useCallback(() => {
    onChange?.({
      ...config,
      text: text.trim(),
    })
  }, [config, text, onChange])

  return (
    <Html groupProps={groupProps}>
      <Row backgroundColor={color.input}>
        <Input
          autoFocus
          value={text}
          width="70px"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
        />
      </Row>
    </Html>
  )
}
