import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation, useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import RepeatIcon from '@mui/icons-material/Repeat'
import Tooltip from '@mui/material/Tooltip'
import { DateTime } from 'luxon'
import { ShowModalKind } from 'Types/modals'
import Utils from 'Utils'

import {
  DuplicateInspectionDefectModal,
  FileUploadProgressModal,
  Header,
  InspectionDefectList,
  InspectionDefectModal,
  InspectionDropdown,
  MoveInspectionDefectModal,
} from 'Components/Blocks'
import { Button, Column, IconButton, Loader, Row, Text } from 'Components/UI'
import { Span } from 'Components/UI/Text'

import {
  INSPECTION_ROOT_PDF,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'
import { IMAGE_LOADING_TOTAL_STEPS } from 'Constants/serviceAppointments'

import {
  CopyInspectionDefectsDocument,
  MoveInspectionDefectsDocument,
} from 'GraphQL/Admin/TypedDocuments'
import {
  FileType,
  InspectionDefectFragment,
  InspectionDefectRelationKind,
  InspectionDocument,
} from 'GraphQL/Main/TypedDocuments'

import {
  useInspectionDefectEntity,
  useInspectionDefectList,
  useSignFile,
} from 'Hooks'

import { useAppContext } from 'Services/AppContext'
import toast from 'Services/Toast'

function Inspection() {
  const theme = useTheme()
  const { inspectionId } = useParams<ServiceAppointmentsRouteParams>()
  const history = useHistory()
  const { showModal, showConfirmModal } = useAppContext()

  const [inspectionDefectOpen, setInspectionDefectOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [moveOpen, setMoveOpen] = useState(false)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [selectedInspectionDefect, setSelectedInspectionDefect] =
    useState<InspectionDefectFragment | null>(null)
  const [updatingInspectionDefects, setUpdatingInspectionDefects] = useState(
    new Set<string>(),
  )
  const [zipDownloading, setZipDownloading] = useState(false)
  const [loadingStep, setLoadingStep] = useState(0)

  const {
    data: inspectionData,
    loading: inspectionLoading,
    error,
  } = useQuery(InspectionDocument, {
    variables: {
      id: inspectionId!,
    },
    skip: !inspectionId,
    fetchPolicy: 'network-only',
  })

  const inspection = useMemo(() => inspectionData?.inspection, [inspectionData])

  const color = useMemo(
    () => ({
      chevronIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )
  const inspectionText = useMemo(() => {
    if (!inspection) {
      return null
    }

    let text = inspection?.serviceAppointment?.subject || ''
    if (inspection?.serviceAppointment?.appointmentNumber) {
      text += ` | SA number: ${inspection?.serviceAppointment.appointmentNumber}`
    }
    if (inspection.createdAt) {
      text += ` | ${DateTime.fromISO(inspection?.createdAt).toLocaleString()}`
    }
    if (inspection.inspectorUser) {
      text += ` | Inspector: ${Utils.User.getUserIdentifier(
        inspection.inspectorUser,
      )} | Status: ${Utils.Inspection.getStatusText(inspection?.status)}`
    }
    if (inspection.reportWriter) {
      text += ` | Report writer: ${inspection.reportWriter}`
    }

    return text
  }, [inspection])

  const defectPhotoUrl =
    selectedInspectionDefect?.editedPhotoUrl ||
    selectedInspectionDefect?.originalPhotoUrl

  const {
    inspectionDefects,
    inspectionDefectsCount,
    selectedInspectionDefects,
    loading: inspectionDefectsLoading,
    loadingMore,
    canLoadMore,
    loadMore,
    clearSelection,
    toggleSelection,
    loadAll,
    refetch,
  } = useInspectionDefectList({ inspectionId })
  const { updateInspectionDefect, deleteInspectionDefect } =
    useInspectionDefectEntity()
  const [
    moveInspectionDefectsMutation,
    { loading: moveInspectionDefectsLoading },
  ] = useMutation(MoveInspectionDefectsDocument, {
    context: {
      admin: true,
    },
  })
  const [
    duplicateInspectionDefectsMutation,
    { loading: duplicateInspectionDefectsLoading },
  ] = useMutation(CopyInspectionDefectsDocument, {
    context: {
      admin: true,
    },
  })

  const { progress, getSignedUrl, abortUpload } = useSignFile()

  useEffect(() => {
    if (!error) {
      return
    }

    const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [error, history])

  const handleChangeDefectComment = useCallback<
    NonNullable<ComponentProps<typeof InspectionDefectList>['onChangeComment']>
  >(
    async (defectId, value) => {
      setUpdatingInspectionDefects(prevSelected => {
        const updatedSelected = new Set(prevSelected)
        updatedSelected.add(defectId)

        return updatedSelected
      })

      await updateInspectionDefect.mutate({
        id: defectId,
        description: value,
      })

      setUpdatingInspectionDefects(prevSelected => {
        const updatedSelected = new Set(prevSelected)
        updatedSelected.delete(defectId)

        return updatedSelected
      })
    },
    [updateInspectionDefect],
  )

  const handleClickInspectionDefect = useCallback<
    NonNullable<
      ComponentProps<typeof InspectionDefectList>['onClickInspectionDefect']
    >
  >(inspectionDefect => {
    setSelectedInspectionDefect(inspectionDefect)
    setInspectionDefectOpen(true)
  }, [])

  const handleCloseFileUploadModal = useCallback(() => {
    abortUpload()
    setFileUploadOpen(false)
  }, [abortUpload])

  const handleOpenMoveModal = useCallback(() => {
    setMoveOpen(true)
  }, [])

  const handleCloseMoveModal = useCallback(() => {
    setMoveOpen(false)
  }, [])

  const handleOpenDuplicateModal = useCallback(() => {
    setDuplicateOpen(true)
  }, [])

  const handleCloseDuplicateModal = useCallback(() => {
    setDuplicateOpen(false)
  }, [])

  const handleCloseInspectionDefectModal = useCallback(() => {
    setInspectionDefectOpen(false)
  }, [])

  const handleDeleteInspectionDefect = useCallback<
    NonNullable<
      ComponentProps<typeof InspectionDefectList>['onDeleteInspectionDefect']
    >
  >(
    async inspectionDefect => {
      const ok = await deleteInspectionDefect.mutate(inspectionDefect)
      if (!ok) {
        return
      }

      toggleSelection(inspectionDefect.id)
      handleCloseInspectionDefectModal()
      refetch()
    },
    [
      deleteInspectionDefect,
      toggleSelection,
      handleCloseInspectionDefectModal,
      refetch,
    ],
  )

  const handleMoveInspectionDefects = useCallback<
    NonNullable<ComponentProps<typeof MoveInspectionDefectModal>['onMove']>
  >(
    async selectedServiceAppointment => {
      if (selectedInspectionDefects.size < 0 || !selectedServiceAppointment.id)
        return

      try {
        await moveInspectionDefectsMutation({
          variables: {
            serviceAppointmentId: selectedServiceAppointment.id,
            relationKind: InspectionDefectRelationKind.ServiceAppointment,
            inspectionDefectIds: Array.from(selectedInspectionDefects),
          },
        })

        toast.success({
          text: (
            <>
              <Span primary>{selectedInspectionDefects.size}</Span> Media
              pair(s) were successfully moved to the{' '}
              <Span bold>{selectedServiceAppointment.subject}</Span> Opportunity
            </>
          ),
        })

        handleCloseMoveModal()
        clearSelection()
        refetch()
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [
      selectedInspectionDefects,
      refetch,
      clearSelection,
      handleCloseMoveModal,
      moveInspectionDefectsMutation,
    ],
  )

  const handleDuplicateInspectionDefects = useCallback<
    NonNullable<
      ComponentProps<typeof DuplicateInspectionDefectModal>['onDuplicate']
    >
  >(
    async selectedServiceAppointment => {
      if (selectedInspectionDefects.size < 0 || !selectedServiceAppointment?.id)
        return
      try {
        await duplicateInspectionDefectsMutation({
          variables: {
            serviceAppointmentId: selectedServiceAppointment.id,
            relationKind: InspectionDefectRelationKind.ServiceAppointment,
            inspectionDefectIds: Array.from(selectedInspectionDefects),
          },
        })

        toast.success({
          text: (
            <>
              <Span primary>{selectedInspectionDefects.size}</Span> Media
              pair(s) were successfully duplicated to the{' '}
              <Span bold>{selectedServiceAppointment.subject}</Span> service
              appointment
            </>
          ),
        })

        handleCloseDuplicateModal()
        clearSelection()
        refetch()
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [
      selectedInspectionDefects,
      clearSelection,
      refetch,
      handleCloseDuplicateModal,
      duplicateInspectionDefectsMutation,
    ],
  )

  const handleChangeImage = useCallback(
    async (inspectionDefectId: string, files: File[]) => {
      if (!inspectionId || !inspection?.serviceAppointment?.id) return
      setFileUploadOpen(true)
      // TODO: extract multiple files uploading for service appointment photo to a hook
      setLoadingStep(prevState => prevState + 1)

      const file = files[0]

      const compressedFile = await Utils.File.compressFile(file)

      const signedFile = await getSignedUrl({
        file,
        type: FileType.ServiceAppointmentPhoto,
        serviceAppointmentId: inspection.serviceAppointment.id,
        inspectionId,
      })

      setLoadingStep(prevState => prevState + 1)

      const signedCompressedFile = await getSignedUrl({
        file: compressedFile,
        type: FileType.InspectionPhoto,
        serviceAppointmentId: inspection.serviceAppointment.id,
        inspectionId,
      })

      if (
        !signedFile?.data?.fileSign ||
        !signedCompressedFile?.data?.fileSign
      ) {
        return
      }

      const photoTakenAt = await Utils.File.getOriginalDateFromFile(file)

      const newInspectionDefect = await updateInspectionDefect.mutate({
        id: inspectionDefectId,
        originalPhotoUrl: signedFile.data.fileSign.publicUrl,
        compressedOriginalPhotoUrl:
          signedCompressedFile.data.fileSign.publicUrl,
        editedPhotoUrl: null,
        latitude: null,
        longitude: null,
        photoTakenAt,
      })

      setFileUploadOpen(false)
      setLoadingStep(0)

      if (!newInspectionDefect || !inspectionDefectOpen) {
        return
      }

      setSelectedInspectionDefect(newInspectionDefect)
    },
    [
      inspectionId,
      inspection,
      getSignedUrl,
      updateInspectionDefect,
      inspectionDefectOpen,
    ],
  )

  const handleDownloadImage = useCallback(
    async (inspectionDefect?: InspectionDefectFragment) => {
      try {
        await Utils.InspectionDefect.downloadDefectImage(inspectionDefect)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [],
  )

  const handleDownloadImages = useCallback(async () => {
    try {
      setZipDownloading(true)

      const { data } = await loadAll()

      const zipName = inspection?.serviceAppointment?.appointmentNumber || ''
      await Utils.InspectionDefect.downloadZipDefectImages(
        data.inspectionDefectList.rows,
        zipName,
      )

      setZipDownloading(false)
    } catch (error) {
      setZipDownloading(false)

      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [inspection, loadAll])

  const handleShowActivity = useCallback(
    (defectId?: string) => {
      if (!defectId) return

      showModal?.({ kind: ShowModalKind.ActivityFeed, defectId })
    },
    [showModal],
  )

  const handleRemoveDrawings = useCallback(
    async (inspectionDefectId?: string) => {
      if (!inspectionDefectId) return

      const ok = await showConfirmModal?.({
        primary: true,
        mainIcon: <DeleteIcon />,
        children: (
          <Text mr={2}>
            Are you sure you want to remove all drawings on the image?
          </Text>
        ),
        okText: 'REMOVE',
        danger: true,
      })

      if (!ok) return

      const newInspectionDefect = await updateInspectionDefect.mutate({
        id: inspectionDefectId,
        editedPhotoUrl: null,
      })

      if (!newInspectionDefect || !inspectionDefectOpen) {
        return
      }

      setSelectedInspectionDefect(newInspectionDefect)
    },
    [showConfirmModal, inspectionDefectOpen, updateInspectionDefect],
  )

  const handleBack = useCallback(() => {
    history.push(SERVICE_APPOINTMENTS)
  }, [history])

  const loadingProgress = useMemo(() => {
    if (!loadingStep) return progress
    return Utils.ProgressBar.calculateLoadingProgress(
      loadingStep,
      progress,
      IMAGE_LOADING_TOTAL_STEPS,
    )
  }, [loadingStep, progress])

  const handleOpenInspectionReport = useCallback(
    async (includeHeader: boolean) => {
      if (!inspection) return
      window.open(INSPECTION_ROOT_PDF(inspection.id, includeHeader), '_blank')
    },
    [inspection],
  )

  if (
    inspectionLoading ||
    deleteInspectionDefect.loading ||
    (updateInspectionDefect.loading && updatingInspectionDefects.size === 0)
  ) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Header>
        <Row center fullWidth spaceBetween>
          <Row center gap={5} minWidth={0}>
            <IconButton onClick={handleBack}>
              <ChevronLeftIcon style={{ color: color.chevronIcon }} />
            </IconButton>

            {inspectionText && (
              <Tooltip
                arrow
                followCursor
                placement="bottom-start"
                title={inspectionText}
              >
                <Row center minWidth={0}>
                  <Text ellipsis header5 inverse maxWidth="100%">
                    {inspectionText}
                  </Text>
                </Row>
              </Tooltip>
            )}
          </Row>

          <InspectionDropdown
            disabledFloorPlans={inspectionDefects.length === 0}
            inspection={inspection}
            onOpenPdfReport={handleOpenInspectionReport}
          />
        </Row>
      </Header>

      <Column fullHeight minHeight={0} mt={6}>
        <Row
          center
          height="40px"
          justifyContent={
            selectedInspectionDefects.size === 0 ? 'flex-end' : 'space-between'
          }
          mb={6}
          mx={6}
        >
          {selectedInspectionDefects.size > 0 && (
            <Row center>
              <Text action2 mr={2}>
                Selected
              </Text>
              <Text action2 mr={2} primary>
                {selectedInspectionDefects.size}
              </Text>
              <Text action2>media pair(s)</Text>

              <Button ml={3} secondary small onClick={clearSelection}>
                <ClearIcon />
                Remove selection
              </Button>
            </Row>
          )}

          <Row center gap={3}>
            {selectedInspectionDefects.size > 0 && (
              <>
                <IconButton onClick={handleOpenDuplicateModal}>
                  <FileCopyIcon />
                </IconButton>

                <IconButton onClick={handleOpenMoveModal}>
                  <RepeatIcon />
                </IconButton>
              </>
            )}

            <Row center mr={4}>
              <Text mr={2} subheader2>
                FIGURES COUNT:
              </Text>
              <Text primary subheader2>
                {inspectionDefectsCount}
              </Text>
            </Row>

            {!!inspectionDefects.length && (
              <Button
                disabled={zipDownloading}
                small
                onClick={handleDownloadImages}
              >
                DOWNLOAD ALL PHOTOS
                {zipDownloading && <Loader inverse ml={4} />}
              </Button>
            )}

            {/*  <Button
              component={Link}
              small
              to={CREATE_DEFECT_INSPECTION(inspectionId)}
            >
              ADD MEDIA
            </Button> */}
          </Row>
        </Row>

        <InspectionDefectList
          audioUrl={inspection?.audioRecord?.audioUrl}
          canLoadMore={canLoadMore}
          inspectionDefects={inspectionDefects}
          loading={inspectionDefectsLoading}
          loadingMore={loadingMore}
          selectedInspectionDefects={selectedInspectionDefects}
          updatingInspectionDefects={updatingInspectionDefects}
          onChangeComment={handleChangeDefectComment}
          onChangeImage={handleChangeImage}
          onClickInspectionDefect={handleClickInspectionDefect}
          onDeleteInspectionDefect={handleDeleteInspectionDefect}
          onDownloadImage={handleDownloadImage}
          onLoadMore={loadMore}
          onRemoveDrawings={handleRemoveDrawings}
          onShowActivity={handleShowActivity}
          onToggleSelection={toggleSelection}
        />

        {!!defectPhotoUrl && inspectionDefectOpen && (
          <InspectionDefectModal
            inspectionDefect={selectedInspectionDefect}
            inspectionId={inspectionId}
            isCompassArrowShowing={inspection?.isCompassArrowShowing}
            isGpsRequired={inspection?.isGpsRequired}
            isOpen
            serviceAppointmentId={inspection?.serviceAppointment?.id}
            url={defectPhotoUrl}
            onChangeImage={files =>
              handleChangeImage(selectedInspectionDefect.id, files)
            }
            onClose={handleCloseInspectionDefectModal}
            onDelete={() =>
              handleDeleteInspectionDefect(selectedInspectionDefect)
            }
            onDownloadImage={() =>
              handleDownloadImage(selectedInspectionDefect)
            }
            onRemoveDrawings={() =>
              handleRemoveDrawings(selectedInspectionDefect.id)
            }
            onShowActivity={() =>
              handleShowActivity(selectedInspectionDefect.id)
            }
          />
        )}
      </Column>

      <FileUploadProgressModal
        isOpen={fileUploadOpen}
        progress={loadingProgress}
        onClose={handleCloseFileUploadModal}
      />

      <MoveInspectionDefectModal
        isOpen={moveOpen}
        loading={moveInspectionDefectsLoading}
        onClose={handleCloseMoveModal}
        onMove={handleMoveInspectionDefects}
      />

      <DuplicateInspectionDefectModal
        isOpen={duplicateOpen}
        loading={duplicateInspectionDefectsLoading}
        onClose={handleCloseDuplicateModal}
        onDuplicate={handleDuplicateInspectionDefects}
      />
    </Column>
  )
}

export default Inspection
