import React, { useCallback } from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'
import { useLocation } from 'react-router-dom'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FolderIcon from '@mui/icons-material/Folder'
import StarIcon from '@mui/icons-material/Star'
import WorkIcon from '@mui/icons-material/Work'

import { InspectionDefectSubscriptionManager } from 'Components/Blocks/Inspections'
import {
  Column,
  Divider,
  Drawer,
  IconButton,
  Link,
  Tab,
  Tabs,
  Text,
} from 'Components/UI'

import { ADMINS, INSPECTORS, SERVICE_APPOINTMENTS } from 'Constants/paths'

import MainContainer from 'Containers/Main'

import { ChevronsContainer } from './styles'

type Props = {
  route?: RouteConfig
}

function AdminLayout({ route }: Props) {
  const { pathname } = useLocation()

  const [drawerOpen, serDrawerOpen] = React.useState(false)

  const handleOpenDrawer = useCallback(() => {
    serDrawerOpen(true)
  }, [])
  const handleCloseDrawer = useCallback(() => {
    serDrawerOpen(false)
  }, [])

  return (
    <Column fullHeight>
      <Drawer open={drawerOpen} variant="permanent">
        <ChevronsContainer open={drawerOpen}>
          {!drawerOpen ? (
            <IconButton onClick={handleOpenDrawer}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleCloseDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </ChevronsContainer>

        <Divider />

        <Tabs orientation="vertical" value={pathname}>
          <Tab
            component={Link}
            icon={<FolderIcon />}
            label={
              drawerOpen && (
                <Text caption2 left ml={2}>
                  SERVICE APPOINTMENTS
                </Text>
              )
            }
            to={SERVICE_APPOINTMENTS}
            value={
              pathname.includes(SERVICE_APPOINTMENTS)
                ? pathname
                : SERVICE_APPOINTMENTS
            }
          />
          <Tab
            component={Link}
            icon={<WorkIcon />}
            label={
              drawerOpen && (
                <Text caption2 ml={2}>
                  INSPECTORS
                </Text>
              )
            }
            to={INSPECTORS}
            value={pathname.includes(INSPECTORS) ? pathname : INSPECTORS}
          />
          <Tab
            component={Link}
            icon={<StarIcon />}
            label={
              drawerOpen && (
                <Text caption2 ml={2}>
                  ADMINS
                </Text>
              )
            }
            to={ADMINS}
            value={pathname.includes(ADMINS) ? pathname : ADMINS}
          />
        </Tabs>
      </Drawer>

      <MainContainer open={drawerOpen}>
        {renderRoutes(route?.routes)}
      </MainContainer>

      <InspectionDefectSubscriptionManager />
    </Column>
  )
}

export default AdminLayout
