import { Font, StyleSheet } from '@react-pdf/renderer'

import CalibriFont from 'Assets/Fonts/Calibri/Calibri-Regular.ttf'

import { S3_URL } from 'Config'

import { PDF_MAP_ARROW_COORDINATES, PINDROP_COORDINATES } from 'Constants/map'

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: `${S3_URL}/fonts/Inter/Inter-Regular.ttf`,
    },
    {
      src: `${S3_URL}/fonts/Inter/Inter-Medium.ttf`,
      fontWeight: 500,
    },
    {
      src: `${S3_URL}/fonts/Inter/Inter-SemiBold.ttf`,
      fontWeight: 600,
    },
    {
      src: `${S3_URL}/fonts/Inter/Inter-Italic.ttf`,
      fontWeight: 300,
    },
    {
      src: `${S3_URL}/fonts/Inter/Inter-Bold.ttf`,
      fontWeight: 700,
    },
  ],
})
Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: CalibriFont,
    },
  ],
})

export default StyleSheet.create({
  m16: {
    marginLeft: '16px',
  },
  ml8: {
    marginLeft: '8px',
  },
  mt350: {
    marginTop: '350px',
  },
  mt30: {
    marginTop: '30px',
  },
  mt12: {
    marginTop: '12px',
  },
  mt8: {
    marginTop: '12px',
  },
  mt5: {
    marginTop: '5px',
  },
  mt2: {
    marginTop: '2px',
  },
  mb12: {
    marginBottom: '12px',
  },

  lateralPaddingContainer: {
    padding: '0 20px',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  spaceBetween: {
    justifyContent: 'space-between',
  },

  alignStart: {
    alignItems: 'flex-start',
  },

  center: {
    alignItems: 'center',
  },

  alignEnd: {
    alignItems: 'flex-end',
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  underline: {
    textDecoration: 'underline',
  },

  header5: {
    fontSize: '14px',
    fontWeight: 600,
  },
  subheader1: {
    fontSize: '12px',
    fontWeight: 600,
  },
  subheader2: {
    fontSize: '10px',
    fontWeight: 600,
  },
  subheader3: {
    fontSize: '8px',
    fontWeight: 600,
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
  },
  body4: {
    fontSize: '10px',
    fontWeight: 400,
  },
  body5: {
    fontSize: '8px',
    fontWeight: 400,
  },
  caption1: {
    fontSize: '18px',
    fontWeight: 500,
  },
  caption2: {
    fontSize: '10px',
    fontWeight: 500,
  },
  caption3Italic: {
    fontWeight: 300,
  },
  caption3: {
    fontSize: '8px',
    fontWeight: 500,
  },
  action4: {
    fontSize: '10px',
    fontWeight: 700,
  },

  lowerZIndex: {
    zIndex: '-1',
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #000000',
    borderLeft: '1px solid #000000',
  },
  tableCell: {
    borderBottom: '1px solid #000000',
    borderRight: '1px solid #000000',
    padding: '2px',
  },

  container: {
    position: 'relative',
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontSize: '9px',
    lineHeight: 1.3,
    display: 'flex',
    flexDirection: 'column',
  },
  companyLogo: {
    width: '150px',
    height: '150px',
  },
  headerColumn: { width: '200px' },
  headerImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '374px',
    maxWidth: '600px',
  },
  headerImage: {
    objectFit: 'cover',
  },
  mediaImageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    height: '245px',
    width: '420px',
    overflow: 'hidden',
    paddingLeft: '20px',
    paddingRight: '5px',
  },
  mediaImageContent: {
    position: 'relative',
  },
  mediaImageTimestamp: {
    position: 'absolute',
    bottom: '5px',
    right: '15px',
    color: '#eb8d4b',
    fontWeight: 700,
  },
  mediaImage: {
    maxWidth: '100%',
    objectFit: 'cover',
  },
  mapImageContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    minHeight: '155px',
    maxHeight: '155px',
    width: '100%',
    overflow: 'hidden',
  },
  mapArrowContainer: {
    width: '40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transformOrigin: `bottom center ${PDF_MAP_ARROW_COORDINATES.Y}px`,
  },
  mapImage: {
    maxWidth: '100%',
    objectFit: 'cover',
  },
  compassContainer: {
    width: '30px',
    position: 'absolute',
    bottom: '5px',
    right: '0',
  },
  pindropContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    width: '30px',
    height: '30px',
    transform: `translate(-${PINDROP_COORDINATES.X / 2}px, -${
      PINDROP_COORDINATES.Y
    }px)`,
    transformOrigin: `bottom`,
  },
  pindropImage: {
    width: '18px',
    height: '18px',
  },
  mediaDescriptionContainer: {
    height: '245px',
    width: '155px',
    overflow: 'hidden',
  },
  watermarkContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 0.3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  watermark: {
    width: '100%',
    maxWidth: '100%',
  },
  defectDescriptionLink: {
    borderBottom: '1px solid #000000',
    color: '#000000',
    textDecoration: 'none',
  },
  defectDescriptionContainer: {
    width: '490px',
  },
  signImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '450px',
    maxWidth: '210px',
  },
  signImage: {
    objectFit: 'cover',
  },
  projectName: {
    maxHeight: '150px',
  },
  floorPlanImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '750px',
    width: '590px',
  },
  floorPlanImage: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  defectImageDescription: {
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Calibri',
  },
  defectListDescription: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
  },
})
