import React, { useCallback, useMemo } from 'react'

import { useMutation } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import Utils from 'Utils'

import { Text } from 'Components/UI'
import { Span } from 'Components/UI/Text'

import {
  CreateInspectionDefectDocument,
  CreateInspectionDefectMutationVariables,
  DeleteInspectionDefectDocument,
  InspectionDefectFragment,
  UpdateInspectionDefectDocument,
  UpdateInspectionDefectMutationVariables,
} from 'GraphQL/Main/TypedDocuments'
import InspectionUpdater from 'GraphQL/Main/Updaters/Inspection'

import { useAppContext } from 'Services/AppContext'
import toast from 'Services/Toast'

function useInspectionDefectEntity() {
  const { showConfirmModal } = useAppContext()

  const [deleteInspectionDefectMutation, deleteMutationResult] = useMutation(
    DeleteInspectionDefectDocument,
  )
  const [createInspectionDefectMutation, createMutationResult] = useMutation(
    CreateInspectionDefectDocument,
  )
  const [updateInspectionDefectMutation, updateMutationResult] = useMutation(
    UpdateInspectionDefectDocument,
  )

  const deleteMutation = useCallback(
    async (inspectionDefect: InspectionDefectFragment): Promise<boolean> => {
      if (!inspectionDefect) {
        return false
      }

      const ok = await showConfirmModal?.({
        primary: true,
        mainIcon: <DeleteIcon />,
        children: (
          <Text mr={2}>
            Are you sure you want to delete defect order number{' '}
            <Span primary>
              {inspectionDefect.orderNumber} ({inspectionDefect.id.slice(0, 8)})
            </Span>
          </Text>
        ),
        okText: 'DELETE',
        danger: true,
      })

      if (!ok) return false

      try {
        await deleteInspectionDefectMutation({
          variables: {
            id: inspectionDefect.id,
          },
        })

        toast.success({
          text: 'Defect successfully deleted!',
        })

        return true
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
        return false
      }
    },
    [showConfirmModal, deleteInspectionDefectMutation],
  )

  const createMutation = useCallback(
    async (
      variables: CreateInspectionDefectMutationVariables,
    ): Promise<InspectionDefectFragment | null> => {
      try {
        const result = await createInspectionDefectMutation({
          variables,
        })

        const newInspectionDefect = result.data?.createInspectionDefect

        if (!newInspectionDefect) {
          return null
        }

        toast.success({
          text: 'Defect successfully created!',
        })

        return newInspectionDefect
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
        return null
      }
    },
    [createInspectionDefectMutation],
  )

  const updateMutation = useCallback(
    async (variables: UpdateInspectionDefectMutationVariables) => {
      try {
        const result = await updateInspectionDefectMutation({
          variables,
          update: (cache, { data }) =>
            InspectionUpdater.update({
              cache,
              inspectionDefect: data?.updateInspectionDefect,
              id: variables.id,
            }),
        })

        const newInspectionDefect = result.data?.updateInspectionDefect

        if (!newInspectionDefect) {
          return null
        }

        toast.success({
          text: 'Defect successfully updated!',
        })

        return newInspectionDefect
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
        return null
      }
    },
    [updateInspectionDefectMutation],
  )

  const {
    createInspectionDefect,
    updateInspectionDefect,
    deleteInspectionDefect,
  } = useMemo(
    () => ({
      createInspectionDefect: {
        mutate: createMutation,
        loading: createMutationResult.loading,
      },
      updateInspectionDefect: {
        mutate: updateMutation,
        loading: updateMutationResult.loading,
      },
      deleteInspectionDefect: {
        mutate: deleteMutation,
        loading: deleteMutationResult.loading,
      },
    }),
    [
      createMutationResult,
      updateMutationResult,
      deleteMutationResult,
      createMutation,
      updateMutation,
      deleteMutation,
    ],
  )

  return {
    createInspectionDefect,
    updateInspectionDefect,
    deleteInspectionDefect,
  }
}

export default useInspectionDefectEntity
