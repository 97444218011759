import React, { useMemo } from 'react'
import { Group } from 'react-konva'

import { NoteConfig } from 'Types/konva'

import { EditableTextInput } from './EditableTextInput'
import TextInput from './TextInput'

type Props = {
  config?: NoteConfig
  isSelected?: boolean
  isEdited?: boolean
  value?: string
  onSelect?: () => void
  onChange?: (config: NoteConfig) => void
  onDoubleClick?: () => void
}

export function StickyNote({
  config,
  isSelected,
  isEdited,
  value,
  onSelect,
  onChange,
  onDoubleClick,
}: Props) {
  const content = useMemo(
    () =>
      isEdited ? (
        <EditableTextInput
          config={config}
          value={value}
          onChange={onChange}
          onClick={onSelect}
        />
      ) : (
        <TextInput
          config={config}
          isSelected={isSelected}
          value={value}
          onChange={onChange}
          onClick={onSelect}
          onDoubleClick={onDoubleClick}
        />
      ),
    [isEdited, config, isSelected, value, onChange, onSelect, onDoubleClick],
  )

  return <Group>{content}</Group>
}
