import * as paths from 'Constants/paths'

import Pdf from 'Containers/Pages/Pdf'

export default [
  {
    path: paths.INSPECTION_ROOT_PDF(),
    exact: true,
    component: Pdf.InspectionReport,
  },
  {
    path: paths.SERVICE_APPOINTMENT_ROOT_PDF(),
    exact: true,
    component: Pdf.ServiceAppointmentReportPage,
  },
]
