import React, { useCallback } from 'react'
import { DayPickerProps } from 'react-day-picker'

import { SizeProps, StyledPicker } from './styles'

type Props = DayPickerProps &
  SizeProps & {
    value?: Date
    onChange?: (pickedDate: Date) => void
  }

function DatePicker({
  value,
  captionLayout,
  fixedWeeks = true,
  onChange,
  ...rest
}: Props) {
  const handleSelect = useCallback(
    (_: Date | undefined, selectedDay: Date) => {
      onChange?.(selectedDay)
    },
    [onChange],
  )

  return (
    <StyledPicker
      {...rest}
      captionLayout={captionLayout}
      fixedWeeks={fixedWeeks}
      mode="single"
      modifiersClassNames={{
        selected: 'rdp-day_single_selected',
      }}
      selected={value}
      onSelect={handleSelect}
    />
  )
}

DatePicker.defaultProps = {
  numberOfMonths: 1,
} as Props

export default DatePicker
