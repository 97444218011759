import compact from 'lodash/compact'

import { UserFragment, UserProfileFragment } from 'GraphQL/Main/TypedDocuments'

function getFullName(profile?: UserProfileFragment | null) {
  const firstName = profile?.firstName || ''
  const lastName = profile?.lastName || ''
  return compact([firstName, lastName]).join(' ')
}

function getUserIdentifier(user?: UserFragment | null) {
  return (
    getFullName(user?.profile) ||
    user?.primaryEmailCredential?.email ||
    user?.id
  )
}

export default {
  getUserIdentifier,
  getFullName,
}
