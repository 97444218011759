import styled, { css } from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

type ScrollContainerProps = LayoutProps &
  SpaceProps & {
    shadowy?: boolean
  }

const scrollContainerShadowyCss = ({ shadowy }: ScrollContainerProps) =>
  shadowy &&
  css`
    box-shadow: rgba(26, 26, 26, 0.16) 0px -17px 17px -17px inset;
  `

export const ScrollContainer = styled.div<ScrollContainerProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  flex: 1;
  border-radius: 8px;

  ${layout}
  ${space}

  ${scrollContainerShadowyCss}
`
