import React from 'react'

import { Text } from 'Components/UI'

function NoRowsOverlay() {
  return (
    <Text action2 color="neutral100" nowrap>
      No data yet
    </Text>
  )
}

export default NoRowsOverlay
