import Async from './async'
import Errors from './errors'
import File from './file'
import Form from './form'
import Image from './image'
import Inspection from './inspection'
import InspectionDefect from './inspectionDefect'
import ProgressBar from './progressBar'
import ServiceAppointment from './serviceAppointment'
import String from './string'
import User from './user'

export default {
  InspectionDefect,
  Inspection,
  Async,
  Image,
  User,
  Form,
  Errors,
  ServiceAppointment,
  String,
  File,
  ProgressBar,
}
