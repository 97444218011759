import { createContext, useContext } from 'react'

import { ShowConfirmModalParams, ShowModalParams } from 'Types/modals'

import { UserFragment } from 'GraphQL/Main/TypedDocuments'

type AppContextValue = {
  me?: UserFragment
  showConfirmModal?: (params?: ShowConfirmModalParams) => Promise<boolean>
  showModal?: (modal: ShowModalParams) => void
}

const AppContext = createContext<AppContextValue>({})

function useAppContext() {
  return useContext(AppContext)
}

export { AppContext, useAppContext }
