import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

import DefectDescriptionLinks from './DefectDescriptionLinks'

import styles from '../styles'

type Props = {
  audioTranscription?: string | null
  inspectionDefects?: Array<InspectionDefectFragment>
  descriptionHidden?: boolean
}

function MinorDefects({
  inspectionDefects,
  descriptionHidden,
  audioTranscription,
}: Props) {
  return (
    <View
      break
      style={[styles.mt12, styles.mb12, styles.lateralPaddingContainer]}
    >
      <Text style={styles.header5}>MINOR DEFECTS:</Text>

      {!descriptionHidden && (
        <Text style={[styles.caption2, styles.mt12]}>
          According to AS 4349.1, clause 4.2.4.2, minor defects are common to
          most properties and may include minor blemishes, corrosion, cracking,
          weathering, general deterioration, unevenness, and physical damage to
          materials and finishes. It is expected that defects of this type would
          be rectified as part of a normal ongoing maintenance.
        </Text>
      )}

      <View style={styles.mt12}>
        <DefectDescriptionLinks
          audioTranscription={audioTranscription}
          inspectionDefects={inspectionDefects}
        />
      </View>
    </View>
  )
}

export default MinorDefects
