import { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { filesize } from 'filesize'

import { MAX_FILE_SIZE, UPLOAD_FILES_COUNT_LIMIT } from 'Constants/file'

import toast from 'Services/Toast'

type Props = (
  | { acceptAudioOnly?: true; acceptImageOnly?: never }
  | { acceptImageOnly?: true; acceptAudioOnly?: never }
) & {
  disabledClick?: boolean
  onDropSuccess?: (files: File[]) => void
}

function useFileDropzone({
  acceptImageOnly,
  acceptAudioOnly,
  disabledClick,
  onDropSuccess,
}: Props) {
  const [dragOver, setDragOver] = useState(false)

  const handleDrop = useCallback(
    (files: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        toast.error({
          text: `Maximum allowed file size is ${filesize(MAX_FILE_SIZE)}`,
        })
        setDragOver(false)
        return
      }
      if (files.length > UPLOAD_FILES_COUNT_LIMIT) {
        toast.error({
          text: `You cannot upload more than ${UPLOAD_FILES_COUNT_LIMIT} files at a time`,
        })
        setDragOver(false)
        return
      }

      onDropSuccess?.(files)
    },
    [onDropSuccess],
  )

  const handleDragEnter = useCallback(() => {
    setDragOver(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setDragOver(false)
  }, [])

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    accept: {
      ...(acceptImageOnly && { 'image/*': [] }),
      ...(acceptAudioOnly && { 'audio/*': [] }),
    },
    maxSize: MAX_FILE_SIZE,
    noClick: disabledClick,
    onDrop: handleDrop,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
    onDropAccepted: handleDragLeave,
  })

  return {
    dragOver,
    getRootProps,
    getInputProps,
    openDropzone,
  }
}

export default useFileDropzone
