import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'
import { MarginProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'

import { LargePlayer } from 'Components/Blocks'
import { Button, Column, IconButton } from 'Components/UI'

import { useFileDropzone } from 'Hooks'

type Props = MarginProps & {
  url?: string | null
  uploadDisabled?: boolean
  onChange?: (files: File[]) => void
}

function AudioUploader({ url, uploadDisabled, onChange, ...rest }: Props) {
  const theme = useTheme()
  const color = useMemo(
    () => ({
      restoreIcon: themeGet('colors.neutral400')({ theme }),
    }),
    [theme],
  )

  const { getInputProps, openDropzone } = useFileDropzone({
    onDropSuccess: onChange,
    acceptAudioOnly: true,
  })

  return (
    <Column {...rest} center>
      <input {...getInputProps()} />

      <Column width={1}>
        {url ? (
          <LargePlayer
            playButtonAccessory={
              <IconButton disabled={uploadDisabled} onClick={openDropzone}>
                <SettingsBackupRestoreIcon
                  style={{ fontSize: 56, color: color.restoreIcon }}
                />
              </IconButton>
            }
            url={url}
          />
        ) : (
          <Button disabled={uploadDisabled} onClick={openDropzone}>
            ADD AUDIO
          </Button>
        )}
      </Column>
    </Column>
  )
}

export default AudioUploader
