import React, { ComponentProps, useCallback } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

import Pagination from 'Components/Blocks/DataGrid/Pagination'
import NoRowsOverlay from 'Components/Blocks/Tables/NoRowsOverlay'
import TableHeader from 'Components/Blocks/Tables/TableHeader'
import { Loader, Row } from 'Components/UI'

import { SortInputOrder } from 'GraphQL/Admin/TypedDocuments'
import {
  ServiceAppointmentFragment,
  SortInput,
} from 'GraphQL/Main/TypedDocuments'

import { HEADER_CELLS } from './constants'
import DataRow from './TableRow'

import { Container, StyledTable, TableCard, TableOverlay } from '../styles'

type Props = {
  data: ServiceAppointmentFragment[]
  loading?: boolean
  page?: number
  pageSize?: number
  totalPages?: number
  sort?: SortInput
  onChangeSort?: (sort: SortInput) => void
  onChangePageSize?: ComponentProps<typeof Pagination>['onChangePageSize']
  onChangePage?: ComponentProps<typeof Pagination>['onChangePage']
  onClickRow?: (inspectionId: string) => void
  onServiceAppointmentClick?: (serviceAppointmentId: string) => void
}

export default function InspectorServiceAppointmentTable({
  data = [],
  totalPages,
  page,
  onChangePage,
  pageSize,
  onChangePageSize,
  loading,
  onChangeSort,
  sort,
  onClickRow,
  onServiceAppointmentClick,
}: Props) {
  const { ref: resizeDetectorRef, height: tableContainerHeight } =
    useResizeDetector()

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc =
        sort?.column === property && sort?.order === SortInputOrder.Asc

      onChangeSort?.({
        column: property,
        order: isAsc ? SortInputOrder.Desc : SortInputOrder.Asc,
      })
    },
    [onChangeSort, sort?.column, sort?.order],
  )

  return (
    <TableCard>
      <Container ref={resizeDetectorRef}>
        <TableContainer
          sx={{
            height: `${tableContainerHeight || 0}px`,
          }}
        >
          <StyledTable size="small" stickyHeader>
            <TableHeader
              headerCells={HEADER_CELLS}
              sort={sort}
              onChangeSort={handleRequestSort}
            />
            {loading ? (
              <TableOverlay>
                <Loader size={40} />
              </TableOverlay>
            ) : (
              // TODO: fix
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {data.length ? (
                  <TableBody>
                    {data?.map(row => (
                      <DataRow
                        key={row.id}
                        row={row}
                        onClickRow={onClickRow}
                        onServiceAppointmentClick={() =>
                          onServiceAppointmentClick?.(row.id)
                        }
                      />
                    ))}
                    <TableRow sx={{ height: '100%' }} />
                  </TableBody>
                ) : (
                  <TableOverlay>
                    <NoRowsOverlay />
                  </TableOverlay>
                )}
              </>
            )}
          </StyledTable>
        </TableContainer>
      </Container>
      <Row borderTop display="flex" justifyEnd minHeight="52px">
        <Pagination
          loading={loading}
          page={page}
          pageSize={pageSize}
          totalPages={totalPages}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </Row>
    </TableCard>
  )
}
