import React, {
  ComponentProps,
  ComponentRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { Shape as KonvaShape, Transformer } from 'react-konva'

import { ShapeConfig } from 'konva/lib/Shape'

import { SHAPE_COORDINATES } from 'Constants/konva'

type Props = {
  shapeProps: ShapeConfig
  isSelected?: boolean
  draggable?: boolean
  onSelect?: () => void
  onChange?: (config: ShapeConfig) => void
}

function Shape({
  shapeProps,
  isSelected,
  draggable,
  onSelect,
  onChange,
}: Props) {
  const shapeRef = useRef<ComponentRef<typeof KonvaShape> | null>(null)
  const transformerRef = useRef<ComponentRef<typeof Transformer> | null>(null)

  useEffect(() => {
    if (!isSelected || !transformerRef.current || !shapeRef.current) {
      return
    }
    // NOTE: need to attach transformer manually
    transformerRef.current?.nodes([shapeRef.current])
    transformerRef.current?.getLayer()?.batchDraw()
  }, [isSelected])

  const handleInitiateShape = useCallback<
    NonNullable<ComponentProps<typeof KonvaShape>['sceneFunc']>
  >((context, shape) => {
    context.beginPath()
    context.moveTo(SHAPE_COORDINATES.start_x, SHAPE_COORDINATES.start_y)
    context.lineTo(SHAPE_COORDINATES.body_x, SHAPE_COORDINATES.start_y)
    context.lineTo(SHAPE_COORDINATES.body_x, SHAPE_COORDINATES.top_y) // NOTE: Top Arrow Corner
    context.lineTo(SHAPE_COORDINATES.head_x, SHAPE_COORDINATES.head_y) // NOTE: Arrowhead
    context.lineTo(SHAPE_COORDINATES.body_x, SHAPE_COORDINATES.bottom_y) // NOTE: Bottom Arrow Corner
    context.lineTo(SHAPE_COORDINATES.body_x, SHAPE_COORDINATES.body_y)
    context.lineTo(SHAPE_COORDINATES.start_x, SHAPE_COORDINATES.body_y)
    context.closePath()

    context.fillStrokeShape(shape)
  }, [])

  // NOTE: limit resize
  const handleLimitShapeSize = useCallback<
    NonNullable<ComponentProps<typeof Transformer>['boundBoxFunc']>
  >((oldBox, newBox) => {
    if (newBox.width >= 250 || newBox.height >= 70) {
      return oldBox
    }
    return newBox
  }, [])

  return (
    <>
      <KonvaShape
        {...shapeProps}
        draggable={draggable}
        ref={shapeRef}
        sceneFunc={handleInitiateShape}
        onClick={onSelect}
        onDragEnd={e => {
          onChange?.({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTap={onSelect}
      />
      {isSelected && (
        // TODO: At this moment, there is no slightest idea how to style Anchors correctly
        <Transformer boundBoxFunc={handleLimitShapeSize} ref={transformerRef} />
      )}
    </>
  )
}

export default Shape
