import React, { useCallback, useMemo, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation } from '@apollo/client'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Switch, Tooltip } from '@mui/material'
import Utils from 'Utils'

import { Button, Loader, Modal, Row } from 'Components/UI'

import {
  ServiceAppointmentFragment,
  UpdateServiceAppointmentDocument,
} from 'GraphQL/Admin/TypedDocuments'
import { FileType } from 'GraphQL/Main/TypedDocuments'

import { useFileDropzone, useSignFile } from 'Hooks'

import toast from 'Services/Toast'

import FileUploadProgressModal from '../FileUploadProgress'

type Props = {
  serviceAppointment?: ServiceAppointmentFragment
  isOpen?: boolean
  onClose?: () => void
  onOpenPdfReport?: (includeHeader: boolean) => void
}

function ServiceAppointmentPDFGenerationModal({
  serviceAppointment,
  isOpen,
  onClose,
  onOpenPdfReport,
}: Props) {
  const theme = useTheme()
  const {
    progress,
    loading: fileLoading,
    getSignedUrl,
    abortUpload,
  } = useSignFile()

  const [hoverSignatureButton, setHoverSignatureButton] = useState(false)
  const [includeHeader, setIncludeHeader] = useState(false)

  const [
    updateServiceAppointmentMutation,
    { loading: updateServiceAppointmentLoading },
  ] = useMutation(UpdateServiceAppointmentDocument, {
    context: {
      admin: true,
    },
  })

  const handleOpenInspectionReport = useCallback(async () => {
    onOpenPdfReport?.(!includeHeader)
  }, [includeHeader, onOpenPdfReport])

  const handleUploadSignature = useCallback(
    async (files: File[]) => {
      if (!serviceAppointment) {
        return
      }

      try {
        const file = files[0]
        const signedFile = await getSignedUrl({
          file,
          type: FileType.ServiceAppointmentPhoto,
          serviceAppointmentId: serviceAppointment.id,
        })
        if (!signedFile?.data?.fileSign) {
          return
        }

        await updateServiceAppointmentMutation({
          variables: {
            id: serviceAppointment.id,
            signUrl: signedFile.data.fileSign.publicUrl,
          },
        })

        toast.success({
          text: `Signature successfully added!`,
        })
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [serviceAppointment, updateServiceAppointmentMutation, getSignedUrl],
  )

  const { openDropzone } = useFileDropzone({
    onDropSuccess: handleUploadSignature,
    acceptImageOnly: true,
  })

  const handleLeaveMouse = useCallback(async () => {
    setHoverSignatureButton(false)
  }, [])

  const handleEnterMouse = useCallback(async () => {
    setHoverSignatureButton(true)
  }, [])

  const handleCloseFileUploadModal = useCallback(() => {
    abortUpload()
  }, [abortUpload])

  const color = useMemo(
    () => ({
      checkIcon: themeGet('colors.success400')({ theme }),
    }),
    [theme],
  )

  const signatureButtonContent = useMemo(() => {
    if (serviceAppointment?.signUrl && hoverSignatureButton) {
      return 'UPLOAD NEW SIGNATURE IMAGE'
    }

    if (serviceAppointment?.signUrl) {
      return (
        <Row gap={4}>
          SIGNATURE UPLOADED{' '}
          <CheckCircleIcon style={{ color: color.checkIcon }} />
        </Row>
      )
    }

    return 'UPLOAD SIGNATURE IMAGE'
  }, [color, serviceAppointment, hoverSignatureButton])

  const handleToggleHeader = useCallback(() => {
    setIncludeHeader(prevState => !prevState)
  }, [])

  return (
    <Modal isOpen={isOpen} title="Open PDF" onClose={onClose}>
      <Tooltip
        PopperProps={{
          sx: {
            zIndex: 9999,
          },
        }}
        arrow
        placement="top"
        title={
          !serviceAppointment &&
          'The Work Order must be marked as Complete in Salesforce to export the PDF with header'
        }
      >
        <Row>
          <Button
            disabled={
              updateServiceAppointmentLoading ||
              fileLoading ||
              !serviceAppointment
            }
            mt={4}
            secondary
            onClick={handleToggleHeader}
          >
            <Switch checked={includeHeader} />
            INCLUDE HEADER
          </Button>
        </Row>
      </Tooltip>

      {includeHeader && (
        <Button
          disabled={updateServiceAppointmentLoading || fileLoading}
          secondary
          onClick={openDropzone}
          onMouseEnter={handleEnterMouse}
          onMouseLeave={handleLeaveMouse}
        >
          {signatureButtonContent}

          {(updateServiceAppointmentLoading || fileLoading) && (
            <Loader ml={4} />
          )}
        </Button>
      )}

      <Button
        disabled={
          includeHeader &&
          (!serviceAppointment?.signUrl ||
            updateServiceAppointmentLoading ||
            fileLoading)
        }
        mt={4}
        onClick={handleOpenInspectionReport}
      >
        {!includeHeader ? 'OPEN' : 'SIGN AND OPEN'}
      </Button>

      <Button mt={4} secondary onClick={onClose}>
        BACK
      </Button>

      <FileUploadProgressModal
        isOpen={fileLoading}
        progress={progress}
        onClose={handleCloseFileUploadModal}
      />
    </Modal>
  )
}

export default ServiceAppointmentPDFGenerationModal
