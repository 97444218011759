import Utils from 'Utils'

import isEmpty from 'lodash/isEmpty'

import { ServiceAppointmentStatus } from 'GraphQL/Admin/TypedDocuments'

import { ServiceAppointmentFilters } from 'Services/Store/serviceAppointments'

function mapStatusesToOption(statuses?: ServiceAppointmentStatus[]) {
  if (!statuses?.length) return []

  return statuses.map(status => ({
    label: Utils.ServiceAppointment.getStatusText(status),
    value: status,
  }))
}

function getActiveFiltersCount(filters?: ServiceAppointmentFilters): number {
  if (!filters) return 0

  return Object.values(filters).filter(filterValue => !isEmpty(filterValue))
    .length
}

export { getActiveFiltersCount, mapStatusesToOption }
