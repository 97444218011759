import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { InspectionFragment } from 'GraphQL/Main/TypedDocuments'

import styles from '../styles'

type Props = {
  inspection?: InspectionFragment
}

function PropertyDescriptions({ inspection }: Props) {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      {/* PROPERTY DESCRIPTION */}
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.header5}>PROPERTY DESCRIPTION</Text>
      </View>
      <Text style={[styles.caption2, styles.mt12]}>Property Type:</Text>
      <Text style={styles.caption2}>
        {inspection?.propertyType ? inspection.propertyType : <>&mdash;</>}
      </Text>
      <Text style={[styles.caption2, styles.mt12]}>Building Type:</Text>
      <Text style={styles.caption2}>
        {inspection?.buildingType ? inspection.buildingType : <>&mdash;</>}
      </Text>

      {/* INSPECTION RESTRICTIONS  */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.center,
          styles.mt12,
        ]}
      >
        <Text style={styles.header5}>INSPECTION RESTRICTIONS</Text>
      </View>
      <View style={[styles.column, styles.mt12]}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            {inspection?.restrictions
              ? inspection.restrictions
              : 'None noted at the time of the inspection.'}
          </Text>
        </View>
      </View>

      {/* SAFETY ISSUES */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.center,
          styles.mt12,
        ]}
      >
        <Text style={styles.header5}>SAFETY ISSUES</Text>
      </View>
      <View style={[styles.column, styles.mt12]}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Please refer to ‘Major Defects’ for any Safety Issues related to
            building defects.
          </Text>
        </View>
      </View>

      <View style={[styles.column, styles.mt12]}>
        <Text style={styles.caption2}>
          <Text style={[styles.caption2, styles.underline]}>
            Important Note:{' '}
          </Text>
          Per AS 4349.0 Clause 4.2.f.2, the report shall identify any observed
          item that may constitute a present or imminent serious safety hazard.
        </Text>
      </View>

      {/* PROPERTY SITES INSPECTED  */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.center,
          styles.mt12,
        ]}
      >
        <Text style={styles.header5}>PROPERTY SITES INSPECTED</Text>
      </View>
      <View style={[styles.column, styles.mt12]}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            {inspection?.propertySitesInspected ? (
              inspection.propertySitesInspected
            ) : (
              <>&mdash;</>
            )}
          </Text>
        </View>
      </View>

      <View style={[styles.column, styles.mt12]}>
        <Text style={styles.caption2}>
          <Text style={[styles.action4, styles.underline]}>
            Important Note:{' '}
          </Text>
          The areas listed above are a broad indication of the areas inspected.
          Damage and/or defects may be present and not detected in areas where
          the inspection was limited, obstructed, or access was not gained.
          Within these areas, some further restrictions may have been present
          restricting or preventing our inspection. If any recommendation has
          been made within this report to gain access to areas, gain further
          access to areas, or any area has been noted as being at “high risk”
          due to limited access, then further access must be gained. We strongly
          recommend that such access be gained to enable a more complete report
          to be submitted.
        </Text>
      </View>

      <View style={[styles.column, styles.mt12]}>
        <Text style={styles.caption2}>
          <Text style={[styles.action4, styles.underline]}>
            Drainage - Surface Water:{' '}
          </Text>
          Not inspected
        </Text>
        <Text style={styles.caption2}>
          Important Note: The site should be monitored during heavy rain to
          determine whether the existing drains can cope. If they cannot cope,
          then additional drains may be required.
        </Text>
      </View>

      <View style={[styles.column, styles.mt12]}>
        <Text style={styles.caption2}>
          <Text style={[styles.action4, styles.underline]}>Services: </Text>
          Not inspected
        </Text>
        <Text style={styles.caption2}>
          Important Note: In regard to plumbing or electrical, it should be
          noted that we are not plumbers or electricians and no comments are
          made to electrical or plumbing. We recommend that a qualified
          contractor be engaged to make comment on any matter dealing with
          plumbing or electrical issues.
        </Text>
      </View>
    </View>
  )
}

export default PropertyDescriptions
