import React from 'react'

import { MarginProps, WidthProps } from 'styled-system'

import { Container, Progress } from './styles'

type Props = MarginProps &
  WidthProps & {
    value?: number
    transparent?: boolean
  }

function ProgressBar({ value, ...rest }: Props) {
  return (
    <Container {...rest}>
      <Progress value={value || 0} />
    </Container>
  )
}

export default ProgressBar
