import React from 'react'
import { Redirect } from 'react-router'

import * as paths from 'Constants/paths'

import { AdminLayout } from 'Containers/Layout'
import Admin from 'Containers/Pages/Admin'
import Common from 'Containers/Pages/Common'
import NotFound from 'Containers/Pages/NotFound'

function adminRoutes() {
  return {
    path: paths.ROOT,
    component: AdminLayout,
    routes: [
      {
        path: paths.ROOT,
        exact: true,
        component: () => <Redirect to={paths.SERVICE_APPOINTMENTS} />,
      },
      {
        path: paths.SERVICE_APPOINTMENTS,
        exact: true,
        component: Admin.ServiceAppointments,
      },
      {
        path: paths.SERVICE_APPOINTMENT_ROOT(),
        exact: true,
        component: Admin.ServiceAppointment,
      },
      {
        path: paths.CREATE_DEFECT_SERVICE_APPOINTMENT(),
        exact: true,
        component: Common.CreateServiceAppointmentDefect,
      },
      {
        path: paths.INSPECTION_ROOT(),
        exact: true,
        component: Admin.Inspection,
      },
      {
        path: paths.CREATE_DEFECT_INSPECTION(),
        exact: true,
        component: Common.CreateInspectionDefect,
      },
      {
        path: paths.INSPECTORS,
        exact: true,
        component: Admin.Inspectors,
      },
      {
        path: paths.ADMINS,
        exact: true,
        component: Admin.Admins,
      },
      {
        path: paths.FLOOR_PLANS_ROOT(),
        exact: true,
        component: Admin.FloorPlans,
      },
      { component: NotFound },
    ],
  }
}

export default adminRoutes
