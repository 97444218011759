import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import {
  COMPANY_ADDRESS,
  COMPANY_CONSTRUCTION,
  COMPANY_NAME,
} from 'Constants/company'

import styles from '../styles'

function PurposeCopyrightContent() {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.caption2}>{COMPANY_NAME} was commissioned by</Text>
        <Text style={styles.action4}>{COMPANY_CONSTRUCTION}</Text>
        <Text style={styles.caption2}>
          to carry out a pre-construction condition inspection and report in
          accordance with AS.4349.0 for the
        </Text>

        <View style={[styles.row, styles.center]}>
          <Text style={styles.action4}>{COMPANY_ADDRESS} </Text>
          <Text style={styles.caption2}>project</Text>
        </View>
      </View>

      {/* PURPOSE */}
      <View
        style={[
          styles.column,
          styles.mt30,
          styles.justifyCenter,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>PURPOSE OF THIS REPORT</Text>
      </View>

      <Text style={[styles.caption2, styles.mt12]}>
        This is a visual pre-construction inspection in accordance with AS
        4349.0 and is intended to record the pre-construction condition of the
        property inspected and the surrounding areas. This is not a structural
        report and will not provide comment on the structural integrity or
        design of the inspected property; however it does include a photographic
        record of the main defects and condition of the property visible at the
        time of the inspection. The report does not provide any comment as to
        the cause of any defect and the condition of the property noted and is
        intended to be used to determine if change has occurred
        post-construction and if so, to what extent. This report and included
        photographs will be retained for use in/ or during post-construction
        condition surveys for the project.
      </Text>

      {/* LIMITATIONS */}
      <View
        style={[
          styles.column,
          styles.mt12,
          styles.justifyCenter,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>LIMITATIONS</Text>
      </View>

      <Text style={[styles.caption2, styles.mt12]}>
        In accordance with AS 4349.0:
      </Text>

      <View style={styles.column}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            A visual only inspection may be of limited use to the client. In
            addition to a visual inspection, to thoroughly inspect the readily
            accessible areas of the property, further testing may be required
            whenever necessary. Such testing is not included as part of this
            inspection and dilapidation report.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            This report does not include the inspection and assessment of items
            or matters outside the scope of the requested inspection and report.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            This report does not include the inspection and assessment of items
            or matters that do not fall within the consultant’s direct
            expertise.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            The inspection only covers the readily accessible areas of the
            property and does not include areas, which were inaccessible or
            obstructed at the time of inspection. Obstructions are defined as
            any condition or physical limitation which inhibits or prevents
            inspection.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Australian Standard Inspection of Buildings, Part 1: Property
            Inspections – Residential buildings recognises that a standard
            property inspection report is not a warranty or an insurance policy
            against problems developing with the building in the future.
          </Text>
        </View>
      </View>

      {/* EXCLUSIONS */}
      <View
        style={[
          styles.column,
          styles.mt12,
          styles.justifyCenter,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>EXCLUSIONS</Text>
      </View>

      <Text style={[styles.caption2, styles.mt12]}>
        The client acknowledges that this report does not cover or deal with:
      </Text>

      <View style={styles.column}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            solving or providing costs for any rectification or repair work;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            the structural design or adequacy of any element of construction;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            detection of wood destroying insects such as termites and wood
            borers;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            the operation of fireplaces and chimneys;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            any building services or appliances on the property;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            any swimming pools and associated pool equipment or spa baths and
            spa equipment or the like;
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            whether the ground on which the building rests has been filled, is
            liable to subside, swell or shrink, is subject to landslip or tidal
            inundation, or if it is flood prone.
          </Text>
        </View>
      </View>

      {/* COPYRIGHT */}
      <View
        style={[
          styles.column,
          styles.mt12,
          styles.justifyCenter,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>COPYRIGHT</Text>
      </View>

      <Text style={[styles.caption2, styles.mt12]}>
        All related council authorities, Acciona, and TfNSW are granted a
        perpetual non-exclusive license to make use of the copyright in all
        images supplied of council assets within this report, including the
        right to make copies available to third parties as though they were
        council images.
      </Text>
    </View>
  )
}

export default PurposeCopyrightContent
