import { StoreonModule } from 'storeon'

import { TableView } from 'Constants/serviceAppointments'

import { ServiceAppointmentStatus } from 'GraphQL/Main/TypedDocuments'

const KEY = 'serviceAppointments'

export type ServiceAppointmentFilters = {
  statuses?: ServiceAppointmentStatus[]
}

type StateValues = {
  view: TableView
  dateRange?: {
    from: string
    to: string
  }
  filters?: ServiceAppointmentFilters
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'serviceAppointments/update': Partial<StateValues>
  'serviceAppointments/clear': void
}

export const INITIAL_STATE: StateValues = {
  view: TableView.Grid,
  filters: undefined,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))

  store.on('serviceAppointments/update', (state, values) => ({
    [KEY]: {
      ...state[KEY],
      ...values,
    },
  }))

  store.on('serviceAppointments/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

export { KEY, module }
export type { Events, State }
