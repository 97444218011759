import React from 'react'

export type ShowConfirmModalParams = {
  title?: string
  children?: string | React.ReactNode
  okText?: string
  cancelText?: string
  mainIcon?: React.ReactNode
  danger?: boolean
  warning?: boolean
  success?: boolean
  primary?: boolean
}

export enum ShowModalKind {
  ActivityFeed = 1,
}

export type ShowModalParams = {
  kind: ShowModalKind.ActivityFeed
  defectId: string
}
