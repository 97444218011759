import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Utils from 'Utils'

import {
  FileUploadProgressModal,
  Header,
  ImageUploader,
} from 'Components/Blocks'
import { Column, IconButton, Loader, Row, Text } from 'Components/UI'

import {
  INSPECTION_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'
import { IMAGE_LOADING_TOTAL_STEPS } from 'Constants/serviceAppointments'

import { FileType, InspectionDefectDocument } from 'GraphQL/Main/TypedDocuments'

import { useInspectionDefectEntity, useSignFile } from 'Hooks'

import toast from 'Services/Toast'

function InspectionDefectEditor() {
  const history = useHistory()
  const theme = useTheme()
  const { defectId } = useParams<ServiceAppointmentsRouteParams>()

  const { progress, getSignedUrl, abortUpload } = useSignFile()

  const { updateInspectionDefect } = useInspectionDefectEntity()

  const [modalOpen, setModalOpen] = useState(false)
  const [loadingStep, setLoadingStep] = useState(0)

  const color = useMemo(
    () => ({
      chevronIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )

  const {
    data: inspectionDefectData,
    loading: inspectionDefectLoading,
    error: inspectionDefectError,
  } = useQuery(InspectionDefectDocument, {
    variables: {
      id: defectId!,
    },
    skip: !defectId,
  })

  const inspectionDefect = useMemo(
    () => inspectionDefectData?.inspectionDefect,
    [inspectionDefectData],
  )

  useLayoutEffect(() => {
    if (!defectId) {
      toast.error({ text: 'Defect does not exist' })
      history.push(SERVICE_APPOINTMENTS)
    }
  }, [history, defectId])

  useLayoutEffect(() => {
    if (!inspectionDefectError) {
      return
    }

    const [graphQLError] = Utils.Errors.getGraphQLErrors(inspectionDefectError)
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [inspectionDefectError, history])

  const handleChangeImage = useCallback(
    async (files: File[]) => {
      if (
        !inspectionDefect?.serviceAppointmentId ||
        !inspectionDefect.inspectionId
      )
        return

      setModalOpen(true)
      // TODO: extract multiple files uploading for service appointment photo to a hook
      setLoadingStep(prevState => prevState + 1)

      const file = files[0]

      const compressedFile = await Utils.File.compressFile(file)

      const signedFile = await getSignedUrl({
        file,
        type: FileType.InspectionPhoto,
        serviceAppointmentId: inspectionDefect.serviceAppointmentId,
        inspectionId: inspectionDefect.inspectionId,
      })

      setLoadingStep(prevState => prevState + 1)

      const signedCompressedFile = await getSignedUrl({
        file: compressedFile,
        type: FileType.InspectionPhoto,
        serviceAppointmentId: inspectionDefect.serviceAppointmentId,
        inspectionId: inspectionDefect.inspectionId,
      })

      if (
        !signedFile?.data?.fileSign ||
        !signedCompressedFile?.data?.fileSign
      ) {
        return
      }

      const photoTakenAt = await Utils.File.getOriginalDateFromFile(file)

      await updateInspectionDefect.mutate({
        id: inspectionDefect.id,
        originalPhotoUrl: signedFile.data.fileSign.publicUrl,
        compressedOriginalPhotoUrl:
          signedCompressedFile.data.fileSign.publicUrl,
        editedPhotoUrl: null,
        latitude: null,
        longitude: null,
        photoTakenAt,
      })

      setModalOpen(false)
      setLoadingStep(0)
    },
    [inspectionDefect, getSignedUrl, updateInspectionDefect],
  )

  const handleCloseModal = useCallback(() => {
    abortUpload()
    setModalOpen(false)
  }, [abortUpload])

  const handleBack = useCallback(() => {
    if (!inspectionDefect || !inspectionDefect.inspectionId) return
    history.push(INSPECTION_ROOT(inspectionDefect.inspectionId))
  }, [history, inspectionDefect])

  const loadingProgress = useMemo(() => {
    if (!loadingStep) return progress
    return Utils.ProgressBar.calculateLoadingProgress(
      loadingStep,
      progress,
      IMAGE_LOADING_TOTAL_STEPS,
    )
  }, [loadingStep, progress])

  if (inspectionDefectLoading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Header>
        <Row center gap={5} minWidth={0}>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon style={{ color: color.chevronIcon }} />
          </IconButton>

          <Row center minWidth={0}>
            <Text ellipsis header5 inverse maxWidth="100%">
              Edit media | Figure: {inspectionDefect?.orderNumber} (
              {inspectionDefect?.id.slice(0, 8)})
            </Text>
          </Row>
        </Row>
      </Header>

      <Column center mt={8}>
        <Column width="328px">
          <ImageUploader
            url={
              inspectionDefect?.editedPhotoUrl ||
              inspectionDefect?.originalPhotoUrl
            }
            onChange={handleChangeImage}
          />
          {/* {inspectionDefect && inspectionDefect.inspectionId && (
            <Button
              component={Link}
              mt={10}
              to={CREATE_DEFECT_INSPECTION(inspectionDefect.inspectionId)}
            >
              ADD NEXT MEDIA
            </Button>
          )} */}
        </Column>
      </Column>

      <FileUploadProgressModal
        isOpen={modalOpen}
        progress={loadingProgress}
        onClose={handleCloseModal}
      />
    </Column>
  )
}

export default InspectionDefectEditor
