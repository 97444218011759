import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { InspectionDefectFragment } from 'GraphQL/Main/TypedDocuments'

import DefectDescriptionLinks from './DefectDescriptionLinks'

import styles from '../styles'

type Props = {
  audioTranscription?: string | null
  inspectionDefects?: Array<InspectionDefectFragment>
  descriptionHidden?: boolean
}

function MajorDefects({
  audioTranscription,
  inspectionDefects,
  descriptionHidden,
}: Props) {
  return (
    <View style={[styles.mt12, styles.lateralPaddingContainer]}>
      <Text style={styles.header5}>MAJOR DEFECTS:</Text>
      {!descriptionHidden && (
        <>
          <Text style={[styles.caption2, styles.mt12]}>
            According to AS 4349.0 clause 1.3.9, a major defect is defined as “A
            defect of sufficient magnitude where rectification has to be carried
            out in order to avoid unsafe conditions, loss of utility or further
            deterioration of the property”. Per AS 4349.1 clause 4.2.4.1, where
            a major defect is mentioned in the report, it should be clearly
            described; including a general statement as to any observed minor
            defects and an explanation given as to why it is a major defect,
            along with its specific locations. AusDilaps, in consideration of
            ‘Acceptance Criteria’ as defined by AS 4349.0 Clause 2.4, and its
            years of experience and professional knowledge of staff, identifies
            and describes the major defects at the time of inspections based on
            one of the following reasons:
          </Text>
          <View style={[styles.row, styles.justifyCenter]}>
            <View style={[styles.table, styles.mt12, { width: '350px' }]}>
              <View style={styles.row}>
                <View
                  style={[
                    styles.tableCell,
                    styles.row,
                    styles.justifyCenter,
                    { width: '25%' },
                  ]}
                >
                  <Text style={styles.action4}>Reason Code</Text>
                </View>
                <View style={[styles.tableCell, { width: '75%' }]}>
                  <Text style={styles.action4}>
                    Reason for Identifying Major Defect
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View
                  style={[
                    styles.tableCell,
                    styles.row,
                    styles.justifyCenter,
                    { width: '25%' },
                  ]}
                >
                  <Text style={styles.caption2}>1</Text>
                </View>
                <View style={[styles.tableCell, { width: '75%' }]}>
                  <Text style={styles.caption2}>
                    1 Large area is affected by the defect
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View
                  style={[
                    styles.tableCell,
                    styles.row,
                    styles.justifyCenter,
                    { width: '25%' },
                  ]}
                >
                  <Text style={styles.caption2}>2</Text>
                </View>
                <View style={[styles.tableCell, { width: '75%' }]}>
                  <Text style={styles.caption2}>
                    Defect has substantially affected the serviceability of
                    element
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View
                  style={[
                    styles.tableCell,
                    styles.row,
                    styles.justifyCenter,
                    { width: '25%' },
                  ]}
                >
                  <Text style={styles.caption2}>3</Text>
                </View>
                <View style={[styles.tableCell, { width: '75%' }]}>
                  <Text style={styles.caption2}>
                    Defect presents risks of harm to people or damage to
                    propertie(s)
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </>
      )}

      <View style={styles.mt12}>
        <DefectDescriptionLinks
          audioTranscription={audioTranscription}
          inspectionDefects={inspectionDefects}
        />
      </View>
    </View>
  )
}

export default MajorDefects
