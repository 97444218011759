import ReactPlayer, { ReactPlayerProps } from 'react-player'

import styled from 'styled-components'
import { system } from 'styled-system'

export const backgroundColor = system({
  color: {
    property: 'backgroundColor',
    scale: 'colors',
  },
})

type StyledPlayerProps = ReactPlayerProps & {
  color?: string
}

export const StyledPlayer = styled(ReactPlayer)<StyledPlayerProps>`
  audio::-webkit-media-controls-panel {
    ${backgroundColor}
  }
`
