import React, { useCallback } from 'react'

import { Image, ImagePreview } from 'Components/Blocks'
import { Button, Column, Text } from 'Components/UI'

import { useFileDropzone } from 'Hooks'

import { DragContainer, ImageContainer } from './styles'

type Props = {
  url?: string | null
  uploadDisabled?: boolean
  onChange?: (files: File[]) => void
}

function ImageUploader({ url, uploadDisabled, onChange }: Props) {
  const { dragOver, getInputProps, getRootProps, openDropzone } =
    useFileDropzone({
      onDropSuccess: onChange,
      acceptImageOnly: true,
    })

  const handleOpenGallery = useCallback(() => {
    ImagePreview.open(url || '')
  }, [url])

  return (
    <Column center>
      <ImageContainer>
        {url ? (
          <Image isClickable url={url} onClick={handleOpenGallery} />
        ) : (
          <DragContainer {...getRootProps()} dark={dragOver}>
            {!uploadDisabled && <input {...getInputProps()} />}

            {dragOver ? (
              <Text body3 heading>
                RELEASE THE PHOTO TO ADD IT
              </Text>
            ) : (
              <Text body3 heading>
                CLICK HERE OR DRAG & DROP PHOTO TO UPLOAD
              </Text>
            )}
          </DragContainer>
        )}
      </ImageContainer>

      {!!url && (
        <Button
          disabled={uploadDisabled}
          mt={8}
          secondary
          width={1}
          onClick={openDropzone}
        >
          REPLACE THE PHOTO
        </Button>
      )}
    </Column>
  )
}

export default ImageUploader
