import Utils from 'Utils'

import { ServiceAppointmentStatus } from 'GraphQL/Main/TypedDocuments'

export const STATUS_OPTIONS = [
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.Inspected,
    ),
    value: ServiceAppointmentStatus.Inspected,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.PartiallyInspected,
    ),
    value: ServiceAppointmentStatus.PartiallyInspected,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.Revert,
    ),
    value: ServiceAppointmentStatus.Revert,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.InProgress,
    ),
    value: ServiceAppointmentStatus.InProgress,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.PendingBooking,
    ),
    value: ServiceAppointmentStatus.PendingBooking,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.AccessExhausted,
    ),
    value: ServiceAppointmentStatus.AccessExhausted,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.DoorKnock,
    ),
    value: ServiceAppointmentStatus.DoorKnock,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.Dispatched,
    ),
    value: ServiceAppointmentStatus.Dispatched,
  },
  {
    label: Utils.ServiceAppointment.getStatusText(
      ServiceAppointmentStatus.Unknown,
    ),
    value: ServiceAppointmentStatus.Unknown,
  },
]

export const PURE_FORM_INITIAL_VALUES = {
  statuses: [],
}
