import Envs from 'Services/Envs'

export const APP_ENV = Envs.get('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_STAGING = APP_ENV === 'staging'
export const IS_DEVELOPMENT = APP_ENV === 'development'
export const S3_URL = Envs.get('S3_URL')

export const APP = {
  NAME: 'ausdilaps-web',
}

export const CORS = Envs.get('CORS')

const API_SSL_ENABLED = Envs.get('API_SSL_ENABLED') === 'true'
const API_PROTOCOL = API_SSL_ENABLED ? 'https' : 'http'
const SOCKET_PROTOCOL = API_SSL_ENABLED ? 'wss' : 'ws'

export const API = {
  URL: `${API_PROTOCOL}://${Envs.get('API_URL')}`,
  WS: `${SOCKET_PROTOCOL}://${Envs.get('API_URL')}`,
}

export const STORE = {
  KEY: `${APP.NAME}/store`,
}
