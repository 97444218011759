import { Stage, StageProps } from 'react-konva'

import styled from 'styled-components'
import { overflow, OverflowProps } from 'styled-system'

type StyledStageProps = StageProps & OverflowProps

const StyledStage = styled(Stage)<StyledStageProps>`
  border-radius: 8px;
  overflow: hidden;

  ${overflow}
`

export default StyledStage
