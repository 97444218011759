import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { CORS } from 'Config'

import { COMPANY_EMAIL, COMPANY_PHONE } from 'Constants/company'

import { InspectionFragment } from 'GraphQL/Main/TypedDocuments'

import Utils from 'Utils'

import styles from '../styles'

type Props = {
  inspection?: InspectionFragment
}

function SignatureContent({ inspection }: Props) {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      {/* RESIDENT NOTED CONCERNS */}
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.header5}>RESIDENT NOTED CONCERNS</Text>
      </View>
      <Text style={[styles.caption2, styles.mt12]}>
        Any noted conditions within this section of the report are raised by
        residents only and may not be able to be verified by AusDilaps with
        photographic evidence or may not qualify under our standard of defects.
      </Text>

      <View style={[styles.column, styles.mt12]}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          {/* TODO: not sure about field relevance */}
          <Text style={[styles.caption2, styles.ml8]}>
            None noted at the time of the Inspection.
          </Text>
        </View>
      </View>

      {/* EXPLANATION OF REVISIONS */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.mt12,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>EXPLANATION OF REVISIONS</Text>
      </View>
      <View style={[styles.column, styles.mt12]}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            {inspection?.explanationOfRevisions ? (
              inspection.explanationOfRevisions
            ) : (
              <>&mdash;</>
            )}
          </Text>
        </View>
      </View>

      {/* ENGINEER SIGN-OFF */}
      <View
        style={[
          styles.column,
          styles.justifyCenter,
          styles.mt12,
          styles.center,
        ]}
      >
        <Text style={styles.header5}>ENGINEER SIGN-OFF</Text>
      </View>
      <Text style={[styles.caption2, styles.mt12]}>
        I, {Utils.User.getUserIdentifier(inspection?.inspectorUser)}
        {!!inspection?.inspectorCredentials &&
          `/ ${inspection?.inspectorCredentials}`}{' '}
        of Australian Dilapidations, have attended the property addressed on
        this report and conducted a full visual assessment per scope identified
        in the report.
      </Text>
      {!!inspection?.inspectorCredentials && (
        <>
          <Text style={[styles.caption2, styles.mt12]}>Regards,</Text>
          <Text style={[styles.caption2, styles.mt12]}>
            {inspection.inspectorCredentials}
          </Text>
        </>
      )}

      <View style={[styles.column, styles.mt350]}>
        <Text style={styles.caption2}>Yours faithfully</Text>
        {!!inspection?.signUrl && (
          <View
            style={[styles.signImageContainer, styles.mt8, styles.lowerZIndex]}
          >
            <Image
              src={`${CORS}${inspection.signUrl}`}
              style={styles.signImage}
            />
          </View>
        )}
        {/* TODO: not sure about field relevance */}
        <Text style={[styles.caption2, styles.mt8]}>Michael Burford</Text>
        <Text style={[styles.action4, styles.mt12]}>
          AUSTRALIAN DILAPIDATIONS
        </Text>
        <Text style={styles.caption2}>Office: {COMPANY_PHONE}</Text>
        <Text style={styles.caption2}>Email: {COMPANY_EMAIL}</Text>
      </View>
    </View>
  )
}

export default SignatureContent
