import React, { useCallback } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import Utils from 'Utils'
import validate from 'validate.js'

import { Button, Column, InputField, Modal } from 'Components/UI'

import {
  InviteUserByEmailDocument,
  UserRole,
} from 'GraphQL/Admin/TypedDocuments'

import toast from 'Services/Toast'

import { StyledForm } from './styles'

type Props = {
  title: string
  isOpen?: boolean
  onClose?: () => void
}

enum Field {
  Email = 'email',
}

type FormValues = {
  [Field.Email]: string
}

export const CONSTRAINTS = {
  [Field.Email]: {
    presence: true,
    email: {
      message: 'should be correct',
    },
  },
}

function InviteInspectorModal({ title, isOpen, onClose }: Props) {
  const [inviteUserByEmailMutation] = useMutation(InviteUserByEmailDocument, {
    context: {
      admin: true,
    },
  })

  const submitInviteUserByEmail = useCallback(
    async (values: FormValues) => {
      try {
        const result = await inviteUserByEmailMutation({
          variables: { ...values, role: UserRole.User },
        })

        if (!result.data?.inviteUserByEmail.ok) {
          return
        }

        toast.success({
          text: 'Invitation successfully sent!',
        })
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }

      onClose?.()
    },
    [inviteUserByEmailMutation, onClose],
  )

  const formValidation = useCallback(
    (values: FormValues) => validate(values, CONSTRAINTS),
    [],
  )

  const renderForm = useCallback(
    ({ handleSubmit, submitting }) => (
      <StyledForm onSubmit={handleSubmit}>
        <InputField
          displayError
          label="Email"
          mb={4}
          name={Field.Email}
          type="text"
        />
        <Button
          disabled={submitting}
          type="submit"
          width={1}
          onClick={handleSubmit}
        >
          SEND INVITE
        </Button>
        <Button secondary width={1} onClick={onClose}>
          CANCEL
        </Button>
      </StyledForm>
    ),
    [onClose],
  )
  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <Column center fullHeight justifyCenter>
        <Form
          render={renderForm}
          validate={formValidation}
          onSubmit={submitInviteUserByEmail}
        />
      </Column>
    </Modal>
  )
}

export default InviteInspectorModal
