export enum TableView {
  Grid = 'grid',
  Calendar = 'calendar',
}

export enum ServiceAppointmentColumn {
  AppointmentNumber = 'appointmentNumber',
  ScheduledStartAt = 'scheduledStartAt',
  InspectionDate = 'inspectionDate',
  CreatedAt = 'createdAt',
  Subject = 'subject',
  InternalReference = 'internalReference',
  Inspection = 'inspection',
  TotalDefectCount = 'totalDefectCount',
  Status = 'status',
  ReportWriter = 'reportWriter',
  Inspector = 'inspector',
  ProjectName = 'projectName',
}

export const IMAGE_LOADING_TOTAL_STEPS = 2
