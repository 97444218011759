import { DateTime } from 'luxon'
import Utils from 'Utils'

import { UserFragment } from 'GraphQL/Admin/TypedDocuments'

import { InspectorColumnsType } from './types'

function processInspectorsData(
  inspectors?: UserFragment[],
): InspectorColumnsType[] {
  if (!inspectors?.length) {
    return []
  }

  return inspectors.map(inspector => {
    return {
      id: inspector.id,
      createdAt: !inspector.createdAt
        ? null
        : DateTime.fromISO(inspector.createdAt).toLocaleString(),
      name: Utils.User.getFullName(inspector.profile) || null,
      email:
        !inspector || !inspector?.primaryEmailCredential?.email
          ? Utils.User.getUserIdentifier(inspector)
          : inspector.primaryEmailCredential?.email,
      user: inspector,
    }
  })
}

export { processInspectorsData }
