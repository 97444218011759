import { ApolloCache } from '@apollo/client'

import { UserFragment } from 'GraphQL/Admin/TypedDocuments'

function evict(cache: ApolloCache<any>, userId?: UserFragment['id']) {
  if (!userId) return

  // TODO: use object name from TypedDocuments. somehow
  cache.evict({ id: `User:${userId}` })
  cache.gc()
}

const UserUpdater = {
  evict,
}

export default UserUpdater
