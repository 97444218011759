import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Container = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${themeGet('space.6')}px;
  min-height: ${themeGet('sizes.header')}px;
  transition: all ease ${themeGet('defaultTransitionTime')};
  background-color: ${themeGet('colors.primary500')};
`
