import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { gap, GapProps } from 'Services/Styles'

export const StyledForm = styled.form<GapProps>`
  display: flex;
  flex-direction: column;
  padding: 0 ${themeGet('space.4')}px;

  ${gap}
`

export const FiltersCount = styled.div`
  background-color: ${themeGet('colors.primary500')};
  color: ${themeGet('colors.white')};
  font-size: 11px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
`
