import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import TuneIcon from '@mui/icons-material/Tune'
import { Fade, Popper } from '@mui/material'

import { SelectField, Text } from 'Components/UI'
import { Button, LinkButton } from 'Components/UI/Buttons'
import Card from 'Components/UI/Card'
import { Column, Row } from 'Components/UI/Flex'

import { ServiceAppointmentStatus } from 'GraphQL/Main/TypedDocuments'

import { useOnClickOutside, useServiceAppointmentsState } from 'Hooks'

import { PURE_FORM_INITIAL_VALUES, STATUS_OPTIONS } from './constants'
import { FiltersCount, StyledForm } from './styles'
import { getActiveFiltersCount, mapStatusesToOption } from './utils'

enum Field {
  // Inspector = 'inspector',
  Statuses = 'statuses',
}

type FormValues = {
  // TODO: add multiple user selection on the BE, rather than using one ID at a time
  // [Field.Inspector]?: string
  [Field.Statuses]?: Array<{ label: string; value: ServiceAppointmentStatus }>
}

function ServiceAppointmentFilter() {
  const { state, updateFilters } = useServiceAppointmentsState()

  const [popperOpen, setPopperOpen] = useState(false)

  const buttonRef = useRef(null)
  const popperRef = useRef(null)

  const formInitialValues = useMemo(
    () => ({
      // TODO: add multiple user selection on the BE, rather than using one ID at a time
      // [Field.Inspector]: state?.filters?.inspector,
      [Field.Statuses]: mapStatusesToOption(state.filters?.statuses),
    }),
    [state],
  )

  const filtersCount = useMemo(
    () => getActiveFiltersCount(state.filters),
    [state],
  )

  const handleOpenerClose = useCallback(() => {
    setPopperOpen(false)
  }, [])

  const handleOpenerOpen = useCallback(() => {
    setPopperOpen(true)
  }, [])

  const submit = useCallback(
    (values: FormValues) => {
      const filters = {
        ...values,
        statuses: values[Field.Statuses]?.map(status => status.value),
      }

      updateFilters(filters)
      handleOpenerClose()
    },
    [handleOpenerClose, updateFilters],
  )

  const renderForm = useCallback(
    ({ handleSubmit, submitting, form }: FormRenderProps<FormValues>) => (
      <StyledForm gap={4} onSubmit={handleSubmit}>
        <Row borderBottom py={4}>
          <Text body3>Filters</Text>
        </Row>
        {/* TODO: add multiple user selection on the BE, rather than using one ID at a time */}
        {/*   <Column alignEnd borderBottom gap={2} pb={4}>
          <LinkButton onClick={() => form.change(Field.Inspector, undefined)}>
            Reset
          </LinkButton>
          <InputField
            displayError
            label="Inspector"
            name={Field.Inspector}
            small
            type="text"
            width={1}
          />
        </Column> */}

        <Column alignEnd borderBottom gap={2} pb={4}>
          <LinkButton onClick={() => form.change(Field.Statuses, [])}>
            Reset
          </LinkButton>
          <SelectField
            disablePortal
            label="SA Status"
            multiple
            name={Field.Statuses}
            options={STATUS_OPTIONS}
            small
            width={1}
          />
        </Column>

        <Row pb={4} spaceBetween>
          <Button
            secondary
            small
            onClick={() => form.reset(PURE_FORM_INITIAL_VALUES)}
          >
            RESET ALL
          </Button>

          <Button disabled={submitting} small type="submit">
            APPLY NOW
          </Button>
        </Row>
      </StyledForm>
    ),
    [],
  )

  useOnClickOutside(popperRef, handleOpenerClose)

  return (
    <Column relative>
      <Button
        gap={3}
        ref={buttonRef}
        secondary
        small
        onClick={handleOpenerOpen}
      >
        <TuneIcon />
        FILTERS
        <FiltersCount>{filtersCount}</FiltersCount>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        open={popperOpen}
        placement="bottom-end"
        ref={popperRef}
        style={{
          zIndex: 10000,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Card width="356px">
              <Form
                initialValues={formInitialValues}
                render={renderForm}
                onSubmit={submit}
              />
            </Card>
          </Fade>
        )}
      </Popper>
    </Column>
  )
}

export default ServiceAppointmentFilter
