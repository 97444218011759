import React from 'react'
import ReactQuill from 'react-quill'

import Caption from 'Components/UI/Forms/Caption'

import { StyleWrapper } from './styles'

const MODULES = {
  toolbar: ['bold', 'underline'],
}

type Props = {
  value?: string
  disabled?: boolean
  caption?: string
  danger?: boolean
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
}

function TextEditor({
  value,
  disabled,
  caption,
  danger,
  onChange,
  onBlur,
}: Props) {
  return (
    <StyleWrapper>
      <ReactQuill
        modules={MODULES}
        readOnly={disabled}
        value={value}
        onBlur={(_selection, _source, editor) => onBlur?.(editor.getHTML())}
        onChange={(value, _, source) => {
          if (source !== 'user') return
          onChange?.(value)
        }}
      />

      <Caption caption={caption} danger={danger} mt={2} />
    </StyleWrapper>
  )
}

export default TextEditor
