import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation } from '@apollo/client'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Tooltip } from '@mui/material'
import Switch from '@mui/material/Switch'
import Utils from 'Utils'

import {
  FileUploadProgressModal,
  ServiceAppointmentPDFGenerationModal,
} from 'Components/Blocks/Modals'
import {
  Column,
  IconButton,
  MenuItem,
  MenuList,
  Row,
  Text,
} from 'Components/UI'

import {
  ServiceAppointmentFragment,
  UpdateServiceAppointmentDocument,
} from 'GraphQL/Admin/TypedDocuments'
import { FileType } from 'GraphQL/Main/TypedDocuments'

import { useFileDropzone, useSignFile } from 'Hooks'

import toast from 'Services/Toast'

type Props = {
  serviceAppointment?: ServiceAppointmentFragment
  onOpenPdfReport?: (includeHeader: boolean) => void
}

function ServiceAppointmentDropdown({
  serviceAppointment,
  onOpenPdfReport,
}: Props) {
  const { progress, getSignedUrl, abortUpload } = useSignFile()

  const theme = useTheme()
  const iconButtonRef = useRef(null)
  const color = useMemo(
    () => ({
      userIcon: themeGet('colors.neutral900')({ theme }),
      checkIcon: themeGet('colors.success400')({ theme }),
    }),
    [theme],
  )

  const [
    updateServiceAppointmentMutation,
    { loading: updateServiceAppointmentLoading },
  ] = useMutation(UpdateServiceAppointmentDocument, {
    context: {
      admin: true,
    },
  })

  const [menuListOpen, setMenuListOpen] = useState(false)
  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [pdfGenerationOpen, setPdfGenerationOpen] = useState(false)
  const [gpsEnabled, setGpsEnabled] = useState(
    !!serviceAppointment?.isGpsRequired,
  )
  const [compassArrowEnabled, setCompassArrowEnabled] = useState(
    !!serviceAppointment?.isCompassArrowShowing,
  )

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])

  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])

  const handleOpenPdfGeneration = useCallback(() => {
    setPdfGenerationOpen(true)
    handleCloseMenuList()
  }, [handleCloseMenuList])

  const handleClosePdfGeneration = useCallback(() => {
    setPdfGenerationOpen(false)
  }, [])

  const handleCloseFileUploadModal = useCallback(() => {
    abortUpload()
    setFileUploadOpen(false)
  }, [abortUpload])

  const handleToggleGps = useCallback(async () => {
    if (!serviceAppointment) {
      return
    }

    const newGpsEnabled = !gpsEnabled
    setGpsEnabled(newGpsEnabled)

    if (!newGpsEnabled && compassArrowEnabled) {
      setCompassArrowEnabled(false)
    }

    try {
      await updateServiceAppointmentMutation({
        variables: {
          id: serviceAppointment.id,
          isGpsRequired: newGpsEnabled,
          isCompassArrowShowing:
            !newGpsEnabled && compassArrowEnabled ? false : undefined,
        },
      })

      if (!newGpsEnabled) {
        toast.info({
          text: `Opportunity GPS is disabled`,
        })
      } else {
        toast.success({
          text: `Opportunity GPS is enabled`,
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [
    serviceAppointment,
    compassArrowEnabled,
    gpsEnabled,
    updateServiceAppointmentMutation,
  ])

  const handleToggleCompassArrow = useCallback(async () => {
    if (!serviceAppointment) {
      return
    }

    const newCompassArrowEnabled = !compassArrowEnabled
    setCompassArrowEnabled(newCompassArrowEnabled)

    try {
      await updateServiceAppointmentMutation({
        variables: {
          id: serviceAppointment.id,
          isCompassArrowShowing: newCompassArrowEnabled,
        },
      })

      if (!newCompassArrowEnabled) {
        toast.info({
          text: `Compass arrow is disabled`,
        })
      } else {
        toast.success({
          text: `Compass arrow is enabled`,
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [
    serviceAppointment,
    compassArrowEnabled,
    updateServiceAppointmentMutation,
  ])

  const handleAddWatermark = useCallback(
    async (files: File[]) => {
      if (!serviceAppointment) {
        return
      }

      try {
        setFileUploadOpen(true)
        handleCloseMenuList()

        const file = files[0]
        const signedFile = await getSignedUrl({
          file,
          type: FileType.ServiceAppointmentWatermark,
          serviceAppointmentId: serviceAppointment.id,
        })
        if (!signedFile?.data?.fileSign) {
          return
        }

        await updateServiceAppointmentMutation({
          variables: {
            id: serviceAppointment.id,
            watermarkUrl: signedFile.data.fileSign.publicUrl,
          },
        })

        toast.success({
          text: `Watermark successfully added!`,
        })

        setFileUploadOpen(false)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [
      serviceAppointment,
      getSignedUrl,
      handleCloseMenuList,
      updateServiceAppointmentMutation,
    ],
  )

  const compassArrowTooltip = useMemo(() => {
    if (!serviceAppointment) {
      return 'The Work Order must be marked as Complete in Salesforce to export the PDF with compass arrow'
    }

    if (!gpsEnabled) {
      return 'You need to enable GPS first'
    }

    return null
  }, [serviceAppointment, gpsEnabled])

  const { openDropzone: watermarkOpenDropzone } = useFileDropzone({
    onDropSuccess: handleAddWatermark,
    acceptImageOnly: true,
  })

  return (
    <Column>
      <IconButton ref={iconButtonRef} onClick={handleOpenMenuList}>
        <MoreVertIcon style={{ color: color.userIcon, fontSize: 30 }} />
      </IconButton>
      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        <Tooltip
          arrow
          placement="left"
          title={
            !serviceAppointment &&
            'The Work Order must be marked as Complete in Salesforce to export the PDF with GPS'
          }
        >
          <Row>
            <MenuItem
              active
              disabled={updateServiceAppointmentLoading || !serviceAppointment}
              width={1}
              onClick={handleToggleGps}
            >
              <Switch checked={gpsEnabled} />

              <Text caption2 color="primary400">
                GPS
              </Text>
            </MenuItem>
          </Row>
        </Tooltip>

        <Tooltip arrow placement="left" title={compassArrowTooltip}>
          <Row>
            <MenuItem
              active
              disabled={
                updateServiceAppointmentLoading ||
                !serviceAppointment ||
                !gpsEnabled
              }
              width={1}
              onClick={handleToggleCompassArrow}
            >
              <Switch checked={compassArrowEnabled} />

              <Text caption2 color="primary400">
                COMPASS ARROW
              </Text>
            </MenuItem>
          </Row>
        </Tooltip>
        <Tooltip
          arrow
          placement="left"
          title={
            !serviceAppointment &&
            'The Work Order must be marked as Complete in Salesforce to export the PDF with watermark'
          }
        >
          <Row>
            <MenuItem
              active
              disabled={!serviceAppointment}
              width={1}
              onClick={watermarkOpenDropzone}
            >
              <UploadFileIcon />
              <Text mr={4}>
                {serviceAppointment?.watermarkUrl
                  ? 'CHANGE WATERMARK'
                  : 'ADD WATERMARK'}
              </Text>

              {!!serviceAppointment?.watermarkUrl && (
                <CheckCircleIcon
                  style={{
                    color: color.checkIcon,
                  }}
                />
              )}
            </MenuItem>
          </Row>
        </Tooltip>

        <Row>
          <MenuItem active width={1} onClick={handleOpenPdfGeneration}>
            <PictureAsPdfIcon />
            OPEN PDF
          </MenuItem>
        </Row>
      </MenuList>
      <FileUploadProgressModal
        isOpen={fileUploadOpen}
        progress={progress}
        onClose={handleCloseFileUploadModal}
      />
      {pdfGenerationOpen && (
        <ServiceAppointmentPDFGenerationModal
          isOpen
          serviceAppointment={serviceAppointment}
          onClose={handleClosePdfGeneration}
          onOpenPdfReport={onOpenPdfReport}
        />
      )}
    </Column>
  )
}

export default ServiceAppointmentDropdown
