import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

type MainProps = {
  open?: boolean
}

const mainOpenCss = ({ open }: MainProps) =>
  open &&
  css`
    margin-left: ${themeGet('sizes.sidebarOpened')}px;
  `

const MainContainer = styled.main<MainProps>`
  height: 100%;
  margin-left: ${themeGet('sizes.sidebarCollapsed')}px;
  transition: all ease ${themeGet('defaultTransitionTime')};
  z-index: 100;

  ${mainOpenCss}
`

export default MainContainer
