import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import LockResetIcon from '@mui/icons-material/LockReset'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Utils from 'Utils'

import { Column, IconButton, MenuItem, MenuList, Text } from 'Components/UI'
import { Span } from 'Components/UI/Text'

import { DeleteUserDocument, UserFragment } from 'GraphQL/Admin/TypedDocuments'
import UserUpdater from 'GraphQL/Admin/Updaters/User'
import { RequestChangeEmailPasswordDocument } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import toast from 'Services/Toast'

type Props = {
  user?: UserFragment
  onSuccessRequest?: () => void
}

function DeleteUserDropdown({ user, onSuccessRequest }: Props) {
  const theme = useTheme()
  const iconButtonRef = useRef(null)
  const [menuListOpen, setMenuListOpen] = useState(false)
  const color = useMemo(
    () => ({
      menuIcon: themeGet('colors.neutral500')({ theme }),
      deleteIcon: themeGet('colors.danger500')({ theme }),
    }),
    [theme],
  )

  const { showConfirmModal } = useAppContext()

  const [deleteUserMutation] = useMutation(DeleteUserDocument, {
    context: {
      admin: true,
    },
  })

  const [requestChangeEmailPasswordMutation] = useMutation(
    RequestChangeEmailPasswordDocument,
  )

  const handleOpenMenuList = useCallback(() => {
    setMenuListOpen(true)
  }, [])

  const handleCloseMenuList = useCallback(() => {
    setMenuListOpen(false)
  }, [])

  const handleDeleteUser = useCallback(async () => {
    handleCloseMenuList()
    if (!user) {
      return
    }

    const ok = await showConfirmModal?.({
      primary: true,
      mainIcon: <DeleteIcon />,
      children: (
        <Text mr={2}>
          Are you sure you want to delete user{' '}
          <Span primary>{Utils.User.getUserIdentifier(user)}</Span>
        </Text>
      ),
      okText: 'DELETE',
      danger: true,
    })

    if (!ok) return

    try {
      await deleteUserMutation({
        variables: {
          id: user.id,
        },
        update: (cache, fetchResult) => {
          if (!fetchResult.data?.deleteUser.ok) return
          UserUpdater.evict(cache, user.id)
        },
      })

      toast.success({
        text: 'User successfully deleted!',
      })

      onSuccessRequest?.()
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [
    user,
    onSuccessRequest,
    handleCloseMenuList,
    showConfirmModal,
    deleteUserMutation,
  ])

  const handleResetPassword = useCallback(async () => {
    handleCloseMenuList()
    if (!user?.primaryEmailCredential?.email) {
      return
    }

    try {
      const result = await requestChangeEmailPasswordMutation({
        variables: { email: user.primaryEmailCredential.email },
      })
      if (result.data?.requestChangeEmailPassword.ok) {
        toast.success({
          text: 'Email with reset password link was successfully sent!',
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [handleCloseMenuList, user, requestChangeEmailPasswordMutation])

  return (
    <Column>
      <IconButton ref={iconButtonRef} onClick={handleOpenMenuList}>
        <MoreVertIcon style={{ color: color.menuIcon, fontSize: 22 }} />
      </IconButton>

      <MenuList
        anchorEl={iconButtonRef.current}
        open={menuListOpen}
        onClose={handleCloseMenuList}
      >
        <MenuItem onClick={handleResetPassword}>
          <LockResetIcon />
          RESET PASSWORD
        </MenuItem>
        <MenuItem danger onClick={handleDeleteUser}>
          <DeleteIcon />
          DELETE USER
        </MenuItem>
      </MenuList>
    </Column>
  )
}

export default DeleteUserDropdown
