import React, {
  ComponentProps,
  ComponentRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Image as KonvaImage, Layer, Stage } from 'react-konva'

import { useMutation } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import useImage from 'use-image'
import { v4 as uuid } from 'uuid'

import { StyledStage } from 'Components/Blocks'
import { Button, Column, Loader, Row, Select, Text } from 'Components/UI'

import { CORS } from 'Config'

import { FLOOR_PLAN_IMAGE_SCALE, INITIAL_NOTE_CONFIG } from 'Constants/konva'

import {
  FloorPlanFragment,
  UpdateFloorPlanDocument,
} from 'GraphQL/Admin/TypedDocuments'
import { FileType } from 'GraphQL/Main/TypedDocuments'

import { useFloorPlanNotesState, useOnClickOutside, useSignFile } from 'Hooks'

import { useAppContext } from 'Services/AppContext'
import toast from 'Services/Toast'

import { KeyboardKey } from 'Types/keyboard'
import { ImageLoadingStatus, NoteConfig } from 'Types/konva'

import Utils from 'Utils'

import { StickyNote } from './StickyNote'
import { StageContainer } from './styles'

type FloorPlanOption = {
  label: string
  value: string
  floorPlan?: FloorPlanFragment
}

type Props = {
  inspectionId?: string
  floorPlans?: FloorPlanFragment[]
}

function FloorPlansEditor({ inspectionId, floorPlans }: Props) {
  const {
    state: floorPlanNotesState,
    updateNote,
    removeNote,
    bulkRemoveNotes,
    clearNotes,
  } = useFloorPlanNotesState()

  const [newNoteConfig, setNewNoteConfig] = useState<NoteConfig | null>()
  const [selectedNoteId, setSelectedNoteId] = useState('')
  const [editedNoteId, setEditedNoteId] = useState('')
  const [selectedFloorPlan, setSelectedFloorPlan] =
    useState<FloorPlanOption | null>(null)

  const stageContainerRef = useRef<HTMLDivElement | null>(null)
  const stageRef = useRef<ComponentRef<typeof Stage> | null>(null)

  const { showConfirmModal } = useAppContext()
  const { getSignedUrl } = useSignFile()

  const [saveNotesLoading, setSaveNotesLoading] = useState(false)

  const [updateFloorPlanMutation, { loading: updateFloorPlanLoading }] =
    useMutation(UpdateFloorPlanDocument, {
      context: {
        admin: true,
      },
    })

  const handleRemoveNotes = useCallback(async () => {
    if (!selectedFloorPlan) return

    const ok = await showConfirmModal?.({
      primary: true,
      mainIcon: <DeleteIcon />,
      children: (
        <Text mr={2}>
          Are you sure you want to remove all notes on the floor plan?
        </Text>
      ),
      okText: 'REMOVE',
      danger: true,
    })
    if (!ok) return

    try {
      const result = await updateFloorPlanMutation({
        variables: {
          id: selectedFloorPlan.value,
          editedImageUrl: '',
        },
      })
      const updatedFloorPlan = result.data?.updateFloorPlan
      toast.success({
        text: 'Floor plan successfully updated!',
      })
      bulkRemoveNotes(selectedFloorPlan.value)

      if (!updatedFloorPlan || selectedFloorPlan.value !== updatedFloorPlan.id)
        return

      setSelectedFloorPlan({
        label: updatedFloorPlan.level,
        value: updatedFloorPlan.id,
        floorPlan: updatedFloorPlan,
      })
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })
    }
  }, [
    selectedFloorPlan,
    bulkRemoveNotes,
    showConfirmModal,
    updateFloorPlanMutation,
  ])

  const saveNotes = useCallback(async () => {
    setSaveNotesLoading(true)

    if (!selectedFloorPlan || !stageRef.current || !inspectionId) return null

    stageRef.current?.getLayers()[0].find('Transformer')[0]?.remove()

    const imageBlobData = (await stageRef.current?.toBlob({
      // NOTE: increased canvas image quality
      pixelRatio: 2.5,
    })) as Blob

    const file = new File([imageBlobData], `canvas-${inspectionId}`, {
      type: imageBlobData.type,
    })

    const compressedFile = await Utils.File.compressFile(file)

    const signedFile = await getSignedUrl({
      inspectionId,
      file: compressedFile,
      type: FileType.FloorPlanImage,
      floorPlanId: selectedFloorPlan.value,
    })

    if (!signedFile?.data?.fileSign) {
      return null
    }

    try {
      const result = await updateFloorPlanMutation({
        variables: {
          id: selectedFloorPlan.value,
          editedImageUrl: signedFile.data.fileSign.publicUrl,
        },
      })

      toast.success({
        text: 'Floor plan successfully updated!',
      })

      bulkRemoveNotes(selectedFloorPlan.value)
      setSaveNotesLoading(false)

      return result.data?.updateFloorPlan
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      toast.error({ text: graphQLError })

      return null
    }
  }, [
    selectedFloorPlan,
    inspectionId,
    bulkRemoveNotes,
    getSignedUrl,
    updateFloorPlanMutation,
  ])

  const handleSaveNotes = useCallback(async () => {
    const updatedFloorPlan = await saveNotes()
    if (!updatedFloorPlan) return

    setSelectedFloorPlan({
      label: updatedFloorPlan.level,
      value: updatedFloorPlan.id,
      floorPlan: updatedFloorPlan,
    })
  }, [saveNotes])

  const floorPlanImageUrl = useMemo(
    () =>
      selectedFloorPlan?.floorPlan?.editedImageUrl ||
      selectedFloorPlan?.floorPlan?.originalImageUrl,
    [selectedFloorPlan],
  )

  const [image, imageStatus] = useImage(
    `${CORS}${floorPlanImageUrl}` || '',
    'anonymous',
    'origin',
  )

  const { stageWidth, stageHeight } = useMemo(
    () => ({
      stageWidth: image?.width ? image.width * FLOOR_PLAN_IMAGE_SCALE : 0,
      stageHeight: image?.height ? image.height * FLOOR_PLAN_IMAGE_SCALE : 0,
    }),
    [image],
  )

  useEffect(() => {
    if (selectedFloorPlan || !floorPlans?.length) return

    const unsavedFloorPlanId = Object.values(floorPlanNotesState).find(
      note => note.inspectionId === inspectionId,
    )?.floorPlanId

    const foundUnsavedFloorPlan = unsavedFloorPlanId
      ? floorPlans.find(floorPlan => floorPlan.id === unsavedFloorPlanId)
      : undefined

    if (!foundUnsavedFloorPlan) {
      setSelectedFloorPlan({
        label: floorPlans[0].level,
        value: floorPlans[0].id,
        floorPlan: floorPlans[0],
      })

      return
    }

    setSelectedFloorPlan({
      label: foundUnsavedFloorPlan.level,
      value: foundUnsavedFloorPlan.id,
      floorPlan: foundUnsavedFloorPlan,
    })
  }, [inspectionId, floorPlanNotesState, selectedFloorPlan, floorPlans])

  const options = useMemo(
    () =>
      floorPlans?.map(floorPlan => ({
        label: floorPlan.level,
        value: floorPlan.id,
        floorPlan,
      })),
    [floorPlans],
  )
  const selectedFloorPlanNotes = useMemo(() => {
    if (!selectedFloorPlan) return []

    return Object.values(floorPlanNotesState).filter(
      note =>
        note.inspectionId === inspectionId &&
        note.floorPlanId === selectedFloorPlan.value,
    )
  }, [inspectionId, selectedFloorPlan, floorPlanNotesState])

  const handleDeleteNote = useCallback(
    (noteId: string) => {
      removeNote(noteId)
    },
    [removeNote],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        (event.key === KeyboardKey.Escape || event.key === KeyboardKey.Enter) &&
        (editedNoteId || selectedNoteId || newNoteConfig)
      ) {
        setSelectedNoteId('')
        setEditedNoteId('')
        setNewNoteConfig(null)
      }

      if (
        event.key === KeyboardKey.Backspace &&
        selectedNoteId &&
        !editedNoteId
      ) {
        handleDeleteNote(selectedNoteId)
      }
    },
    [selectedNoteId, editedNoteId, newNoteConfig, handleDeleteNote],
  )

  // NOTE: Konva lacks keyboard handlers, so we have to implement a workaround for that
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])

  const handleDeselectNote = useCallback(() => {
    const affectedNoteId = selectedNoteId || editedNoteId

    if (!selectedFloorPlan || !affectedNoteId) return

    const selectedNote = floorPlanNotesState[affectedNoteId]
    if (!selectedNote?.text) {
      handleDeleteNote(affectedNoteId)
    }

    setSelectedNoteId('')
    setEditedNoteId('')
  }, [
    floorPlanNotesState,
    selectedFloorPlan,
    selectedNoteId,
    editedNoteId,
    handleDeleteNote,
  ])

  const handleSelectNote = useCallback((noteId?: string) => {
    if (!noteId) return

    setSelectedNoteId(noteId)
  }, [])

  const handleDoubleClickNote = useCallback((noteId?: string) => {
    if (!noteId) return

    setEditedNoteId(noteId)
  }, [])

  const handleShowNoteInput = useCallback(() => {
    if (!inspectionId || !selectedFloorPlan) return

    const note: NoteConfig = {
      ...INITIAL_NOTE_CONFIG,
      id: uuid(),
      inspectionId,
      floorPlanId: selectedFloorPlan.value,
    }

    setNewNoteConfig(note)
  }, [selectedFloorPlan, inspectionId])

  const handleAddNote = useCallback(
    (newConfig: NoteConfig) => {
      setNewNoteConfig(null)
      if (!newConfig.text) return

      updateNote(newConfig)
    },
    [updateNote],
  )

  const handleChangeNote = useCallback(
    (newConfig: NoteConfig) => {
      updateNote(newConfig)
    },
    [updateNote],
  )

  const handleSelectFloorPlan = useCallback<
    NonNullable<ComponentProps<typeof Select<FloorPlanOption>>['onChange']>
  >(
    async (_, option) => {
      if (selectedFloorPlanNotes.length > 0) {
        await saveNotes()
      }

      setSelectedFloorPlan(option)
    },
    [selectedFloorPlanNotes, saveNotes],
  )

  useOnClickOutside(stageContainerRef, handleDeselectNote)

  const loading = saveNotesLoading || updateFloorPlanLoading

  return (
    <Column minWidth="640px">
      <Column fullHeight gap={4} minWidth={0}>
        <Row center gap={4} mt={3} spaceBetween>
          <Select<FloorPlanOption>
            disableClearable
            disabled={loading}
            label="Floor plans"
            options={options}
            readOnly
            small
            value={selectedFloorPlan}
            width="200px"
            onChange={handleSelectFloorPlan}
          />

          {imageStatus === ImageLoadingStatus.Loaded && (
            <Row gap={4} spaceBetween>
              {!!selectedFloorPlan?.floorPlan?.editedImageUrl && (
                <Button
                  disabled={loading}
                  gap={3}
                  secondary
                  small
                  onClick={handleRemoveNotes}
                >
                  <CloseIcon />
                  REMOVE NOTES
                </Button>
              )}

              <Button disabled={loading} small onClick={handleShowNoteInput}>
                POINT NOTE
              </Button>
            </Row>
          )}
        </Row>

        {imageStatus !== ImageLoadingStatus.Loading && !floorPlanImageUrl && (
          <Row center justifyCenter>
            <Text body2>This floor plan does not have an image</Text>
          </Row>
        )}

        {imageStatus === ImageLoadingStatus.Failed && (
          <Row center justifyCenter>
            <Text body2>Something went wrong, try reloading the page</Text>
          </Row>
        )}

        {(imageStatus === ImageLoadingStatus.Loading || loading) && (
          <Row center height="200px" justifyCenter>
            <Loader size={70} />
          </Row>
        )}

        {imageStatus === ImageLoadingStatus.Loaded && !loading && (
          <StageContainer mb={4} ref={stageContainerRef}>
            <StyledStage
              height={stageHeight}
              overflow="auto"
              ref={stageRef}
              width={stageWidth}
            >
              <Layer>
                <KonvaImage
                  image={image}
                  scaleX={FLOOR_PLAN_IMAGE_SCALE}
                  scaleY={FLOOR_PLAN_IMAGE_SCALE}
                  onClick={handleDeselectNote}
                />

                {!!selectedFloorPlanNotes?.length &&
                  selectedFloorPlanNotes.map(note => (
                    <StickyNote
                      config={note}
                      isEdited={note.id === editedNoteId}
                      isSelected={note.id === selectedNoteId}
                      key={note.id}
                      value={note.text}
                      onChange={handleChangeNote}
                      onDoubleClick={() => handleDoubleClickNote(note.id)}
                      onSelect={() => handleSelectNote(note.id)}
                    />
                  ))}

                {!!newNoteConfig && (
                  <StickyNote
                    config={newNoteConfig}
                    isEdited
                    value={newNoteConfig.text}
                    onChange={handleAddNote}
                  />
                )}
              </Layer>
            </StyledStage>

            {selectedFloorPlanNotes.length > 0 && (
              <Row fullWidth gap={4} justifyEnd mt={4}>
                <Button disabled={loading} secondary small onClick={clearNotes}>
                  CANCEL
                </Button>

                <Button disabled={loading} small onClick={handleSaveNotes}>
                  SAVE
                </Button>
              </Row>
            )}
          </StageContainer>
        )}
      </Column>
    </Column>
  )
}

export default FloorPlansEditor
