import { DateTimeFormatOptions } from 'luxon'

export const TIME_12HR_FORMAT: DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export const TIME_SHORT_MONTH_FORMAT: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export const TIME_EXIF_FORMAT = 'yyyy:MM:dd HH:mm:ss'
