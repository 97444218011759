import React, { ComponentProps, useCallback, useMemo } from 'react'

import SearchIcon from '@mui/icons-material/Search'

import debounce from 'lodash/debounce'

import { UserDropdown } from 'Components/Blocks/Dropdowns'
import Header from 'Components/Blocks/Header'
import { Input, Row, Text } from 'Components/UI'

const SEARCH_DEBOUNCE = 350

type Props = {
  onChange?: (value: string) => void
}

function ServiceAppointmentHeader({ onChange }: Props) {
  const debouncedSearch = useMemo(() => {
    return debounce(
      (value: string) => onChange?.(value.trim()),
      SEARCH_DEBOUNCE,
    )
  }, [onChange])

  const handleSearch = useCallback<
    NonNullable<ComponentProps<typeof Input>['onChange']>
  >(
    event => {
      debouncedSearch(event.target.value)
    },
    [debouncedSearch],
  )

  return (
    <Header>
      <Row center fullWidth spaceBetween>
        <Text header5 inverse>
          Service Appointments
        </Text>

        <Row gap={4}>
          <Input
            inverse
            placeholder="Search..."
            renderBeforeElement={() => <SearchIcon />}
            small
            onChange={handleSearch}
          />

          <UserDropdown />
        </Row>
      </Row>
    </Header>
  )
}

export default ServiceAppointmentHeader
