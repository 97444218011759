import styled, { css } from 'styled-components'

type ImageWrapperProps = {
  isClickable?: boolean
}

const imageWrapperClickableCss = ({ isClickable }: ImageWrapperProps) =>
  isClickable &&
  css`
    cursor: pointer;
  `

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;

  ${imageWrapperClickableCss}
`

export const StyledImage = styled.img`
  height: auto;
  max-width: 100%;
`
