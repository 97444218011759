import React from 'react'

import { MarginProps } from 'styled-system'

import { NoImagesSvg } from 'Assets/Svg'

import InfiniteScroll from 'Components/Blocks/InfiniteScroll'
import { Column, Loader, Row, Text } from 'Components/UI'

import {
  AudioRecordTranscriptionStatus,
  InspectionDefectFragment,
} from 'GraphQL/Main/TypedDocuments'

import DefectItem from './DefectItem'

type Props = MarginProps & {
  audioUrl?: string
  audioTranscription?: string | null
  audioTranscriptionStatus?: AudioRecordTranscriptionStatus | null
  inspectionDefects?: InspectionDefectFragment[]
  selectedInspectionDefects?: Set<string>
  loading?: boolean
  loadingMore?: boolean
  canLoadMore?: boolean
  hideDefectComments?: boolean
  hideRemoveDrawings?: boolean
  hideDownloadImages?: boolean
  updatingInspectionDefects?: Set<string>
  onLoadMore?: () => void
  onClickInspectionDefect?: (inspectionDefect: InspectionDefectFragment) => void
  onDeleteInspectionDefect?: (
    inspectionDefect: InspectionDefectFragment,
  ) => void
  onToggleSelection?: (id: string) => void
  onChangeComment?: (id: string, value: string) => void
  onChangeImage?: (id: string, files: File[]) => void
  onShowActivity?: (id: string) => void
  onRemoveDrawings?: (id: string) => void
  onDownloadImage?: (inspectionDefect: InspectionDefectFragment) => void
}

function InspectionDefectList({
  audioUrl,
  audioTranscription,
  audioTranscriptionStatus,
  inspectionDefects,
  selectedInspectionDefects,
  loading,
  loadingMore,
  canLoadMore,
  hideDefectComments,
  hideRemoveDrawings,
  hideDownloadImages,
  updatingInspectionDefects,
  onLoadMore,
  onClickInspectionDefect,
  onDeleteInspectionDefect,
  onToggleSelection,
  onChangeComment,
  onChangeImage,
  onShowActivity,
  onRemoveDrawings,
  onDownloadImage,
}: Props) {
  if (loading && !loadingMore) {
    return <Loader fullScreen size={70} />
  }
  // note: return text 'Opportunity is empty, Click “Add media” to make a first record'
  if (!inspectionDefects?.length) {
    return (
      <Column center flex={1} justifyCenter>
        <Column center width="350px">
          <NoImagesSvg />
          <Text action2 center>
            Opportunity is empty
          </Text>
        </Column>
      </Column>
    )
  }

  return (
    <InfiniteScroll
      canLoadMore={canLoadMore}
      rootMargin="20px"
      shadowy
      onLoadMore={onLoadMore}
    >
      <Row gap={5} mb={6} mx={6} wrap>
        {inspectionDefects.map(inspectionDefect => (
          <DefectItem
            audioTranscription={audioTranscription}
            audioTranscriptionStatus={audioTranscriptionStatus}
            audioUrl={audioUrl}
            checked={selectedInspectionDefects?.has(inspectionDefect.id)}
            defect={inspectionDefect}
            hideComments={hideDefectComments}
            hideDownloadImages={hideDownloadImages}
            hideRemoveDrawings={hideRemoveDrawings}
            isUpdating={updatingInspectionDefects?.has(inspectionDefect.id)}
            key={inspectionDefect.id}
            onChangeComment={value =>
              onChangeComment?.(inspectionDefect.id, value)
            }
            onChangeImage={files => onChangeImage?.(inspectionDefect.id, files)}
            onClickDefect={() => onClickInspectionDefect?.(inspectionDefect)}
            onDelete={() => onDeleteInspectionDefect?.(inspectionDefect)}
            onDownloadImage={() => onDownloadImage?.(inspectionDefect)}
            onRemoveDrawings={() => onRemoveDrawings?.(inspectionDefect.id)}
            onShowActivity={() => onShowActivity?.(inspectionDefect.id)}
            onToggleSelection={() => onToggleSelection?.(inspectionDefect.id)}
          />
        ))}
      </Row>
      {loadingMore && (
        <Row center gap={3} justifyCenter mb={8} mt={4}>
          <Loader size={30} />

          <Text caption2 center muted>
            LOADING...
          </Text>
        </Row>
      )}
    </InfiniteScroll>
  )
}

export default InspectionDefectList
