import React, { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { StyledPlayer } from './styles'

type Props = {
  url: string
  color?: string
  startAtSecond?: number
}

const PLAYER_CONFIG = {
  file: {
    attributes: { controlsList: 'nodownload noplaybackrate' },
    forceAudio: true,
  },
}

// TODO: devise a solution where only one record is activated at a time
function Player({ url, startAtSecond, color = 'white' }: Props) {
  const [isReady, setIsReady] = useState(false)
  const playerRef = useRef<ReactPlayer>()

  const onReady = useCallback(() => {
    if (!isReady && startAtSecond) {
      if (playerRef.current) {
        playerRef?.current?.seekTo(startAtSecond, 'seconds')
      }
      setIsReady(true)
    }
  }, [isReady, startAtSecond])

  return (
    <StyledPlayer
      color={color}
      config={PLAYER_CONFIG}
      controls
      height="32px"
      ref={playerRef}
      url={url}
      width="100%"
      onReady={onReady}
    />
  )
}

export default Player
