import {
  compressAccurately,
  compressAccuratelyConfig,
} from 'lib-image-conversion'

import { CORS } from 'Config'

import { DEFAULT_COMPRESS_IMAGE_OPTIONS, IMAGE_MIMES } from 'Constants/media'

type Dimension = {
  width: number
  height: number
}

function isImage(mime?: string) {
  if (!mime) return false
  return IMAGE_MIMES.includes(mime)
}

async function getImageDimension(file: File) {
  if (isImage(file.type)) {
    return undefined
  }

  const dimension = await new Promise<Dimension | undefined>(resolve => {
    const finish = () => {
      if (image.width && image.height) {
        resolve({ width: image.width, height: image.height })
      } else {
        resolve(undefined)
      }
    }

    const image = new Image()
    image.addEventListener('load', finish)
    image.addEventListener('error', finish)
    image.src = URL.createObjectURL(file)
  })
  return dimension
}

async function compressImageFromUrl(
  url?: string | null,
  options: compressAccuratelyConfig = DEFAULT_COMPRESS_IMAGE_OPTIONS,
) {
  if (!url) return null

  const response = await fetch(`${CORS}${url}`)
  const blob = await response.blob()

  const compressedBlob = await compressAccurately(blob, options)

  return URL.createObjectURL(compressedBlob)
}

export default { getImageDimension, isImage, compressImageFromUrl }
