import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Table } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import { Card } from 'Components/UI'

export const StyledTable = styled(Table)`
  height: 100%;
  th {
    height: 56px;
    border-bottom: 1px solid ${themeGet('colors.divider.default')};
  }

  tbody {
    .MuiTableCell-root {
      border-bottom: none;
    }

    tr:hover {
      background-color: ${themeGet('colors.neutral800')};
    }
  }
`

export const TableOverlay = styled.div<WidthProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);

  ${width}
`

export const ExpandedTable = styled(TableRow)`
  width: 100%;
  border-collapse: collapse;

  background-color: ${themeGet('colors.neutral800')};
  table tr {
    &:hover {
      cursor: pointer;
      background-color: ${themeGet('colors.neutral700')};
    }
  }

  thead tr {
    background-color: ${themeGet('colors.neutral800')};
    &:hover {
      background-color: ${themeGet('colors.neutral800')};
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const TableCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`
