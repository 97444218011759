import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Utils from 'Utils'

import {
  FloorPlansDefectList,
  FloorPlansEditor,
  Header,
} from 'Components/Blocks'
import { Column, IconButton, Loader, Row, Text } from 'Components/UI'

import {
  INSPECTION_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'

import { FloorPlanListDocument } from 'GraphQL/Admin/TypedDocuments'
import { InspectionDocument } from 'GraphQL/Main/TypedDocuments'

import { useInspectionDefectList } from 'Hooks'

import toast from 'Services/Toast'

function FloorPlans() {
  const theme = useTheme()
  const { inspectionId } = useParams<ServiceAppointmentsRouteParams>()
  const history = useHistory()

  const {
    data: inspectionData,
    loading: inspectionDataLoading,
    error,
  } = useQuery(InspectionDocument, {
    variables: {
      id: inspectionId!,
    },
    skip: !inspectionId,
    fetchPolicy: 'network-only',
  })

  const serviceAppointment = useMemo(
    () => inspectionData?.inspection?.serviceAppointment,
    [inspectionData],
  )

  const { data: floorPlansData, loading: floorPlansLoading } = useQuery(
    FloorPlanListDocument,
    {
      context: {
        admin: true,
      },
      variables: {
        inspectionId: inspectionId!,
        limit: 999,
      },
      skip: !inspectionId,
      fetchPolicy: 'network-only',
    },
  )
  const floorPlans = useMemo(
    () => (floorPlansData ? floorPlansData.floorPlanList.rows : []),
    [floorPlansData],
  )

  const color = useMemo(
    () => ({
      chevronIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )
  const floorPlanText = useMemo(() => {
    let text = 'Floor plans'
    if (serviceAppointment?.appointmentNumber) {
      text += ` | ${serviceAppointment.appointmentNumber}`
    }

    return text
  }, [serviceAppointment])

  const {
    inspectionDefects,
    loading: inspectionDefectsLoading,
    loadingMore,
    canLoadMore,
    loadMore,
  } = useInspectionDefectList({ inspectionId })

  useEffect(() => {
    if (!error) {
      return
    }

    const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [error, history])

  const handleBack = useCallback(() => {
    history.push(INSPECTION_ROOT(inspectionId))
  }, [history, inspectionId])

  if (
    inspectionDataLoading ||
    floorPlansLoading ||
    (inspectionDefectsLoading && !loadingMore)
  ) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Header>
        <Row center gap={5} minWidth={0}>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon style={{ color: color.chevronIcon }} />
          </IconButton>

          <Row center minWidth={0}>
            <Text ellipsis header5 inverse maxWidth="100%">
              {floorPlanText}
            </Text>
          </Row>
        </Row>
      </Header>

      <Column fullHeight minHeight={0} mt={6} mx={6} overflow="auto">
        {floorPlans.length > 0 ? (
          <Row fullHeight fullWidth gap="100px" minWidth={0}>
            <FloorPlansDefectList
              audioUrl={inspectionData?.inspection?.audioRecord?.audioUrl}
              canLoadMore={canLoadMore}
              inspectionDefects={inspectionDefects}
              loadMore={loadMore}
              loadingMore={loadingMore}
            />

            <FloorPlansEditor
              floorPlans={floorPlans}
              inspectionId={inspectionId}
            />
          </Row>
        ) : (
          <Column center fullWidth mt={10}>
            <Text action2>There are no floor plans yet</Text>
          </Column>
        )}
      </Column>
    </Column>
  )
}

export default FloorPlans
