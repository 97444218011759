import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import Utils from 'Utils'
import validate from 'validate.js'

import { LogoSvg, MailSvg } from 'Assets/Svg'

import {
  Button,
  Column,
  InputField,
  Link,
  Loader,
  Row,
  Text,
} from 'Components/UI'

import { LOGIN } from 'Constants/paths'

import { RequestChangeEmailPasswordDocument } from 'GraphQL/Main/TypedDocuments'

import { PasswordResetState } from 'Services/Store/passwordResetState'
import toast from 'Services/Toast'

import { Card, StyledForm } from '../styles'

enum Field {
  Email = 'email',
}

type FormValues = {
  [Field.Email]: string
}

export const CONSTRAINTS = {
  [Field.Email]: {
    presence: true,
    email: {
      message: 'should be correct',
    },
  },
}

function ForgotPassword() {
  const [hasSendResetLink, setHasSendResetLink] = useState(false)
  const email = PasswordResetState.getEmail()

  const [requestChangeEmailPasswordMutation] = useMutation(
    RequestChangeEmailPasswordDocument,
  )

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        const result = await requestChangeEmailPasswordMutation({
          variables: values,
        })

        if (!result.data?.requestChangeEmailPassword.ok) {
          return
        }

        PasswordResetState.setEmail(values[Field.Email])
        setHasSendResetLink(true)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [requestChangeEmailPasswordMutation],
  )

  const formValidation = useCallback(
    (values: FormValues) => validate(values, CONSTRAINTS),
    [],
  )

  const renderForm = useCallback(
    ({ handleSubmit, submitting }) => (
      <StyledForm onSubmit={handleSubmit}>
        <InputField
          displayError
          label="Email"
          mb="60px"
          name={Field.Email}
          type="text"
        />

        <Button
          disabled={submitting}
          type="submit"
          width={1}
          onClick={handleSubmit}
        >
          RESET PASSWORD
          {submitting && <Loader inverse ml={4} />}
        </Button>

        {!submitting && (
          <Button component={Link} mt={3} secondary to={LOGIN} width={1}>
            BACK TO LOGIN
          </Button>
        )}
      </StyledForm>
    ),
    [],
  )

  return (
    <Column center fullHeight justifyCenter>
      <Card p={'60px'}>
        <Column center width={1}>
          <LogoSvg />
          {!hasSendResetLink && (
            <>
              <Text header4 heading mb={9} mt="60px">
                Reset password
              </Text>
              <Text body4 center heading mb={9}>
                {`Enter the email associated with your account and we'll send an email
        with instructions to reset your password`}
              </Text>
            </>
          )}

          {!hasSendResetLink ? (
            <Form
              render={renderForm}
              validate={formValidation}
              onSubmit={submit}
            />
          ) : (
            <Column center width={1}>
              <Column center gap={9} mb="60px" mt="60px">
                <MailSvg />
                <Text header4 heading>
                  Check your email
                </Text>
                <Row center justifyCenter wrap>
                  <Text body4 center heading>
                    We sent a password reset link to
                  </Text>
                  <Text action4 center heading ml={2}>
                    {email}
                  </Text>
                </Row>
              </Column>

              <Button component={Link} secondary to={LOGIN} width={1}>
                BACK TO LOGIN
              </Button>
            </Column>
          )}
        </Column>
      </Card>
    </Column>
  )
}

export default ForgotPassword
