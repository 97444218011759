import {
  FieldFunctionOptions,
  FieldPolicy,
  Reference,
  TypePolicies,
} from '@apollo/client'

import {
  ServiceAppointmentListQuery,
  ServiceAppointmentListQueryVariables,
} from 'GraphQL/Admin/TypedDocuments'
import {
  ActivityListQuery,
  ActivityListQueryVariables,
  InspectionDefectListQuery,
  InspectionDefectListQueryVariables,
} from 'GraphQL/Main/TypedDocuments'

type ConcreteFieldPolicy<
  TResult,
  TArgs extends Record<string, any>,
> = FieldPolicy<TResult, TResult, TResult, FieldFunctionOptions<TArgs>>

type ReferenceCursorList<T extends { rows: any[] }> = Omit<T, 'rows'> & {
  rows: Reference[]
}

export default {
  Query: {
    fields: {
      inspectionDefectList: {
        keyArgs: ['serviceAppointmentId'],
        merge(existing, incoming) {
          const existingRows = existing?.rows ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.rows?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return {
            ...existing,
            ...incoming,
            rows: [...existingRows, ...incomingRows],
          }
        },
      } as ConcreteFieldPolicy<
        ReferenceCursorList<InspectionDefectListQuery['inspectionDefectList']>,
        InspectionDefectListQueryVariables
      >,
      activityList: {
        keyArgs: ['inspectionDefectId'],
        merge(existing, incoming) {
          const existingRows = existing?.rows ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.rows?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return {
            ...existing,
            ...incoming,
            rows: [...existingRows, ...incomingRows],
          }
        },
      } as ConcreteFieldPolicy<
        ReferenceCursorList<ActivityListQuery['activityList']>,
        ActivityListQueryVariables
      >,
      serviceAppointmentList: {
        keyArgs: ['id', 'scheduledFrom', 'scheduledTo', 'search', 'sort'],
        merge(existing, incoming) {
          const existingRows = existing?.rows ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.rows?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return {
            ...existing,
            ...incoming,
            rows: [...existingRows, ...incomingRows],
          }
        },
      } as ConcreteFieldPolicy<
        ReferenceCursorList<
          ServiceAppointmentListQuery['serviceAppointmentList']
        >,
        ServiceAppointmentListQueryVariables
      >,
    },
  },
} as TypePolicies
