import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import styles from '../styles'

function TermsAndConditions() {
  return (
    <View break style={[styles.mt30, styles.lateralPaddingContainer]}>
      <View style={[styles.column, styles.justifyCenter, styles.center]}>
        <Text style={styles.header5}>TERMS AND CONDITIONS</Text>
      </View>

      <Text style={[styles.action4, styles.mt30]}>
        Important information regarding the scope and limitations of inspection
        and this report: Any person who relies upon the contents of this report
        does so acknowledging that the following clauses form an integral part
        of the report.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>
        This report is not an all encompassing structural survey.
      </Text>
      <Text style={styles.caption2}>
        It is a reasonable attempt to identify any obvious or significant
        defects apparent at the time of the inspection. Whether a defect is
        considered significant or not, to a large extent, depends on the age and
        type of the building or property inspected. This report is not a
        Certificate of Compliance with the requirements of any act, regulation,
        ordinance or by-law or, as a warranty or an insurance policy against
        problems developing with the building or property in the future.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>
        Only areas to which reasonable access is available were inspected.
      </Text>
      <Text style={styles.caption2}>
        AS 4349.0 defines reasonable access as “areas where safe, unobstructed
        access is provided and the minimum clearances specified below are
        available, or where these clearances are not available, areas within the
        inspector’s unobstructed line of sight and within arm’s length...”.
        Reasonable access does not include removing screws and bolts to access
        covers or the use of destructive/invasive inspection methods, cutting or
        making access traps, moving heavy furniture, floor coverings or stored
        goods.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>
        This report does not and cannot make comment upon:
      </Text>
      <View style={styles.column}>
        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            The assessment or detection of defects which may be subject to the
            prevailing weather conditions.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Whether or not services have been used for some time prior to the
            inspection and whether this will affect the detection of leaks or
            other defects.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            The presence or absence of timber pests, gas-fittings, common
            property areas, environmental concerns, the proximity of the
            property to flight paths, railways, or busy traffic.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Noise levels, health and safety issues, heritage concerns, security
            concerns or systems; fire protection, site drainage.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Detection and identification of illegal building work, illegal
            plumbing work, durability of exposed finishes, neighbourhood
            problems, electrical installation, cables or reception systems, any
            matters that are solely regulated by statute.
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.caption2}>&bull;</Text>
          <Text style={[styles.caption2, styles.ml8]}>
            Accordingly, this report does not guarantee that defects and/or
            damages do not exist in any inaccessible or partly inaccessible
            areas or sections of the property
          </Text>
        </View>
      </View>

      <Text style={[styles.action4, styles.mt12]}>
        Asbestos, Lead and Mould Disclaimer:
      </Text>
      <Text style={styles.caption2}>
        No inspection for asbestos, lead or mould was carried out at the
        property and no professional report on the presence or absence of them
        is provided. If asbestos is noted as present within the property or if
        the building was built prior to 1990 and you are concerned they may be
        present within the property then you should seek advice from a qualified
        specialist to identify the amount and importance of their presence and
        the cost of sealing or removal.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>Estimating Disclaimer:</Text>
      <Text style={styles.caption2}>
        This report does not provide any estimates on repair or remedial works.
        We recommend you consult a licenced builder to give an estimate on any
        work required.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>
        Disclaimer of Liability:
      </Text>
      <Text style={styles.caption2}>
        No liability shall be accepted on an account of failure of the report to
        notify any problems in the area(s) or section(s) of the subject property
        physically inaccessible during inspection, or to which access is denied.
        No responsibility can be accepted for defects which are latent or
        otherwise not reasonably detected on a visual inspection.
      </Text>

      <Text style={[styles.action4, styles.mt12]}>
        Disclaimer of Liability to Third Parties:
      </Text>
      <Text style={styles.caption2}>
        This report is made solely for the use and benefit of the client named
        on the front of this report. No liability or responsibility whatsoever,
        in contract or tort, is accepted to any third party who may rely on the
        report wholly or in part. Any third party acting or relying on this
        report, in whole or in part does so at their own risk.
      </Text>
    </View>
  )
}

export default TermsAndConditions
