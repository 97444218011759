import React from 'react'

import { ImageWrapper, StyledImage } from './styles'

type Props = {
  url: string
  isClickable?: boolean
  onClick?: () => void
}

function Image({ url, isClickable, onClick }: Props) {
  return (
    <ImageWrapper isClickable={isClickable} onClick={onClick}>
      <StyledImage src={url} />
    </ImageWrapper>
  )
}

export default Image
