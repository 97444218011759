import Admins from './Admins'
import FloorPlans from './FloorPlans'
import Inspection from './Inspection'
import Inspectors from './Inspectors'
import ServiceAppointment from './ServiceAppointment'
import ServiceAppointments from './ServiceAppointments'

export default {
  FloorPlans,
  Admins,
  Inspection,
  ServiceAppointments,
  Inspectors,
  ServiceAppointment,
}
