import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'
import { NoteConfig } from 'Types/konva'

import { Events, KEY, State } from 'Services/Store/floorPlanNotes'

export default function useFloorPlanNotesState() {
  const { dispatch, [KEY]: state } = useStoreon<State, Events>(KEY)

  const updateNote = useCallback(
    (note: NoteConfig) => {
      dispatch('floorPlanNotes/update', {
        note,
      })
    },
    [dispatch],
  )

  const removeNote = useCallback(
    (noteId: string) => {
      dispatch('floorPlanNotes/remove', {
        noteId,
      })
    },
    [dispatch],
  )

  const bulkRemoveNotes = useCallback(
    (floorPlanId: string) => {
      dispatch('floorPlanNotes/bulkRemove', {
        floorPlanId,
      })
    },
    [dispatch],
  )

  const clearNotes = useCallback(() => {
    dispatch('floorPlanNotes/clear')
  }, [dispatch])

  return {
    state,
    updateNote,
    removeNote,
    bulkRemoveNotes,
    clearNotes,
  }
}
