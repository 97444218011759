import styled, { css } from 'styled-components'
import { width, WidthProps } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import { IMAGE_MAP_ARROW_COORDINATES, PINDROP_COORDINATES } from 'Constants/map'

import { gap, GapProps } from 'Services/Styles'

type MapArrowContainerProps = {
  rotate?: number
}

type RadioValuesContainerProps = {
  tight?: boolean
}
type ButtonsContainerProps = GapProps & {
  tight?: boolean
}

const radioValuesContainerTightCss = ({ tight }: RadioValuesContainerProps) =>
  tight &&
  css`
    flex-direction: column;
  `

const buttonsContainerTightCss = ({ tight }: ButtonsContainerProps) =>
  tight &&
  css`
    flex-direction: column-reverse;
    gap: ${themeGet('space.1')}px;
  `

const mapArrowContainerRotateCss = ({ rotate }: MapArrowContainerProps) =>
  (rotate || rotate === 0) &&
  css`
    transform: translate(-50%, -${IMAGE_MAP_ARROW_COORDINATES.Y}px)
      rotate(${rotate}deg);
  `

export const StageContainer = styled.div<WidthProps>`
  position: relative;
  box-shadow: rgb(26 26 26 / 40%) 0 0 10px;
  border-radius: 8px;

  ${width}
`

export const MapArrowContainer = styled.div<MapArrowContainerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 100;
  transform-origin: 50% ${IMAGE_MAP_ARROW_COORDINATES.Y}px;

  ${mapArrowContainerRotateCss}
`

export const CompassContainer = styled.div`
  position: absolute;
  bottom: 8px;
  width: 60px;
  right: 0;
  z-index: 100;
`

export const PindropContainer = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -${PINDROP_COORDINATES.Y}px);
`

export const RadioValuesContainer = styled.div<RadioValuesContainerProps>`
  display: flex;
  align-items: center;

  ${radioValuesContainerTightCss}
`

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${gap}
  ${buttonsContainerTightCss}
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${themeGet('colors.neutral500Translucent')};
`
