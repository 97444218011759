import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useQuery } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip from '@mui/material/Tooltip'
import { DateTime } from 'luxon'
import { ShowModalKind } from 'Types/modals'
import Utils from 'Utils'

import {
  FileUploadProgressModal,
  Header,
  InspectionDefectList,
} from 'Components/Blocks'
import { Button, Column, IconButton, Loader, Row, Text } from 'Components/UI'

import {
  SERVICE_APPOINTMENT_DEFECT_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'
import { IMAGE_LOADING_TOTAL_STEPS } from 'Constants/serviceAppointments'

import {
  FileType,
  ServiceAppointmentDocument,
} from 'GraphQL/Main/TypedDocuments'

import {
  useInspectionDefectEntity,
  useInspectionDefectList,
  useSignFile,
} from 'Hooks'

import { useAppContext } from 'Services/AppContext'
import toast from 'Services/Toast'

function ServiceAppointment() {
  const { me, showModal } = useAppContext()
  const theme = useTheme()
  const { serviceAppointmentId } = useParams<ServiceAppointmentsRouteParams>()
  const history = useHistory()
  const { progress, getSignedUrl, abortUpload } = useSignFile()

  const [modalOpen, setModalOpen] = useState(false)
  const [loadingStep, setLoadingStep] = useState(0)
  const {
    data: serviceAppointmentData,
    loading: serviceAppointmentLoading,
    error,
  } = useQuery(ServiceAppointmentDocument, {
    variables: {
      id: serviceAppointmentId!,
    },
    skip: !serviceAppointmentId,
    fetchPolicy: 'network-only',
  })

  const serviceAppointment = useMemo(
    () => serviceAppointmentData?.serviceAppointment,
    [serviceAppointmentData],
  )
  const color = useMemo(
    () => ({
      chevronIcon: themeGet('colors.neutral900')({ theme }),
    }),
    [theme],
  )
  const serviceAppointmentText = useMemo(() => {
    if (!serviceAppointment) {
      return null
    }

    let text = serviceAppointment.subject || ''
    if (serviceAppointment.appointmentNumber) {
      text += ` | SA number: ${serviceAppointment.appointmentNumber}`
    }
    if (serviceAppointment.createdAt) {
      text += ` | ${DateTime.fromISO(
        serviceAppointment?.createdAt,
      ).toLocaleString()}`
    }
    text += ` | Inspector: ${Utils.User.getUserIdentifier(
      me,
    )} | Status: ${Utils.ServiceAppointment.getStatusText(
      serviceAppointment.status,
    )}`

    return text
  }, [serviceAppointment, me])

  const {
    inspectionDefects,
    inspectionDefectsCount,
    selectedInspectionDefects,
    loading: inspectionDefectsLoading,
    loadingMore,
    canLoadMore,
    loadMore,
    toggleSelection,
    clearSelection,
    refetch,
  } = useInspectionDefectList({ serviceAppointmentId })
  const { deleteInspectionDefect, updateInspectionDefect } =
    useInspectionDefectEntity()

  useEffect(() => {
    if (!error) {
      return
    }

    const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [error, history])

  const handleClickInspectionDefect = useCallback<
    NonNullable<
      ComponentProps<typeof InspectionDefectList>['onClickInspectionDefect']
    >
  >(
    inspectionDefect => {
      history.push(SERVICE_APPOINTMENT_DEFECT_ROOT(inspectionDefect?.id))
    },
    [history],
  )

  const handleDeleteInspectionDefect = useCallback<
    NonNullable<
      ComponentProps<typeof InspectionDefectList>['onDeleteInspectionDefect']
    >
  >(
    async inspectionDefect => {
      const ok = await deleteInspectionDefect.mutate(inspectionDefect)
      if (!ok) {
        return
      }

      refetch()
    },
    [deleteInspectionDefect, refetch],
  )

  const handleChangeImage = useCallback(
    async (inspectionDefectId: string, files: File[]) => {
      setModalOpen(true)
      // TODO: extract multiple files uploading for service appointment photo to a hook
      setLoadingStep(prevState => prevState + 1)

      const file = files[0]

      const compressedFile = await Utils.File.compressFile(file)

      const signedFile = await getSignedUrl({
        file,
        type: FileType.ServiceAppointmentPhoto,
        serviceAppointmentId,
      })

      setLoadingStep(prevState => prevState + 1)

      const signedCompressedFile = await getSignedUrl({
        file: compressedFile,
        type: FileType.ServiceAppointmentPhoto,
        serviceAppointmentId,
      })

      if (
        !signedFile?.data?.fileSign ||
        !signedCompressedFile?.data?.fileSign
      ) {
        return
      }

      const photoTakenAt = await Utils.File.getOriginalDateFromFile(file)

      await updateInspectionDefect.mutate({
        id: inspectionDefectId,
        originalPhotoUrl: signedFile.data.fileSign.publicUrl,
        compressedOriginalPhotoUrl: signedFile.data.fileSign.publicUrl,
        editedPhotoUrl: null,
        latitude: null,
        longitude: null,
        photoTakenAt,
      })

      setModalOpen(false)
    },
    [serviceAppointmentId, getSignedUrl, updateInspectionDefect],
  )

  const handleShowActivity = useCallback(
    (defectId?: string) => {
      if (!defectId) return

      showModal?.({ kind: ShowModalKind.ActivityFeed, defectId })
    },
    [showModal],
  )

  const handleBack = useCallback(() => {
    history.push(SERVICE_APPOINTMENTS)
  }, [history])

  const handleCloseModal = useCallback(() => {
    abortUpload()
    setModalOpen(false)
  }, [abortUpload])

  const loadingProgress = useMemo(() => {
    if (!loadingStep) return progress
    return Utils.ProgressBar.calculateLoadingProgress(
      loadingStep,
      progress,
      IMAGE_LOADING_TOTAL_STEPS,
    )
  }, [loadingStep, progress])

  if (serviceAppointmentLoading || deleteInspectionDefect.loading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Header>
        <Row center gap={5} minWidth={0}>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon style={{ color: color.chevronIcon }} />
          </IconButton>

          {serviceAppointmentText && (
            <Tooltip
              arrow
              followCursor
              placement="bottom-start"
              title={serviceAppointmentText}
            >
              <Row center minWidth={0}>
                <Text ellipsis header5 inverse maxWidth="100%">
                  {serviceAppointmentText}
                </Text>
              </Row>
            </Tooltip>
          )}
        </Row>
      </Header>

      <Column fullHeight minHeight={0} mt={6}>
        <Row
          center
          justifyContent={
            selectedInspectionDefects?.size === 0 ? 'flex-end' : 'space-between'
          }
          mb={6}
          mx={6}
        >
          {selectedInspectionDefects && selectedInspectionDefects?.size > 0 && (
            <Row center>
              <Text action2 mr={2}>
                Selected
              </Text>
              <Text action2 mr={2} primary>
                {selectedInspectionDefects.size}
              </Text>
              <Text action2>media pair(s)</Text>

              <Button ml={3} secondary small onClick={clearSelection}>
                <ClearIcon />
                Remove selection
              </Button>
            </Row>
          )}

          <Row center mr={4}>
            <Text mr={2} subheader2>
              FIGURES COUNT:
            </Text>
            <Text primary subheader2>
              {inspectionDefectsCount}
            </Text>
          </Row>

          {/* <Button
            component={Link}
            small
            to={CREATE_DEFECT_SERVICE_APPOINTMENT(serviceAppointmentId)}
          >
            ADD MEDIA
          </Button> */}
        </Row>

        <InspectionDefectList
          audioUrl={serviceAppointment?.audioRecord?.audioUrl}
          canLoadMore={canLoadMore}
          hideDefectComments
          hideDownloadImages
          hideRemoveDrawings
          inspectionDefects={inspectionDefects}
          loading={inspectionDefectsLoading}
          loadingMore={loadingMore}
          selectedInspectionDefects={selectedInspectionDefects}
          onChangeImage={handleChangeImage}
          onClickInspectionDefect={handleClickInspectionDefect}
          onDeleteInspectionDefect={handleDeleteInspectionDefect}
          onLoadMore={loadMore}
          onShowActivity={handleShowActivity}
          onToggleSelection={toggleSelection}
        />
      </Column>

      <FileUploadProgressModal
        isOpen={modalOpen}
        progress={loadingProgress}
        onClose={handleCloseModal}
      />
    </Column>
  )
}

export default ServiceAppointment
