import React, { ComponentProps, useMemo } from 'react'

import { useTheme } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import Slider from '@mui/material/Slider'
import { DateTime } from 'luxon'

import { PlayerVolumeBarDropdown } from 'Components/Blocks'
import { Column, IconButton, Loader, Row, Text } from 'Components/UI'

type Props = {
  currentDuration?: number
  playing?: boolean
  volume?: number
  totalDuration?: number
  finished?: boolean
  loading?: boolean
  muted?: boolean
  onPause?: () => void
  onPlay?: () => void
  onChangeDuration?: ComponentProps<typeof Slider>['onChange']
  onChangeVolume?: ComponentProps<typeof PlayerVolumeBarDropdown>['onChange']
  onToggleMute?: () => void
  playButtonAccessory?: React.ReactNode
}

function CustomPlayer({
  currentDuration,
  playing,
  volume,
  finished,
  totalDuration,
  loading,
  muted,
  onPause,
  onPlay,
  onChangeDuration,
  onChangeVolume,
  onToggleMute,
  playButtonAccessory,
}: Props) {
  const theme = useTheme()
  const color = useMemo(
    () => ({
      playIcon: themeGet('colors.primary500')({ theme }),
    }),
    [theme],
  )

  const { currentTime, totalTime } = useMemo(() => {
    return {
      currentTime: DateTime.fromSeconds(currentDuration || 0).toFormat('mm:ss'),
      totalTime: DateTime.fromSeconds(totalDuration || 0).toFormat('mm:ss'),
    }
  }, [currentDuration, totalDuration])

  return (
    <Column center>
      <Row center gap={3} width={1}>
        <Slider
          disabled={loading}
          max={totalDuration}
          min={0}
          size="small"
          value={currentDuration}
          onChange={onChangeDuration}
        />

        <PlayerVolumeBarDropdown
          muted={muted}
          value={volume}
          onChange={onChangeVolume}
          onToggleMute={onToggleMute}
        />
      </Row>

      <Text body1 fontSize="44px" heading>
        {currentTime} / {totalTime}
      </Text>

      {loading ? (
        <Loader mt={4} size={40} />
      ) : (
        <Row center gap={4}>
          {playButtonAccessory}

          {playing && !finished ? (
            <IconButton onClick={onPause}>
              <PauseCircleIcon
                style={{ fontSize: 56, color: color.playIcon }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={onPlay}>
              <PlayCircleIcon style={{ fontSize: 56, color: color.playIcon }} />
            </IconButton>
          )}
        </Row>
      )}
    </Column>
  )
}

export default CustomPlayer
