import React, { useCallback, useMemo, useRef, useState } from 'react'

import { NetworkStatus, useApolloClient, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import Utils from 'Utils'
import { v4 as uuid } from 'uuid'

import { InfiniteScroll } from 'Components/Blocks'
import {
  Button,
  Column,
  Divider,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'
import { Span } from 'Components/UI/Text'

import { TIME_SHORT_MONTH_FORMAT } from 'Constants/date'

import {
  ActivityEventKind,
  ActivityFragment,
  ActivityListDocument,
  SortInputOrder,
} from 'GraphQL/Main/TypedDocuments'
import { resetActivityListQuery } from 'GraphQL/Main/Updaters/Root'

const LOAD_ACTIVITY_FEED_LIMIT = 15

type Props = {
  isOpen?: boolean
  defectId?: string
  onClose?: () => void
}

function ActivityFeedGlobalModal({ isOpen, defectId, onClose }: Props) {
  const page = useRef(0)
  const [haveMoreActivityFeedToLoad, setHaveMoreActivityFeedToLoad] =
    useState(true)
  const client = useApolloClient()

  const {
    data: activityFeedData,
    loading: activityFeedLoading,
    networkStatus: activityFeedNetworkStatus,
    fetchMore: activityFeedFetchMore,
  } = useQuery(ActivityListDocument, {
    variables: {
      limit: LOAD_ACTIVITY_FEED_LIMIT,
      inspectionDefectId: defectId!,
      sort: {
        column: 'createdAt',
        order: SortInputOrder.Desc,
      },
    },
    skip: !defectId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const { activityFeed, canLoadMore, loadingMore } = useMemo(
    () => ({
      activityFeed: activityFeedData?.activityList.rows || [],
      canLoadMore: haveMoreActivityFeedToLoad && !activityFeedLoading,
      loadingMore: activityFeedNetworkStatus === NetworkStatus.fetchMore,
    }),
    [
      activityFeedData,
      haveMoreActivityFeedToLoad,
      activityFeedLoading,
      activityFeedNetworkStatus,
    ],
  )

  const handleLoadMore = useCallback(() => {
    if (
      !activityFeedData ||
      activityFeed.length >= activityFeedData?.activityList.count ||
      activityFeedNetworkStatus === NetworkStatus.loading ||
      activityFeedNetworkStatus === NetworkStatus.fetchMore
    ) {
      setHaveMoreActivityFeedToLoad(false)
      return
    }

    page.current += 1
    setHaveMoreActivityFeedToLoad(true)
    activityFeedFetchMore({
      variables: {
        page: page.current,
      },
    }).then()
  }, [
    activityFeed,
    activityFeedData,
    activityFeedNetworkStatus,
    activityFeedFetchMore,
  ])

  const getEventKindText = useCallback((activity: ActivityFragment) => {
    switch (activity.eventKind) {
      case ActivityEventKind.Created: {
        if (!activity.user) {
          return <>Created</>
        }

        return (
          <>
            Created by:{' '}
            <Span bold>
              {activity.user.role}
              {Utils.User.getUserIdentifier(activity.user)}
            </Span>
          </>
        )
      }

      case ActivityEventKind.Updated: {
        const activityChanges: React.ReactNode[] = []

        if (activity.inspectionDefectChanges?.description) {
          activityChanges.push(
            <>
              new Comment &ndash;{' '}
              <Span bold>{activity.inspectionDefectChanges.description}</Span>
            </>,
          )
        }
        if (activity.inspectionDefectChanges?.editedPhotoUrl) {
          activityChanges.push(<>new Image</>)
        }
        if (activity.inspectionDefectChanges?.kind) {
          activityChanges.push(
            <>
              changed Kind to &ndash;{' '}
              <Span bold>{activity.inspectionDefectChanges.kind}</Span>
            </>,
          )
        }

        if (!activityChanges.length) {
          return null
        }

        return (
          <>
            Updates{' '}
            {activity.user && (
              <>
                {' '}
                by{' '}
                <Span bold>
                  {activity.user?.role}{' '}
                  {Utils.User.getUserIdentifier(activity.user)}
                </Span>
              </>
            )}
            :{' '}
            {activityChanges.map((activityChange, index) => (
              <Span key={uuid()}>
                {activityChange}
                {index !== activityChanges.length - 1 && ', '}
              </Span>
            ))}
          </>
        )
      }

      default:
        return null
    }
  }, [])

  const handleCloseModal = useCallback(() => {
    resetActivityListQuery(client.cache)
    onClose?.()
  }, [onClose, client])

  return (
    <Modal isOpen={isOpen} title="Activity Feed" onClose={handleCloseModal}>
      <Column maxHeight="500px" width="900px">
        {!activityFeedLoading || loadingMore ? (
          <InfiniteScroll
            canLoadMore={canLoadMore}
            shadowy={activityFeed.length > 10}
            onLoadMore={handleLoadMore}
          >
            {activityFeed.map(activity => {
              const eventKindText = getEventKindText(activity)
              if (!eventKindText) {
                return null
              }

              return (
                <React.Fragment key={activity.id}>
                  <Column>
                    <Row center fullWidth minHeight="42px" spaceBetween>
                      <Row maxWidth="720px">
                        <Text body3 heading>
                          {eventKindText}
                        </Text>
                      </Row>
                      <Text body5 muted>
                        {DateTime.fromISO(activity.createdAt).toLocaleString(
                          TIME_SHORT_MONTH_FORMAT,
                        )}
                      </Text>
                    </Row>
                  </Column>

                  <Divider />
                </React.Fragment>
              )
            })}

            {loadingMore && (
              <Row center gap={3} justifyCenter mb={8} mt={4}>
                <Loader size={30} />

                <Text caption2 center muted>
                  LOADING...
                </Text>
              </Row>
            )}
          </InfiniteScroll>
        ) : (
          <Row center fullHeight fullWidth justifyCenter>
            <Loader size={70} />
          </Row>
        )}

        <Row justifyCenter>
          <Button mt={7} width="328px" onClick={onClose}>
            CLOSE
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}

export default ActivityFeedGlobalModal
