import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '@apollo/client'
import qs from 'qs'
import Utils from 'Utils'

import { InspectionPDF } from 'Components/Blocks'
import { Column, Loader } from 'Components/UI'

import {
  INSPECTION_ROOT,
  SERVICE_APPOINTMENTS,
  ServiceAppointmentsRouteParams,
} from 'Constants/paths'

import { FloorPlanListDocument } from 'GraphQL/Admin/TypedDocuments'
import { InspectionDocument } from 'GraphQL/Main/TypedDocuments'

import { useInspectionDefectList } from 'Hooks'

import toast from 'Services/Toast'

import { LoaderContainer } from './styles'

const DEFECTS_LIMIT = 999

function InspectionReportPage() {
  const history = useHistory()
  const { inspectionId } = useParams<ServiceAppointmentsRouteParams>()

  const [loaded, setLoaded] = useState(false)

  const {
    data: inspectionData,
    loading: inspectionLoading,
    error: inspectionError,
  } = useQuery(InspectionDocument, {
    variables: {
      id: inspectionId!,
    },
    skip: !inspectionId,
    fetchPolicy: 'network-only',
  })

  const { serviceAppointment, inspection, withoutHeader } = useMemo(
    () => ({
      serviceAppointment: inspectionData?.inspection?.serviceAppointment,
      inspection: inspectionData?.inspection,
      withoutHeader: !!qs.parse(window.location.search.substring(1))
        ?.withoutHeader,
    }),
    [inspectionData],
  )

  useLayoutEffect(() => {
    if (!inspectionError) {
      return
    }
    const [graphQLError] = Utils.Errors.getGraphQLErrors(inspectionError)
    toast.error({ text: graphQLError })

    history.push(SERVICE_APPOINTMENTS)
  }, [history, inspectionError])

  useLayoutEffect(() => {
    if (!withoutHeader && inspection && !inspection?.signUrl) {
      toast.error({ text: 'Please, upload the signature for the PDF' })
      history.push(INSPECTION_ROOT(inspectionId))
    }
  }, [inspection, withoutHeader, history, serviceAppointment, inspectionId])

  const { inspectionDefects, loading: inspectionDefectsLoading } =
    useInspectionDefectList({ inspectionId, limit: DEFECTS_LIMIT })

  const { data: floorPlansData, loading: floorPlansLoading } = useQuery(
    FloorPlanListDocument,
    {
      context: {
        admin: true,
      },
      variables: {
        inspectionId: inspection?.id!,
        limit: 999,
      },
      skip: !inspection?.id,
      fetchPolicy: 'network-only',
    },
  )

  const handleLoadSuccess = useCallback(() => {
    if (loaded) return

    setLoaded(true)
  }, [loaded])

  const titleText = useMemo(() => {
    let text = `PDF Service Appointment: ${
      serviceAppointment?.appointmentNumber ||
      serviceAppointment?.id || <>&mdash;</>
    }`
    if (inspection?.name) {
      text += ` | Opportunity: ${inspection?.name}`
    }

    return text
  }, [serviceAppointment, inspection])

  if (inspectionLoading || inspectionDefectsLoading || floorPlansLoading) {
    return <Loader fullScreen size={70} />
  }

  return (
    <Column fullHeight>
      <Helmet title={titleText} />

      <Column fullHeight minHeight={0}>
        {!loaded && (
          <LoaderContainer>
            <Loader size={70} />
          </LoaderContainer>
        )}

        <InspectionPDF
          floorPlans={floorPlansData?.floorPlanList.rows}
          inspection={inspection}
          inspectionDefects={inspectionDefects}
          withoutHeader={withoutHeader}
          onLoadSuccess={handleLoadSuccess}
        />
      </Column>
    </Column>
  )
}

export default InspectionReportPage
